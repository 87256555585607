import api from '@/config/Api'

const baseEndpoint = 'administration/docList-attachments'
const baseEndpoint2 = 'administration/docList-attachment'

export default {
  getDoclist (params) {
    return api.get(baseEndpoint, params)
  },

  getDoclistByAttachmentType (id, params) {
    return api.get(`${baseEndpoint}?filters[attachmentType]=${id}`, params)
  },

  getDoc (id) {
    return api.get(`administration/docList-attachment/${id}`)
  },

  saveDoclist (data) {
    return api.post(baseEndpoint2, data)
  },

  updateDoclist (id, data) {
    return api.put(`${baseEndpoint2}/${id}`, data)
  },

  deleteDoclist (id) {
    return api.delete(`${baseEndpoint2}/${id}`)
  }
}
