<template>
    <transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        >
        <header class="modal-header" id="modalTitle">
            <slot name="header">
              {{$t('LogDetail')}}-({{valueToShow.action}})
            </slot>
            <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
            >
             <ods-icon name = "close" size = "18" />
            </button>
        </header>
        <ods-module v-loading="loadingModal">
          <section class="modal-body">
              <slot name="body">
                <ods-row :gutter="24">
                  
                  <ods-col :md="12" :sm="12">
                      <span class="font-weight-bold">{{$t('action')}}:</span>
                      {{logData.action}}
                  </ods-col>
                  
                  <ods-col :md="12" :sm="12">
                      <span class="font-weight-bold">{{$t('origin')}}:</span>
                      {{logData.origin}}
                  </ods-col>
                  <ods-col :md="24" :sm="24">
                      <span class="font-weight-bold">{{$t('statusResponse')}}:</span>
                      {{logData.statusResponse}}
                  </ods-col>
                  <ods-col :md="24" :sm="24">
                      <span class="font-weight-bold">{{$t('url')}}:</span>
                      {{logData.url}}
                  </ods-col>
                  <ods-col :md="24" :sm="24">
                      <span class="font-weight-bold">{{$t('header')}}:</span>
                      {{logData.header}}
                  </ods-col>
                  <ods-col :md="24" :sm="24">
                      <span class="font-weight-bold">{{$t('body')}}:</span>
                      {{logData.body}}
                  </ods-col>
                  <ods-col :md="24" :sm="24">
                      <span class="font-weight-bold">{{$t('response')}}:</span>
                      {{logData.response}}
                  </ods-col>
                </ods-row>
              </slot>
          </section>
        </ods-module>
        </div>
    </div>
  </transition>
</template>

<script>
import errors from '@/config/InputErrors'
import FormButtons from '@/custom-components/Buttons/FormButtons'
import handlePromise from '@/utils/promise'
import moment from 'moment'
import Vue from 'vue'
import { mapState } from 'vuex'
import LogService from '@/services/Log'

export default {
  name: 'LogDetailModal',
  props: {
    valueToShow: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState({
      userData: state => state.user.data
    })
  },
  mounted: function () {
    this.getValue()
  },
  data () {
    return {
      loadingModal: false,
      logData: {}
    }
  },
  methods: {
    async getValue () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(LogService.getLog(this.valueToShow.id))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.logData = data
    },
    close () {
      this.$emit('close')
    },
    toggleLoading () {
      this.loadingModal = !this.loadingModal
    }
  }
}
</script>
<style scoped>
  ::-webkit-scrollbar {
     width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(224, 223, 223);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(197, 197, 197);
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1400;
  }

  .modal {
    background: #FFFFFF;
    overflow-x: auto;
    display: flex;
    width: 30%;
    border-radius: 5px;
    flex-direction: column;
    padding: 10px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #000000;
    font-weight: 490;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    height: 20vh;
    overflow-y: auto;
  }

  .modal-footer{
      position: sticky;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active {
    transition: opacity .5s ease;
  }
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
  .unlayer-editor {
    flex: 1;
    display: flex;
  }
</style>
