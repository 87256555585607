<template>
  <section>
     <ods-row :gutter="20" type="flex">
       <ods-module :header="$t('notificationMaintenance')" style="width:100%">
         <template slot="header">
            <ods-row :gutter="20">
              <ods-col :md="16">
                {{$t('notificationMaintenance')}}
              </ods-col>
              <ods-col :md="8" class="text-right">
                <ods-button
                      size="mini"
                      type="primary"
                      icon="ods-icon-plus"
                      @click="toggleDialog()">{{$t('createNotification')}}</ods-button>
              </ods-col>
            </ods-row>
          </template>
          <ods-col :md="24">
            <ods-table
              ref="singleTable"
              v-loading="loading"
              :data="notifications"
              size="mini"
              style="width: 100%">
              <ods-table-column
                property="name"
                :label="$t('name')"
                >
              </ods-table-column>
              <ods-table-column :label="'Email'">
                <template slot-scope="scope" >
                  <ods-checkbox
                    v-model="scope.row.isEmailRequired"
                    @change="handleCheckbox(scope.row)"
                    >
                  </ods-checkbox>
                </template>
              </ods-table-column>
              <ods-table-column :label="'SMS'">
                <template slot-scope="scope" >
                  <ods-checkbox
                    v-model="scope.row.isSmsRequired"
                    @change="handleCheckbox(scope.row)"
                    >
                  </ods-checkbox>
                </template>
              </ods-table-column>
              <ods-table-column :label="$t('creationDate')">
                <template slot-scope="scope">
                    {{ scope.row.createdAt| date2 }}
                </template>
              </ods-table-column>
              <ods-table-column :label="$t('actions')"  width="180">
                <template slot-scope="scope">
                    <ods-button
                      size="mini"
                      type="warning"
                      icon="ods-icon-edit" @click="toggleDialog(scope.row)"></ods-button>
                    <ods-button
                      size="mini"
                      type="danger"
                      icon="ods-icon-close" @click="toggleDeleteDialog(scope.row.id)"></ods-button>
                </template>
              </ods-table-column>
            </ods-table>
          </ods-col>
        </ods-module>
     </ods-row>
     <dialog-edit v-if="dialogVisible"
      :valueToEdit="valueToEdit"
      v-show="dialogVisible"
      @close="closeDialog"
      @confirm="getNotifications"
    />
    <dialog-confirmation
          :visible="dialogDeleteVisible"
          :loading="deleteLoading"
          @confirm="deleteItem"
          @cancel="toggleDeleteDialog()"
        />
  </section>
</template>

<script>
import handlePromise from '@/utils/promise'
import NotificationService from '@/services/Notification'
import DialogEdit from './EditModal.vue'
import DialogConfirmation from '@/custom-components/DialogConfirmation'

export default {
  name: 'NotificationMaintenance',
  data () {
    return {
      loading: false,
      notifications: [],
      dialogVisible: false,
      dialogDeleteVisible: false,
      deleteLoading: false,
      toDelete: null,
      valueToEdit: {}
    }
  },
  components: {
    DialogEdit,
    DialogConfirmation
  },
  mounted () {
    this.getNotifications()
  },
  methods: {
    async getNotifications () {
      let params = {
        limit: -1
      }
      this.toggleLoading()
      const [error, response, data] = await handlePromise(NotificationService.getNotifications(params))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.notifications = data.data
    },
    async handleCheckbox (row) {
      const data = {
        isEmailRequired: row.isEmailRequired ? 'true' : 'false',
        isSmsRequired: row.isSmsRequired ? 'true' : 'false'
      }
      this.toggleLoading()
      const [error, response] = await handlePromise(NotificationService.updateNotification(row.id, data))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
    },
    async toggleDialog (row) {
      if (row) {
        this.valueToEdit = row
      } else { this.valueToEdit = null }
      this.dialogVisible = !this.dialogVisible
    },
    closeDialog () {
      this.dialogVisible = !this.dialogVisible
    },
    toggleDeleteDialog (row) {
      this.toDelete = row
      this.dialogDeleteVisible = !this.dialogDeleteVisible
    },
    async deleteItem () {
      this.deleteLoading = true
      const [error, response] = await handlePromise(NotificationService.deleteNotification(this.toDelete))
      this.deleteLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.toggleDeleteDialog()
      this.getNotifications()
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
