<template>
  <section v-loading="loading">
    <ods-module :header="'Card reader test connection V5'">
      <ods-button @click="testApi">Sed request</ods-button>
      <div v-if="sendedData">
        Sended DATA:
        {{sendedData}}  
      </div>
      <hr>
      <div v-if="response">
        RESPONSE:
        {{response}}
      </div>
      <div v-if="error">
        ERROR:
        {{error}}
      </div>
      
    </ods-module>
  </section>
</template>

<script>
import handlePromise from '@/utils/promise'
import axios from 'axios'
import moment from 'moment'
import PaymentService from '@/services/Payment'
import ExampleService from '@/services/Example'
import ListHeader from '@/custom-components/ListHeader'
import map from 'lodash/map'
import $ from 'jquery'

export default {
  name: 'ReadCards',
  props: {
    params: Object
  },
  components: {
    ListHeader
  },
  data () {
    return {
      loading: false,
      sendedData: null,
      error: null,
      axios: axios,
      response:null,
    }
  },
  methods: {
     testApi(){
      let dataToSend = {
        "ReadCardInfo":false,
        "ReadPersonalInfo":true,
        "ReadAddressDetails":true,
        "ReadBiometrics":false,
        "ReadEmploymentInfo":false,
        "ReadImmigrationDetails":false,
        "ReadTrafficDetails":false,
        "SilentReading":false,
        "ReaderIndex":-1,
        "ReaderName":"",
        "OutputFormat":"JSON",
        "ValidateCard":false}
        this.sendedData = dataToSend

        let currentObj = this;
                $.support.cors = true;				
                $.ajax({
                    type: "POST",
                    url: "http://localhost:5050/api/operation/ReadCard",
                    // dataType: "json",
                    data: JSON.stringify(
                       dataToSend),
                    success: function (data) {
                     currentObj.response = data
                        $("#btnReadSmartcard").prop('value', 'Read Smartcard');
                        $('#btnReadSmartcard').prop("disabled", false);
                        $("#tbDetails").find("tr:gt(0)").remove();
                        if ($("#sltOutputFormat :selected").text() == "XML") {
                            $('<tr><td style="border: solid 1px lightgray;width: 100%" colspan="2"><textarea rows="50" cols="50" style="height:200px; width:100%">' + data + '</textarea></td></tr>').appendTo('#tbDetails');
                        }
                        else {
                          currentObj.response = data
                            var jsonData = JSON.stringify(data);
                            var objData = $.parseJSON(jsonData);
                            $.each(objData, function (k, v) {
                                if (k == "MiscellaneousTextData" || k == "MiscellaneousBinaryData") {
                                    var misc = "<table>";
                                    $.each(v, function (k1, v1) {
                                        misc += '<tr><td style="border: solid 1px lightgray;width: 20%"><b>' + k1 + '</b></td><td style="width: 80%;word-wrap: break-word; border: solid 1px lightgray">' + v1 + '</td></tr>';
                                    });
                                    misc += "</table>"
                                    $('<tr><td style="border: solid 1px lightgray;width: 20%"><b>' + k + '</b></td><td style="width: 80%;word-wrap: break-word; border: solid 1px lightgray">' + misc + '</td></tr>').appendTo('#tbDetails');
                                }
                                else
                                    $('<tr><td style="border: solid 1px lightgray;width: 20%">' + k + '</b></td><td style="width: 80%;word-wrap: break-word; border: solid 1px lightgray">' + v + '</td></tr>').appendTo('#tbDetails');
                            });
                        }
                        $('#tbDetails').show();
                    },
                    error: function (xhr, tst, err) {
                        $("#btnReadSmartcard").prop('value', 'Read Smartcard');
                        $('#btnReadSmartcard').prop("disabled", false);
                        currentObj.error = xhr.responseText
                    }
                });
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
