import api from '@/config/Api'

const baseEndpoint = 'administration/attachment-types'
const baseEndpoint2 = 'administration/attachment-type'

export default {
  getIdTypes (params) {
    return api.get('administration/idTypes', params)
  },

  getAttachemntTypes (params) {
    return api.get(baseEndpoint, params)
  },

  getAttachemntType (id) {
    return api.get(`${baseEndpoint2}/${id}`)
  },

  saveAttachemntType (data) {
    return api.post(baseEndpoint2, data)
  },

  updateAttachemntType (id, data) {
    return api.put(`${baseEndpoint2}/${id}`, data)
  },

  deleteAttachemntType (id) {
    return api.delete(`${baseEndpoint2}/${id}`)
  }
}
