import api from '@/config/Api'

const baseEndpoint = 'administration/status-mapping'

export default {
  getStatusMap (status) {
    if (status) {
      return api.get(`${baseEndpoint}?filters[status]=${status}`)
    } else {
      return api.get(baseEndpoint)
    }
  },

  saveStatusMap (data) {
    return api.post(baseEndpoint, data)
  },

  deleteStatusMap (id) {
    return api.delete(`${baseEndpoint}/${id}`)
  }
}
