<template>
  <section v-loading="loading">
    <ods-row :gutter="20">
      <ods-col :md="8">
        <ods-module :header="$t('requestByGovernorates')" class="mb-1">
          <v-chart
            @click="handleMapOnClick($event)"
            ref="vchart"
            style="width: 100%"
            :options="chart7"
            autoresize="autoresize"
          />
        </ods-module>
      </ods-col>
      <ods-col :md="16">
        <ods-module :header="$t('requestStatusByGovernorates')" class="mb-1">
          <v-chart
            ref="vchart"
            style="width: 100%"
            :options="chart1"
            autoresize="autoresize"
          />
        </ods-module>
      </ods-col>
    </ods-row>
    <ods-row :gutter="20">
      <ods-col :md="12">
        <ods-module :header="$t('requestByServiceTypes')" class="mb-1">
          <v-chart
            ref="vchart"
            style="width: 100%"
            :options="chart4"
            autoresize="autoresize"
          />
        </ods-module>
      </ods-col>
      <ods-col :md="12">
        <ods-module :header="$t('verifiedRequests')" class="mb-1">
          <v-chart
            ref="vchart"
            style="width: 100%"
            :options="chart2"
            autoresize="autoresize"
          />
        </ods-module>
      </ods-col>
    </ods-row>
    <ods-row :gutter="20">
      <ods-col :md="12">
        <ods-module :header="$t('requestsByCreatedDate')" class="mb-1">
          <v-chart
            ref="vchart"
            style="width: 100%"
            :options="chart5"
            autoresize="autoresize"
          />
        </ods-module>
      </ods-col>
      <ods-col :md="12">
        <ods-module :header="$t('requestsApprovedByCreatedDate')" class="mb-1">
          <v-chart
            ref="vchart"
            style="width: 100%"
            :options="chart6"
            autoresize="autoresize"
          />
        </ods-module>
      </ods-col>
    </ods-row>
    <ods-row :gutter="20">
      <ods-col :md="24">
        <ods-module class="mb-1">
          <div class="box-top">
            {{ avgBetweenCreatedAndApproved | formatNumber }}
          </div>
          <div class="box">
            <ods-button
              type="secundary"
              size="medium"
              icon="ods-icon-calendar-schedule"
              circle
              disabled
              style="color:gray;border-color: rgb(116, 141, 252);margin-top:-5px"
            />
          </div>
          <div class="box-bottom">
            {{ $t("avgBetweenCreatedAndApproved") }}
          </div>
        </ods-module>
      </ods-col>
    </ods-row>
  </section>
</template>

<script>
import handlePromise from "@/utils/promise";
import _ from "lodash";
import moment from "moment";
import DashboardService from "@/services/Dashboard";
import ECharts from "vue-echarts";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/map";
import bahreimJson from "../../assets/geoman.json";

export default {
  name: "Dashboard",
  props: {
    params: Object,
  },
  components: {
    "v-chart": ECharts,
  },
  data() {
    return {
      loading: false,
      search: {},
      chart1: {},
      statusByGovernorateGraph: {},
      chart2: {},
      verifiedGraph: {},
      chart3: {},
      avgBetweenCreatedAndApproved: String,
      chart4: {},
      requestByServiceType: {},
      chart5: {},
      requestByCreatedDate: {},
      chart6: {},
      requestsApprovedByCreatedDate: {},
      chart7: null,
      mapOption: null,
      barOption: null,
      mapChart: true,
      mapData: {},
      colors: [
        "#2a39c7",
        "#2a66c7",
        "#2a7bc7",
        "#2a88c7",
        "#05b1f5",
        "#05cdf5",
      ],
    };
  },
  mounted() {
    this.getGraphs();
    // window.setInterval(() => {
    //   this.chart7 =
    //     this.chart7 === this.mapOption ? this.barOption : this.mapOption;
    // }, 3000);
  },
  methods: {
    async getGraphs() {
      ECharts.registerMap("BAH", bahreimJson);

      this.toggleLoading();
      const [error2, response2, data2] = await handlePromise(
        DashboardService.getMap()
      );
      this.toggleLoading();

      this.mapData = data2;

      this.mapOption = {
        tooltip: {
          trigger: "item",
          showDelay: 0,
          transitionDuration: 0.2,
        },
        visualMap: {
          left: "right",
          min: 0,
          max: 10,
          inRange: {
            color: [
              "#313695",
              "#4575b4",
              "#74add1",
              "#abd9e9",
              "#e0f3f8",
              "#ffffbf",
              "#fee090",
              "#fdae61",
              "#f46d43",
              "#d73027",
              "#a50026",
            ],
          },
          text: ["High", "Low"],
          calculable: true,
        },

        toolbox: {
          show: true,
          //orient: 'vertical',
          left: "left",
          top: "top",
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {},
          },
        },
        emphasis: {
          label: {
            show: true,
          },
        },
        series: [
          {
            name: "Bahrain requests:",
            type: "map",
            roam: true,
            map: "BAH",
            animationDurationUpdate: 1000,
            animationDuration: 1000,
            animationEasing: "linear",
            animationEasingUpdate: "linear",
            universalTransition: true,
            data: this.mapData.data,
          },
        ],
      };

      this.barOption = {
        xAxis: {
          type: "value",
        },
        yAxis: {
          type: "category",
          axisLabel: {
            rotate: 30,
          },
          data: this.mapData.data.map(function(item) {
            return item.name;
          }),
        },
        animationDurationUpdate: 1000,
        animationDuration: 1000,
        animationEasing: "linear",
        animationEasingUpdate: "linear",
        series: {
          type: "bar",
          data: this.mapData.data.map(function(item) {
            return item.value;
          }),
          universalTransition: true,
        },
      };

      this.chart7 = this.mapOption;

      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        DashboardService.getGraphs()
      );
      this.toggleLoading();
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.verifiedGraph = data[0].verified;
      this.statusByGovernorateGraph = data[0].statusByGovernorate;
      this.avgBetweenCreatedAndApproved = data[0].avgBetweenCreatedAndApproved;
      this.requestByServiceType = data[0].requestByServiceType;
      this.requestByCreatedDate = data[0].requestsByCreatedDate;
      this.requestsApprovedByCreatedDate =
        data[0].requestsApprovedByCreatedDate;
      this.buildGraphs();
    },
    async buildGraphs() {
      const seriesLabel = {
        show: true,
      };
      let yAxisData = [];
      this.statusByGovernorateGraph.map((item) => {
        if (
          !_.find(yAxisData, function(o) {
            return o === item.statusMaster;
          })
        ) {
          yAxisData.push(item.statusMaster);
        }
      });
      let baseData = [];
      this.statusByGovernorateGraph.map((item) => {
        if (
          !_.find(baseData, function(o) {
            return o.governorate === item.nameEn;
          })
        ) {
          let currentStatus = item.statusMaster;
          let auxStatus = {
            [currentStatus]: [item["1"]],
          };
          baseData.push({
            governorate: item.nameEn,
            requests: auxStatus,
          });
        } else {
          let index = _.indexOf(_.map(baseData, "governorate"), item.nameEn);
          let currentStatus = item.statusMaster;
          let auxStatus = {
            [currentStatus]: [item["1"]],
          };
          baseData[index].requests = _.concat(
            baseData[index].requests,
            auxStatus
          );
        }
      });

      let seriesStatusByGovernorate = [];

      baseData.map((item) => {
        let chartData1 = _.map(yAxisData, function(o) {
          return "0";
        });

        yAxisData.map((item2, index2) => {
          if (!item.requests.length) {
            if (item.requests[`${item2}`]) {
              chartData1[index2] = item.requests[item2][0];
            }
          } else {
            item.requests.map((item3, index3) => {
              if (item3[`${item2}`]) {
                chartData1[index2] = item3[item2][0];
              }
            });
          }
        });

        seriesStatusByGovernorate.push({
          name: item.governorate,
          type: "bar",
          label: seriesLabel,
          data: chartData1,
          itemStyle: { color: this.popRandomColor() },
        });
      });

      this.chart1 = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {
          data: ["Capital", "Muharraq", "Northern", "Southern"],
        },
        grid: {
          left: 100,
        },
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "value",
          name: "Requests",
          axisLabel: {
            formatter: "{value}",
          },
        },
        yAxis: {
          name: "Status",
          type: "category",
          inverse: false,
          data: yAxisData,
          axisLabel: {
            formatter: function(value) {
              let aux = value.split(" ");
              let returnedString = "";
              aux.map((item) => {
                returnedString = returnedString + "\n" + item;
              });
              return returnedString;
            },
            margin: 10,
            rich: {
              value: {
                lineHeight: 30,
                align: "center",
              },
            },
          },
        },
        series: seriesStatusByGovernorate,
      };

      let verifiedData = [];
      await this.verifiedGraph.map((item) => {
        if (item.isVerified) {
          verifiedData.push({
            value: item["1"],
            name: "Verified",
            itemStyle: { color: this.popRandomColor() },
          });
        }
        if (!item.isVerified) {
          verifiedData.push({
            value: item["1"],
            name: "Not verified",
            itemStyle: { color: this.popRandomColor() },
          });
        }
      });
      this.chart2 = {
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            name: "Verified requests",
            type: "pie",
            radius: ["60%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 50,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: verifiedData,
          },
        ],
      };

      let serviceTypes = [];
      let dataForServiceTypes = [];
      this.requestByServiceType.map((item) => {
        if (
          !_.find(serviceTypes, function(o) {
            return o === item.descriptionEn;
          })
        ) {
          serviceTypes.push(item.descriptionEn);
          dataForServiceTypes.push(item["1"]);
        }
      });

      this.chart4 = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            name: "Service type",
            type: "category",
            data: serviceTypes,
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              formatter: function(value) {
                let aux = value.split(" ");
                let returnedString = "";
                aux.map((item) => {
                  returnedString = returnedString + "\n" + item;
                });
                return returnedString;
              },
            },
          },
        ],
        yAxis: [
          {
            name: "Requests",
            type: "value",
          },
        ],
        series: [
          {
            name: "Direct",
            type: "bar",
            barWidth: "40%",
            itemStyle: { color: '#2a7bc7' },
            data: dataForServiceTypes,
          },
        ],
      };

      let requestByCreatedDateSeries = [];
      let data = _(this.requestByCreatedDate)
      .map(item => {
          return {
              date: moment(item["createdAt"])
                    .startOf("day").format(),
               registrations: parseInt(item["1"])
          }
      })
      .groupBy("date")
      .mapValues(item => {
           return _.sumBy(item, 'registrations');
       })
      .value()
      requestByCreatedDateSeries.push({
        name: "Requests",
        type: "line",
        smooth: true,
        itemStyle: { color: '#2a7bc7' },
        data: Object.keys(data).map((key) => [key, data[key]]),
      });
      this.chart5 = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        xAxis: [
          {
            name: "Date",
            type: "time",
            boundaryGap: false,
            axisLabel: {
              formatter: function(value) {
                return moment(new Date(Number(`${value}`))).format("DD/MM/YYYY");
              },
            },
          },
        ],
        yAxis: [
          {
            name: "Requests created",
            type: "value",
          },
        ],
        series: requestByCreatedDateSeries,
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            filterMode: "none",
          },
          {
            start: 0,
            end: 100,
            filterMode: "none",
          },
        ],
      };

      let data2 = _(this.requestsApprovedByCreatedDate)
      .map(item => {
          return {
              date: moment(item["createdAt"])
                    .startOf("day").format(),
               registrations: parseInt(item["1"])
          }
      })
      .groupBy("date")
      .mapValues(item => {
           return _.sumBy(item, 'registrations');
       })
      .value()
      let requestsApprovedByCreatedDateSeries = [];
      requestsApprovedByCreatedDateSeries.push({
        name: "test",
        type: "line",
        smooth: true,
        itemStyle: { color: '#2a7bc7' },
        data: Object.keys(data2).map((key) => [key, data2[key]]),
      });

      this.chart6 = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        xAxis: [
          {
            name: "Date",
            type: "time",
            boundaryGap: false,
            axisLabel: {
              formatter: function(value) {
                return moment(new Date(Number(`${value}`))).format("DD/MM/YYYY");
              },
            },
          },
        ],
        yAxis: [
          {
            name: "Requests approved",
            type: "value",
          },
        ],
        series: requestsApprovedByCreatedDateSeries,
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
            filterMode: "none",
          },
          {
            start: 0,
            end: 100,
            filterMode: "none",
          },
        ],
      };
    },
    popRandomColor() {
      var rand = Math.random();
      var color = this.colors[Math.floor(rand * this.colors.length)];
      this.colors.splice(Math.floor(rand * this.colors.length), 1);
      return color;
    },
    handleMapOnClick(data){
      if(data.data.name){
        this.$router.push({ path: `/request-dashboard/directLink/${data.data.name}` })
      }
    },
    toggleLoading() {
      this.loading = !this.loading;
    },
  },
};
</script>
<style scoped>
.box {
  width: 100%;
  height: 20px;
  border-bottom: 1px solid rgb(184, 181, 181);
  text-align: center;
}
.box-top {
  margin-top: 20px;
  font-weight: 450;
  font-size: 320%;
  text-align: center;
}
.box-bottom {
  margin-top: 30px;
  text-align: center;
  font-size: 120%;
}
</style>
