<template>
  <ods-dialog
      :visible.sync="visible"
      width="30%"
      modal
      center
      :close-on-click-modal="false"
      :close-on-press-escape="false"
  >
    <div class="ods-dialog--center">
      <h3 class="alert__title ods-alert--error">
        {{verify ? $t('verifyAlert') : deleteAllLogs ? $t('deleteAllLogsAlert') : $t('deleteAlert')}}
      </h3>
    </div>
    <div slot="footer">
      <ods-button size="medium" :type="verify ? 'warning' : 'danger'" @click="$emit('confirm')" :loading="loading">
        {{verify ? $t('verify') : $t('confirm')}}
      </ods-button>
      <ods-button size="medium" type="secondary" @click="$emit('cancel')" :disabled="loading">
        {{$t('cancel')}}
      </ods-button>
    </div>
  </ods-dialog>
</template>

<script>
export default {
  name: 'DialogConfirmation',
  props: {
    visible: Boolean,
    loading: Boolean,
    verify: {
      type: Boolean,
      default: false
    },
    deleteAllLogs: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  .alert__title {
    font-size: 14px;
    padding: 10px;
  }
</style>
