<template>
  <section>
    <ods-row :gutter="20" class="header">
      <ods-col :md="24">
        <ods-module>
          <template slot="header">
            <ods-row :gutter="20">
              <ods-col :md="16">
                {{ requestType.descriptionEn }} ({{ referenceNumber }})
              </ods-col>
              <ods-col :md="8" class="text-right">
                <ods-tag v-show="form.actionId !== ''" v-if="form.actionId" style="margin-right: 20px" type="info">{{ form.actionId === 1 ? 'Pending Approve/Reject' : form.actionId === 2 ? 'Pending Attach' : form.actionId === 3 ? 'Pending Pay' : form.actionId === 4 ? 'Pending Instructions' : form.actionId === 5 ? 'Pending Register' : ''}}</ods-tag>
                <ods-tag
                  v-if="cStatus.statusMaster !== 'Approved'"
                  style="margin-right: 40px"
                  :type="
                    cStatus.statusMaster === 'New Request'
                      ? 'warning'
                      : 'danger'
                  "
                >
                  {{ cStatus.statusMaster }}
                </ods-tag>
                <ods-badge
                  style="margin-right: 80px; margin-top: 0px"
                  v-if="cStatus.statusMaster === 'Approved'"
                  :value="form.isVerified ? 'Verified' : 'Not verified'"
                  class="item"
                  :type="form.isVerified ? 'info' : 'warning'"
                >
                  <ods-tag :type="'success'" style="margin-right: -10px">
                    {{ cStatus.statusMaster }}
                  </ods-tag>
                </ods-badge>
                <router-link :to="{ name: 'RequestDashboard' }">
                  <button
                    type="button"
                    class="btn-close"
                    style="margin-top: -15px"
                  >
                    <ods-icon name="close" size="18" />
                  </button>
                </router-link>
              </ods-col>
            </ods-row>
          </template>
        </ods-module>
      </ods-col>
    </ods-row>
    <ods-module v-loading="loading" style="padding-top: 80px">
      <ods-form :model="form" ref="form">
        <ods-row :gutter="24">
          <ods-accordion v-model="activeNames" :accordion="accordion">
            <ods-module
              class="mt-1"
              v-if="
                form.status
                  ? form.status.statusMaster === 'Approved'
                    ? true
                    : false
                  : false
              "
            >
              <ods-accordion-item
                :key="'0'"
                :name="'0'"
                :title="$t('statusInCSS')"
                :disabled="disabled"
              >
                <ods-row :gutter="24">
                  <ods-col :md="12">
                    <ods-form-item :label="$t('oucAccount')" prop="oucAccount">
                      <ods-input v-model="form.oucAccount" disabled></ods-input>
                    </ods-form-item>
                  </ods-col>

                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('statusInCSS')"
                      prop="statusInCSS"
                    >
                      <ods-input
                        v-model="form.statusOuc"
                        v-bind:autosize="true"
                        type="textarea"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>

                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('statusInCSSDesc')"
                      prop="statusInCSSDesc"
                    >
                      <ods-input
                        v-model="form.statusOucDesc"
                        v-bind:autosize="true"
                        type="textarea"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                </ods-row>
              </ods-accordion-item>
            </ods-module>

            <ods-module class="mt-1">
              <ods-accordion-item
                :key="'1'"
                :name="'1'"
                :title="$t('accountDetails')"
                :disabled="disabled"
              >
                <ods-row :gutter="24">
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('accountNumber')"
                      prop="accountNumberOld"
                    >
                      <ods-input
                        v-model="form.accountNumberOld"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12" class="">
                    <ods-form-item
                      :label="$t('supplyAddressEn')"
                      prop="supplyAddressEn"
                    >
                      <ods-input
                        v-model="form.supplyAddressId"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                </ods-row>
              </ods-accordion-item>
            </ods-module>

            <ods-module
              class="mt-1"
              v-if="requestType.code != '02'"
              v-show="currentStatus !== 'Rejected'"
            >
              <ods-accordion-item
                :key="'2'"
                :name="'2'"
                :title="$t('meterReadingDetails')"
                :disabled="disabled"
              >
                <ods-button
                  style="float: right"
                  class="mb-1"
                  type="secondary"
                  size="small"
                  @click="saveMeterReading"
                  :disabled="
                    currentStatus === 'Incomplete' ||
                    currentStatus === 'Approved' ||
                    currentStatus === 'Change Service Type' ||
                    currentStatus === 'Rejected'
                  "
                >
                  {{ $t("saveMeterReading") }}
                </ods-button>

                <ods-button
                  style="float: right"
                  class="mb-1 mr-1"
                  type="secondary"
                  size="small"
                  :disabled="
                    currentStatus === 'Incomplete' ||
                    currentStatus === 'Approved' ||
                    currentStatus === 'Change Service Type' ||
                    currentStatus === 'Rejected'
                  "
                  @click="estimateConsumption"
                >
                  {{ $t("estimateConsumption") }}
                </ods-button>
                <ods-table
                  :data="meterReadingData"
                  v-loading="loading"
                  size="mini"
                  style="width: 100%"
                >
                  <ods-table-column
                    prop="meterNumber"
                    :label="$t('meterNumber')"
                    width="120%"
                  />
                  <ods-table-column prop="service" :label="$t('service')">
                    <template slot-scope="scope">
                      {{ scope.row.serviceEn }}
                    </template>
                  </ods-table-column>
                  <ods-table-column
                    prop="consumptionTypeDescEn"
                    :label="$t('consumptionType')"
                    width="150%"
                  />
                  <ods-table-column
                    prop="lastReading"
                    :label="$t('lastMeterReading')"
                    width="150%"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.lastReading | formatNumber }}
                    </template>
                  </ods-table-column>
                  <ods-table-column
                    prop="lastReadindDate"
                    :label="$t('lastReadingDate')"
                    width="150%"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.lastReadingDate | date2 }}
                    </template>
                  </ods-table-column>
                  <ods-table-column
                    width="150%"
                    prop="lastReadingTypeEn"
                    :label="$t('lastReadingType')"
                  />
                  <ods-table-column
                    prop="presentReading"
                    :label="$t('currentMeterReading')"
                    width="250%"
                  >
                    <template slot-scope="scope">
                      <div>
                        <ods-input
                          style="width: 50%"
                          @input="
                            formatNumber(scope.row.presentReading, scope.$index)
                          "
                          v-model="scope.row.presentReading"
                          :disabled="
                            currentStatus === 'Incomplete' ||
                            currentStatus === 'Approved' ||
                            currentStatus === 'Change Service Type' ||
                            currentStatus === 'Rejected'
                          "
                        ></ods-input
                        >({{
                          scope.row.meterDigit ? scope.row.meterDigit : "-"
                        }})
                      </div>
                    </template>
                  </ods-table-column>
                  <ods-table-column
                    prop="presentReadindDate"
                    :label="$t('currentReadingDate')"
                    width="250%"
                  >
                    <template slot-scope="scope">
                      <ods-date-picker
                        type="date"
                        v-model="scope.row.presentReadingDate"
                        style="width: 75%"
                        @change="
                          handleMeterReadingDateValidation(scope.row)
                            ? (scope.row.presentReading = null)
                            : (scope.row.presentReading =
                                scope.row.presentReading)
                        "
                        v-bind:clearable="false"
                        :disabled="
                          currentStatus === 'Incomplete' ||
                          currentStatus === 'Approved' ||
                          currentStatus === 'Change Service Type' ||
                          currentStatus === 'Rejected'
                        "
                        :picker-options="{ disabledDate: disabledReadingDate }"
                      />
                    </template>
                  </ods-table-column>
                </ods-table>
              </ods-accordion-item>
            </ods-module>

            <ods-module class="mt-1">
              <ods-accordion-item
                :key="'3'"
                :name="'3'"
                :title="$t('propertyDetails')"
                :disabled="disabled"
              >
                <ods-row :gutter="24">
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('customerType')"
                      prop="customerType"
                      v-if="form.customerType"
                    >
                      <ods-select
                        v-model="form.customerType"
                        @change="onChangeCustomerType($event)"
                        placeholder=""
                        :disabled="
                          currentStatus === 'Incomplete' ||
                          currentStatus === 'Approved' ||
                          currentStatus === 'Change Service Type' ||
                          currentStatus === 'Rejected'
                        "
                        remote
                      >
                        <ods-option
                          v-for="item in customersTypes"
                          :key="item.id"
                          :label="item.descriptionEn"
                          :value="item.id"
                        >
                        </ods-option>
                      </ods-select>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('propertyCategory')"
                      prop="propertyCategory"
                      v-if="form.propertyCategory"
                    >
                      <ods-select
                        v-model="form.propertyCategory"
                        @change="getPropertyTypesByCategory()"
                        placeholder=""
                        :disabled="
                          currentStatus === 'Incomplete' ||
                          currentStatus === 'Approved' ||
                          currentStatus === 'Change Service Type' ||
                          currentStatus === 'Rejected'
                        "
                        remote
                      >
                        <ods-option
                          v-for="item in propertyCategories"
                          :key="item.id"
                          :label="item.descriptionEn"
                          :value="item.id"
                        >
                        </ods-option>
                      </ods-select>
                    </ods-form-item>
                  </ods-col>
                </ods-row>
                <ods-row :gutter="24">
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('propertyType')"
                      prop="propertyType"
                      v-if="form.propertyType"
                    >
                      <ods-select
                        v-model="form.propertyType"
                        placeholder=""
                        :disabled="
                          currentStatus === 'Incomplete' ||
                          currentStatus === 'Approved' ||
                          currentStatus === 'Change Service Type' ||
                          currentStatus === 'Rejected'
                        "
                        @change="getTarif()"
                        remote
                      >
                        <ods-option
                          v-for="item in propertiesTypes"
                          :key="item.id"
                          :label="item.descriptionEn"
                          :value="item.id"
                        >
                        </ods-option>
                      </ods-select>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12" v-if="currentCustomerType">
                    <ods-form-item
                      v-if="currentCustomerType.descriptionEn === 'Owner'"
                      :label="$t('purchaseDate')"
                      prop="purchaseDate"
                    >
                      <ods-date-picker
                        type="date"
                        v-model="form.purchaseDate"
                        :picker-options="{
                          disabledDate: disabledPurchaseDateTo,
                        }"
                        :disabled="
                          currentStatus === 'Incomplete' ||
                          currentStatus === 'Approved' ||
                          currentStatus === 'Change Service Type' ||
                          currentStatus === 'Rejected'
                        "
                        style="width: 100%"
                      />
                    </ods-form-item>
                  </ods-col>
                </ods-row>
                <ods-row :gutter="24">
                  <ods-col :md="12" v-if="currentCustomerType">
                    <ods-form-item
                      :label="$t('leaseDate')"
                      prop="leaseDate"
                      v-if="currentCustomerType.descriptionEn !== 'Owner'"
                    >
                      <ods-date-picker
                        type="date"
                        v-model="form.leaseDate"
                        :picker-options="{ disabledDate: disabledLeaseDateTo }"
                        :disabled="
                          currentStatus === 'Incomplete' ||
                          currentStatus === 'Approved' ||
                          currentStatus === 'Change Service Type' ||
                          currentStatus === 'Rejected'
                        "
                        style="width: 100%"
                      />
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('leaseAmount')"
                      prop="leaseAmount"
                      :rules="form.leaseDate ? [errors.required] : null"
                    >
                      <ods-input
                        v-model="form.leaseAmount"
                        :disabled="
                          currentStatus === 'Incomplete' ||
                          currentStatus === 'Approved' ||
                          currentStatus === 'Change Service Type' ||
                          currentStatus === 'Rejected'
                        "
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                </ods-row>
                <ods-row :gutter="24">
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('customerComments')"
                      prop="customerComment"
                    >
                      <ods-input
                        v-model="form.customerComment"
                        v-bind:autosize="true"
                        type="textarea"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12" class="">
                    <ods-form-item
                      :label="$t('fullyFurnished')"
                      prop="isFullyFurnished"
                    >
                      <br />
                      <ods-radio-group
                        v-model="form.isFullyFurnished"
                        :disabled="
                          currentStatus === 'Incomplete' ||
                          currentStatus === 'Approved' ||
                          currentStatus === 'Change Service Type' ||
                          currentStatus === 'Rejected'
                        "
                      >
                        <ods-radio :label="1">
                          {{ $t("yes") }}
                        </ods-radio>
                        <ods-radio :label="0">
                          {{ $t("no") }}
                        </ods-radio>
                      </ods-radio-group>
                    </ods-form-item>
                  </ods-col>
                </ods-row>
                <ods-module>
                  <template slot="header">
                    <ods-row :gutter="20">
                      <ods-col :md="16">
                        {{ $t("ownerInformation") }}
                      </ods-col>
                      <ods-col :md="8" class="text-right">
                        <ods-button
                          type="secondary"
                          size="small"
                          @click="fetchOwnerDetailsOUC"
                          :disabled="
                            currentStatus === 'Incomplete' ||
                            currentStatus === 'Approved' ||
                            currentStatus === 'Change Service Type' ||
                            currentStatus === 'Rejected'
                          "
                        >
                          {{ $t("fetchOwnerDetails") }}
                        </ods-button>
                      </ods-col>
                    </ods-row>
                  </template>
                  <ods-row :gutter="24">
                    <ods-col :md="12">
                      <ods-form-item
                        :label="$t('ownerName')"
                        prop="ownerName"
                        v-if="form.propertyOwner"
                      >
                        <ods-input
                          v-model="form.propertyOwner.name"
                          type="text"
                          disabled
                        ></ods-input>
                      </ods-form-item>
                    </ods-col>
                    <ods-col :md="12" v-if="form.propertyOwner">
                      <ods-form-item
                        :label="$t('ownerIdentityType')"
                        prop="ownerIdentityType"
                        v-if="form.propertyOwner.idType"
                      >
                        <ods-input
                          v-model="form.propertyOwner.idType.descriptionEn"
                          disabled
                          type="text"
                        ></ods-input>
                      </ods-form-item>
                    </ods-col>
                  </ods-row>
                  <ods-row :gutter="24">
                    <ods-col :md="12">
                      <ods-form-item
                        :label="$t('ownerIdentityNumber')"
                        prop="ownerIdentityNumber"
                        v-if="form.propertyOwner"
                      >
                        <ods-input
                          v-model="form.propertyOwner.idNumber"
                          type="text"
                          disabled
                        ></ods-input>
                      </ods-form-item>
                    </ods-col>
                    <ods-col :md="12">
                      <ods-form-item
                        :label="$t('additionalNotes')"
                        prop="additionalNotes"
                        v-if="form.propertyOwner"
                      >
                        <ods-input
                          v-model="form.additionalNotesToCss"
                          v-bind:autosize="true"
                          :disabled="
                            currentStatus === 'Incomplete' ||
                            currentStatus === 'Approved'
                          "
                          type="textarea"
                        ></ods-input>
                      </ods-form-item>
                    </ods-col>
                  </ods-row>
                </ods-module>
              </ods-accordion-item>
            </ods-module>

            <ods-module class="mt-1">
              <ods-accordion-item
                :key="'4'"
                :name="'4'"
                :title="$t('serviceRSCodeDetails')"
                :disabled="disabled"
              >
                <ods-row>
                  <ods-button
                    style="float: right"
                    class="mb-1 mr-1"
                    type="secondary"
                    size="small"
                    @click="fetchRSCodesDetails"
                    :disabled="
                      currentStatus === 'Incomplete' ||
                      currentStatus === 'Approved' ||
                      currentStatus === 'Change Service Type' ||
                      currentStatus === 'Rejected'
                    "
                  >
                    {{ $t("fetchRSCodes") }}
                  </ods-button>
                </ods-row>
                <ods-row :gutter="24">
                  <ods-col :md="8">
                    <div>
                      <div style="height: 110px">
                        <p style="margin-bottom: 5px">
                          <span class="font-weight-bold"
                            >{{ $t("electricityService") }}
                            <img
                              style="
                                position: absolute;
                                margin-left: 10px;
                                width: 30px;
                                margin-top: -10px;
                              "
                              src="../../assets/lightbulb-regular.svg"
                              alt=""
                            />
                          </span>
                        </p>
                        <p
                          v-show="
                            !form.rsExistingSpnElectricity &&
                            !form.rsStatusElectricity &&
                            !form.rsCodeActualElectricity
                          "
                        >
                          <span class="font-weight-bold rsNoData"
                            >No data found</span
                          >
                        </p>
                        <p>
                          <span class="font-weight-bold"
                            >{{ $t("existingSPN") }}:</span
                          >
                          {{
                            form.rsExistingSpnElectricity
                              ? form.rsExistingSpnElectricity
                              : "---"
                          }}
                        </p>
                        <p>
                          <span class="font-weight-bold"
                            >{{ $t("status") }}:</span
                          >
                          {{
                            form.rsStatusElectricity
                              ? form.rsStatusElectricity
                              : "---"
                          }}
                        </p>
                        <p class="mb-1">
                          <span class="font-weight-bold"
                            >{{ $t("existingRSCode") }}:</span
                          >
                          {{
                            form.rsCodeActualElectricity
                              ? `${this.form.rsCodeActualElectricity}`
                              : "---"
                          }}
                        </p>
                      </div>
                      <hr />
                      <ods-form-item
                        :label="$t('newRSDescription')"
                        prop="newRSDescriptionElectricity"
                      >
                        <ods-select
                          v-model="form.rsdescElectricity2"
                          @change="onchangeTarifElectricity()"
                          placeholder=""
                          remote
                          clearable
                          filterable
                          :disabled="
                            currentStatus === 'Incomplete' ||
                            currentStatus === 'Approved' ||
                            currentStatus === 'Change Service Type' ||
                            currentStatus === 'Rejected'
                          "
                        >
                          <ods-option
                            v-for="item in RSTarif.electricity"
                            :key="item.id"
                            :label="item.descriptionRsc"
                            :value="item.id"
                          >
                          </ods-option>
                        </ods-select>
                      </ods-form-item>
                    </div>
                  </ods-col>
                  <ods-col :md="8">
                    <div>
                      <div style="height: 110px">
                        <p style="margin-bottom: 5px">
                          <span class="font-weight-bold"
                            >{{ $t("waterService") }}
                            <img
                              style="
                                position: absolute;
                                margin-left: 10px;
                                width: 30px;
                                margin-top: -10px;
                              "
                              src="../../assets/droplet-solid.svg"
                              alt=""
                            />
                          </span>
                        </p>
                        <p
                          v-show="
                            !form.rsExistingSpnWater &&
                            !form.rsStatusWater &&
                            !form.rsCodeActualWater
                          "
                        >
                          <span class="font-weight-bold rsNoData"
                            >No data found</span
                          >
                        </p>
                        <p>
                          <span class="font-weight-bold"
                            >{{ $t("existingSPN") }}:</span
                          >
                          {{
                            form.rsExistingSpnWater
                              ? form.rsExistingSpnWater
                              : "---"
                          }}
                        </p>
                        <p>
                          <span class="font-weight-bold"
                            >{{ $t("status") }}:</span
                          >
                          {{ form.rsStatusWater ? form.rsStatusWater : "---" }}
                        </p>
                        <p class="mb-1">
                          <span class="font-weight-bold"
                            >{{ $t("existingRSCode") }}:</span
                          >
                          {{
                            form.rsCodeActualWater
                              ? `${this.form.rsCodeActualWater}`
                              : "---"
                          }}
                        </p>
                      </div>
                      <hr />
                      <ods-form-item
                        :label="$t('newRSDescription')"
                        prop="newRSDescriptionWater"
                      >
                        <ods-select
                          v-model="form.rsdescWater2"
                          @change="onchangeTarifWater()"
                          placeholder=""
                          remote
                          clearable
                          filterable
                          :disabled="
                            currentStatus === 'Incomplete' ||
                            currentStatus === 'Approved' ||
                            currentStatus === 'Change Service Type' ||
                            currentStatus === 'Rejected'
                          "
                        >
                          <ods-option
                            v-for="item in RSTarif.electricity"
                            :key="item.id"
                            :label="item.descriptionRsc"
                            :value="item.id"
                          >
                          </ods-option>
                        </ods-select>
                      </ods-form-item>
                    </div>
                  </ods-col>
                  <ods-col :md="8">
                    <div>
                      <div style="height: 110px">
                        <p style="margin-bottom: 5px">
                          <span class="font-weight-bold"
                            >{{ $t("rate") }}
                            <ods-icon
                              style="
                                position: absolute;
                                margin-left: 10px;
                                width: 30px;
                                margin-top: -10px;
                                color: rgb(151, 150, 150);
                              "
                              name="offer"
                              size="35"
                            />
                          </span>
                        </p>
                        <p
                          v-show="
                            !form.rsExistingSpnRate &&
                            !form.rsStatusRate &&
                            !form.rsCodeActualRate
                          "
                        >
                          <span class="font-weight-bold rsNoData"
                            >No data found</span
                          >
                        </p>
                        <p>
                          <span class="font-weight-bold"
                            >{{ $t("existingSPN") }}:</span
                          >
                          {{
                            form.rsExistingSpnRate
                              ? form.rsExistingSpnRate
                              : "---"
                          }}
                        </p>
                        <p>
                          <span class="font-weight-bold"
                            >{{ $t("status") }}:</span
                          >
                          {{ form.rsStatusRate ? form.rsStatusRate : "---" }}
                        </p>
                        <p class="mb-1">
                          <span class="font-weight-bold"
                            >{{ $t("existingRSCode") }}:</span
                          >
                          {{
                            form.rsCodeActualRate
                              ? `${this.form.rsCodeActualRate}`
                              : "---"
                          }}
                        </p>
                      </div>
                      <hr />
                      <ods-form-item
                        :label="$t('newRSDescription')"
                        prop="newRSDescriptionRate"
                      >
                        <ods-select
                          v-model="form.rsdescRate2"
                          placeholder=""
                          remote
                          clearable
                          filterable
                          :disabled="
                            currentStatus === 'Incomplete' ||
                            currentStatus === 'Approved' ||
                            currentStatus === 'Change Service Type' ||
                            currentStatus === 'Rejected'
                          "
                        >
                          <ods-option
                            v-for="item in RSTarif.rate"
                            :key="item.id"
                            :label="item.descriptionRsc"
                            :value="item.id"
                          >
                          </ods-option>
                        </ods-select>
                      </ods-form-item>
                    </div>
                  </ods-col>
                </ods-row>
              </ods-accordion-item>
            </ods-module>

            <ods-module class="mt-1">
              <ods-accordion-item
                :key="'5'"
                :name="'5'"
                :title="$t('mailingAddressDetails')"
                :disabled="disabled"
              >
                <ods-row :gutter="24">
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('addressType')"
                      prop="addressType"
                    >
                      <ods-input
                        v-model="form.addressType"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('mailingAddress')"
                      prop="maillingAddressId"
                    >
                      <ods-input
                        v-model="form.maillingAddressEn"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                </ods-row>
                <ods-row :gutter="24">
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('billLanguage')"
                      prop="billLanguage"
                    >
                      <ods-input
                        v-model="form.billingLanguage"
                        type="text"
                        disabled
                      ></ods-input>
                      <!-- <ods-select v-model="form.billLanguage" placeholder="" remote disabled>
                        <ods-option
                            v-for="item in billLanguages"
                            :key="item"
                            :label="item"
                            :value="item"
                        >
                        </ods-option>
                      </ods-select> -->
                    </ods-form-item>
                  </ods-col>
                </ods-row>
                <ods-row :gutter="24" v-if="form.customerMailingAddress">
                  <ods-col :md="12" class="">
                    <ods-form-item label="Alpha" prop="alpha">
                      <ods-input
                        v-model="form.customerMailingAddress.alpha"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12">
                    <ods-form-item label="Block" prop="block">
                      <ods-input
                        v-model="form.customerMailingAddress.block"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12">
                    <ods-form-item label="Building" prop="building">
                      <ods-input
                        v-model="form.customerMailingAddress.building"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12">
                    <ods-form-item label="Department" prop="department">
                      <ods-input
                        v-model="form.customerMailingAddress.department"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12">
                    <ods-form-item label="Flat" prop="flat">
                      <ods-input
                        v-model="form.customerMailingAddress.flat"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12">
                    <ods-form-item label="POBox" prop="pOBox">
                      <ods-input
                        v-model="form.customerMailingAddress.pOBox"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12">
                    <ods-form-item label="Street" prop="roadOrStreet">
                      <ods-input
                        v-model="form.customerMailingAddress.roadOrStreet"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                </ods-row>
              </ods-accordion-item>
            </ods-module>

            <ods-module class="mt-1">
              <ods-accordion-item
                :key="'6'"
                :name="'6'"
                :title="$t('customerDetails')"
                :disabled="disabled"
              >
                <ods-row :gutter="24">
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('idType')"
                      prop="idType"
                      v-if="form.customer"
                    >
                      <ods-input
                        v-model="form.customer.idType.descriptionEn"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('idNumber')"
                      prop="idNumber"
                      v-if="form.customer"
                    >
                      <ods-input
                        v-model="form.customer.idNumber"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                </ods-row>
                <ods-row :gutter="24">
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('nameEnglish')"
                      prop="nameEnglish"
                      v-if="form.customer"
                    >
                      <ods-input
                        v-model="form.customer.fullNameEn"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                    <ods-form-item
                      :label="$t('firstNameEnglish')"
                      prop="firstNameEnglish"
                      v-if="form.customer"
                    >
                      <ods-input
                        class="resaltField"
                        v-model="form.customer.firstNameEn"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                    <ods-form-item
                      :label="$t('middleName1English')"
                      prop="middleName1English"
                      v-if="form.customer"
                    >
                      <ods-input
                        class="resaltField"
                        v-model="form.customer.middleName1En"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                    <ods-form-item
                      :label="$t('middleName2English')"
                      prop="middleName2English"
                      v-if="form.customer"
                    >
                      <ods-input
                        class="resaltField"
                        v-model="form.customer.middleName2En"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                    <ods-form-item
                      :label="$t('middleName3English')"
                      prop="middleName3English"
                      v-if="form.customer"
                    >
                      <ods-input
                        class="resaltField"
                        v-model="form.customer.middleName3En"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                    <ods-form-item
                      :label="$t('middleName4English')"
                      prop="middleName4English"
                      v-if="form.customer"
                    >
                      <ods-input
                        class="resaltField"
                        v-model="form.customer.middleName4En"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                    <ods-form-item
                      :label="$t('familyNameEnglish')"
                      prop="familyNameEnglish"
                      v-if="form.customer"
                    >
                      <ods-input
                        class="resaltField"
                        v-model="form.customer.familyNameEn"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('nameArabic')"
                      prop="nameArabic"
                      v-if="form.customer"
                    >
                      <ods-input
                        v-model="form.customer.fullNameAr"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                    <ods-form-item
                      :label="$t('firstNameArabic')"
                      prop="firstNameArabic"
                      v-if="form.customer"
                    >
                      <ods-input
                        class="resaltField"
                        v-model="form.customer.firstNameAr"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                    <ods-form-item
                      :label="$t('middleName1Arabic')"
                      prop="middleName1Arabic"
                      v-if="form.customer"
                    >
                      <ods-input
                        class="resaltField"
                        v-model="form.customer.middleName1Ar"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                    <ods-form-item
                      :label="$t('middleName2Arabic')"
                      prop="middleName2Arabic"
                      v-if="form.customer"
                    >
                      <ods-input
                        class="resaltField"
                        v-model="form.customer.middleName2Ar"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                    <ods-form-item
                      :label="$t('middleName3Arabic')"
                      prop="middleName3Arabic"
                      v-if="form.customer"
                    >
                      <ods-input
                        class="resaltField"
                        v-model="form.customer.middleName3Ar"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                    <ods-form-item
                      :label="$t('middleName4Arabic')"
                      prop="middleName4Arabic"
                      v-if="form.customer"
                    >
                      <ods-input
                        class="resaltField"
                        v-model="form.customer.middleName4Ar"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                    <ods-form-item
                      :label="$t('familyNameArabic')"
                      prop="familyNameArabic"
                      v-if="form.customer"
                    >
                      <ods-input
                        class="resaltField"
                        v-model="form.customer.familyNameAr"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                </ods-row>

                <ods-row :gutter="24">
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('nationality')"
                      prop="nationality"
                      v-if="form.customer"
                    >
                      <ods-input
                        v-model="form.customer.nationality.description_en"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('mobileNumber')"
                      prop="mobileNumber"
                      v-if="form.customer"
                    >
                      <ods-input
                        v-model="form.customer.mobile"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                </ods-row>
                <ods-row :gutter="24">
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('otherMobileNumber')"
                      prop="otherMobileNumber"
                      v-if="form.customer"
                    >
                      <ods-input
                        v-model="form.customer.otherMobile"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('telephoneNumber')"
                      prop="telephoneNumber"
                      v-if="form.customer"
                    >
                      <ods-input
                        v-model="form.customer.telephone"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                </ods-row>
                <ods-row :gutter="24">
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('fax')"
                      prop="fax"
                      v-if="form.customer"
                    >
                      <ods-input
                        v-model="form.customer.fax"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('email')"
                      prop="email"
                      v-if="form.customer"
                    >
                      <ods-input
                        v-model="form.customer.email"
                        type="text"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                </ods-row>
              </ods-accordion-item>
            </ods-module>

            <ods-module class="mt-1">
              <ods-accordion-item
                :key="'7'"
                :name="'7'"
                :title="$t('paymentDetails')"
                :disabled="disabled"
              >
                <ods-row :gutter="24">
                  <ods-col :md="12" class="">
                    <ods-form-item
                      :label="$t('paymentDeductionOption')"
                      prop="paymentDeductionOption"
                    >
                      <br />
                      <ods-radio-group
                        v-model="form.paymentDeductionOption"
                        :disabled="
                          currentStatus === 'Incomplete' ||
                          currentStatus === 'Approved' ||
                          currentStatus === 'Change Service Type' ||
                          currentStatus === 'Rejected'
                        "
                      >
                        <ods-radio class="radioButton" :label="'CMAX'">
                          {{ $t("creditCard") }}
                        </ods-radio>
                        <ods-radio class="radioButton" :label="'DDI'">
                          {{ $t("bankAccount") }}
                        </ods-radio>
                      </ods-radio-group>
                    </ods-form-item>
                  </ods-col>
                  <!-- <ods-col :md="12">
                    <ods-form-item
                      :label="$t('insuranceFees')"
                      prop="insuranceFees"
                    >
                      <ods-input
                        v-model="form.insuranceFees"
                        :disabled="
                          currentStatus === 'Incomplete' ||
                          currentStatus === 'Approved' ||
                          currentStatus === 'Change Service Type' ||
                          currentStatus ==='Rejected'
"
                      ></ods-input>
                    </ods-form-item>
                  </ods-col> -->
                </ods-row>
              </ods-accordion-item>
            </ods-module>

            <!-- <ods-module class="mt-1">
              <ods-accordion-item
                :key="'8'"
                :name="'8'"
                :title="$t('supplyInformation')"
                :disabled="disabled"
              >
                <ods-row :gutter="24">
                  <ods-col :md="12" class="">
                    <ods-form-item
                      :label="$t('supplyAddressEn')"
                      prop="supplyAddressEn"
                    >
                      <ods-input
                        v-model="form.supplyAddressId"
                        disabled
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                </ods-row>
              </ods-accordion-item>
            </ods-module> -->

            <!-- <ods-module class="mt-1"  v-if="form.customerMailingAddress">
              <ods-accordion-item
                :key="'9'"
                :name="'9'"
                :title="$t('maillingAddress')"
                :disabled="disabled"
              >

              </ods-accordion-item>
            </ods-module> -->

            <ods-module class="mt-1">
              <ods-accordion-item
                :key="'10'"
                :name="'10'"
                :title="$t('attachmentsDetails')"
                :disabled="disabled"
              >
                <ods-tooltip placement="top">
                  <div slot="content">{{ $t("admitedFiles") }}<br /></div>
                  <ods-button
                    style="float: right"
                    class="mb-1"
                    type="secondary"
                    size="small"
                    @click="mergeAttachments"
                  >
                    {{ $t("mergeViewAttachments") }}
                  </ods-button>
                </ods-tooltip>

                <ods-table
                  :data="attachmentByRequest"
                  v-loading="loading"
                  size="mini"
                  style="width: 100%"
                >
                  <ods-table-column
                    prop="documeteName"
                    :label="$t('documentName')"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.documeteName }}
                    </template>
                  </ods-table-column>
                  <ods-table-column
                    prop="attachmentType.descriptionEn"
                    :label="$t('documentDescription')"
                  >
                    <template slot-scope="scope">
                      {{ scope.row.documentDescription }}
                    </template>
                  </ods-table-column>
                  <ods-table-column
                    prop="viewDocument"
                    :label="$t('viewDocument')"
                    width="140"
                  >
                    <!-- <template
                      slot-scope="scope"
                      v-if="isImage(scope.row.attachment.fileType)"
                    >
                      <a
                        :href="scope.row.attachment.fileUri"
                        target="_blank"
                        v-if="scope.row.attachment.fileUri"
                      >
                        <ods-button type="secondary" size="small">
                          {{ $t("view") }}
                        </ods-button>
                      </a>
                      <p
                        v-if="!scope.row.attachment.fileUri"
                        style="text-align:center"
                      >
                        ---
                      </p>
                    </template> -->
                    <template slot-scope="scope">
                      <ods-button
                        v-if="scope.row.attachment.fileUri"
                        type="secondary"
                        size="small"
                        @click="
                          toggleImageModal(
                            scope.row.attachment.fileUri,
                            scope.row
                          )
                        "
                      >
                        {{ $t("view") }}
                      </ods-button>
                      <p
                        v-if="!scope.row.attachment.fileUri"
                        style="text-align: center"
                      >
                        ---
                      </p>
                    </template>
                  </ods-table-column>
                  <ods-table-column
                    prop="incomplete"
                    :label="$t('incomplete')"
                    width="100"
                  >
                    <template slot-scope="scope">
                      <ods-checkbox
                        :disabled="
                          currentStatus === 'Incomplete' ||
                          currentStatus === 'Approved' ||
                          currentStatus === 'Change Service Type' ||
                          currentStatus === 'Rejected'
                        "
                        v-model="scope.row.attachment.incomplete"
                        @change="handleChangeAttachment(scope.row)"
                      >
                      </ods-checkbox>
                    </template>
                  </ods-table-column>
                </ods-table>
              </ods-accordion-item>
            </ods-module>

            <ods-module class="mt-1">
              <ods-accordion-item
                :key="'11'"
                :name="'11'"
                :title="$t('statusHistory')"
                :disabled="disabled"
              >
                <ods-scrollbar
                  wrap-class="wrap-styles"
                  :tag="'div'"
                  v-bind:native="false"
                  :wrapStyle="{
                    height: '300px',
                    'border-radius': '8px',
                    padding: '10px',
                  }"
                >
                  <ods-timeline class="mt-1 ml-1" :reverse="false">
                    <ods-timeline-item
                      v-for="(activity, index) in statusHistoryData"
                      :key="index"
                      :icon="
                        activity.status.status_master === 'New Request'
                          ? 'ods-icon-clip'
                          : activity.status.status_master === 'Rejected'
                          ? 'ods-icon-error'
                          : activity.status.status_master === 'Approved'
                          ? 'ods-icon-pushpin'
                          : activity.status.status_master === 'Cancelled'
                          ? 'ods-icon-delete'
                          : activity.status.status_master ===
                            'Approved Submited Fail'
                          ? 'ods-icon-info-alert'
                          : 'ods-icon-shield'
                      "
                      placement="right"
                      size="large"
                      :color="
                        activity.status.status_master === 'New Request'
                          ? '#0f5398'
                          : activity.status.status_master === 'Rejected' ||
                            activity.status.status_master === 'Cancelled' ||
                            activity.status.status_master ===
                              'Approved Submited Fail'
                          ? '#a50000'
                          : '#00780a'
                      "
                      hide-timestamp
                    >
                      <ods-row :gutter="24">
                        <ods-col :md="8" :sm="8">
                          <p class="font-weight-bold">
                            {{ activity.updatedAt | date(true) }}
                          </p>
                        </ods-col>
                        <ods-col :md="8" :sm="8">
                          <span class="font-weight-bold"
                            >{{ $t("user") }}:</span
                          >
                          {{ activity.updatedBy ? activity.updatedBy : "---" }}
                        </ods-col>
                        <ods-col :md="8" :sm="8">
                          <span class="font-weight-bold"
                            >{{ $t("status") }}:</span
                          >
                          {{ activity.status.status_master }}
                        </ods-col>
                      </ods-row>
                      <ods-row :gutter="24">
                        <ods-col :md="8" :sm="8">
                          <span class="font-weight-bold"
                            >{{ $t("propertyCategory") }}:</span
                          >
                          {{
                            activity.propertyCategory.descriptionEn
                              ? activity.propertyCategory.descriptionEn
                              : "---"
                          }}
                        </ods-col>
                        <ods-col :md="8" :sm="8">
                          <span class="font-weight-bold"
                            >{{ $t("propertyType") }}:</span
                          >
                          {{
                            activity.propertyType.descriptionEn
                              ? activity.propertyType.descriptionEn
                              : "---"
                          }}
                        </ods-col>
                        <ods-col :md="8" :sm="8">
                          <span
                            v-if="activity.purchaseDate"
                            class="font-weight-bold"
                            >{{ $t("purchaseDate") }}:
                            {{ activity.purchaseDate | date2 }}</span
                          >
                          <span
                            v-if="!activity.purchaseDate"
                            class="font-weight-bold"
                            >{{ $t("purchaseDate") }}: ---</span
                          >
                        </ods-col>
                      </ods-row>
                      <ods-row :gutter="24">
                        <ods-col :md="8" :sm="8">
                          <span
                            v-if="activity.leaseDate"
                            class="font-weight-bold"
                            >{{ $t("leaseDate") }}:
                            {{ activity.leaseDate | date2 }}</span
                          >
                          <span
                            v-if="!activity.leaseDate"
                            class="font-weight-bold"
                            >{{ $t("leaseDate") }}: ---</span
                          >
                        </ods-col>
                        <ods-col :md="8" :sm="8">
                          <span class="font-weight-bold"
                            >{{ $t("leaseAmount") }}:</span
                          >
                          {{
                            activity.leaseAmount ? activity.leaseAmount : "---"
                          }}
                        </ods-col>
                      </ods-row>
                      <ods-row :gutter="24">
                        <ods-col :md="8" :sm="8">
                          <span class="font-weight-bold"
                            >{{ $t("paymentDeductionOption") }}:</span
                          >{{
                            activity.paymentDeductionOption === "CMAX"
                              ? $t("creditCard")
                              : $t("bankAccount")
                          }}
                        </ods-col>
                        <!-- <ods-col :md="8" :sm="8">
                          <span class="font-weight-bold"
                            >{{ $t("insuranceFees") }}:</span
                          >
                          {{
                            activity.insuranceFees
                              ? activity.insuranceFees
                              : "---"
                          }}
                        </ods-col> -->
                      </ods-row>
                      <ods-row :gutter="24">
                        <ods-col :md="8" :sm="8">
                          <span class="font-weight-bold"
                            >{{ $t("comments") }}:</span
                          >
                          {{ activity.comments ? activity.comments : "---" }}
                        </ods-col>
                        <ods-col :md="8" :sm="8">
                          <span class="font-weight-bold"
                            >{{ $t("internalComments") }}:</span
                          >
                          {{
                            activity.internalComments
                              ? activity.internalComments
                              : "---"
                          }}
                        </ods-col>
                      </ods-row>
                    </ods-timeline-item>
                  </ods-timeline>
                </ods-scrollbar>
              </ods-accordion-item>
            </ods-module>

            <ods-module class="mt-1">
              <ods-accordion-item
                :key="'12'"
                :name="'12'"
                :title="`${$t('statusDetails')} - ${$t('requestLanguage')} : ${
                  form.submittedLanguage === 'en' ? 'English' : 'Arabic'
                }`"
                :disabled="disabled"
              >
                <ods-row :gutter="24">
                  <ods-col :md="12">
                    <ods-form-item :label="$t('comments')" prop="comments">
                      <ods-input
                        v-model="form.comments"
                        v-bind:autosize="true"
                        :disabled="
                          currentStatus === 'Incomplete' ||
                          currentStatus === 'Approved' ||
                          currentStatus === 'Change Service Type' ||
                          currentStatus === 'Rejected'
                        "
                        type="textarea"
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('internalComments')"
                      prop="internalComments"
                    >
                      <ods-input
                        v-model="form.internalComments"
                        v-bind:autosize="true"
                        :disabled="
                          currentStatus === 'Incomplete' ||
                          currentStatus === 'Approved' ||
                          currentStatus === 'Change Service Type' ||
                          currentStatus === 'Rejected'
                        "
                        type="textarea"
                      ></ods-input>
                    </ods-form-item>
                  </ods-col>
                </ods-row>
                <ods-row :gutter="24">
                  <ods-col :md="12" v-if="currentStatus === 'Rejected'">
                    <ods-form-item
                      :label="$t('createServiceOrder')"
                      prop="createServiceOrder"
                    >
                      <br />
                      <ods-radio-group
                        v-model="form.createServiceOrder"
                        :disabled="
                          currentStatus === 'Incomplete' ||
                          currentStatus === 'Approved' ||
                          currentStatus === 'Change Service Type' ||
                          currentStatus === 'Rejected'
                        "
                      >
                        <ods-radio :label="true">
                          {{ $t("yes") }}
                        </ods-radio>
                        <ods-radio :label="false">
                          {{ $t("no") }}
                        </ods-radio>
                      </ods-radio-group>
                    </ods-form-item>
                  </ods-col>
                </ods-row>
              </ods-accordion-item>
            </ods-module>
          </ods-accordion>
          <ods-module
            class="mt-1"
            :header="$t('newStatus')"
            v-if="statuses.length > 0"
          >
            <template slot="header">
              <ods-row :gutter="20">
                <ods-col :md="16">
                  {{ $t("newStatus") }}
                </ods-col>
                <ods-col :md="8" class="text-right">
                  <ods-checkbox
                    v-model="noSelectStatus"
                    checkboxLabel="my-checkbox"
                    @change="handleNoSelectStatus"
                  >
                    {{ $t("noSelectStatus") }}
                  </ods-checkbox>
                </ods-col>
              </ods-row>
            </template>
            <ods-row type="flex" justify="center">
              <div v-for="newStatus in statuses" :key="newStatus.id">
                <ods-button
                  type="secondary"
                  size="medium"
                  :disabled="noSelectStatus"
                  :class="
                    newStatus.createdAt ? 'active mr-1 mb-1' : 'mr-1 mb-1'
                  "
                  @click="changeSelectedStatus(newStatus)"
                >
                  {{ newStatus.nextStatus.statusMaster }}
                </ods-button>
              </div>
            </ods-row>
          </ods-module>
        </ods-row>
      </ods-form>
      <div class="mt-1">
        <form-buttons
          :disabledSubmit="
            currentStatus === 'Incomplete' || currentStatus === 'Approved'
          "
          @submit="submitForm"
          :verified="currentStatus === 'Approved' && !form.isVerified"
          @verified="toggleDialog()"
          :cancelText="'back'"
          cancel-to="RequestDashboard"
          :form="form"
        ></form-buttons>
      </div>
    </ods-module>

    <dialog-confirmation
      :visible="verifingVisible"
      v-bind:verify="true"
      :loading="verifingLoading"
      @confirm="verifyRequest"
      @cancel="toggleDialog()"
    />
    <img-viewer ref="viewer" />
  </section>
</template>

<script>
import handlePromise from '@/utils/promise'
import DialogConfirmation from '@/custom-components/DialogConfirmation'
import moment from 'moment'
import _ from 'lodash'
import Vue from 'vue'
import errors from '@/config/InputErrors'
import FormButtons from '@/custom-components/Buttons/FormButtons'
import RequestService from '@/services/Request'
import StatusMapping from '@/services/StatusMapping'
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib'
import ImgViewer from './ImgViewer'

export default {
  name: 'RequestDetail',
  props: {
    params: Object
  },
  components: {
    FormButtons,
    DialogConfirmation,
    ImgViewer
  },
  data () {
    return {
      loading: false,
      form: {},
      errors,
      activeNames: [
        '0',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12'
      ],
      customersTypes: [],
      propertyCategories: [],
      propertiesTypes: [],
      billLanguages: [],
      statuses: [],
      currentStatus: {},
      cStatus: {},
      meterReadingData: [],
      statusHistoryData: [],
      attachmentsDetailsData: [],
      attachmentByRequest: [],
      attachmentPdf: null,
      RSTarif: [],
      accordion: false,
      disabled: false,
      data: [],
      referenceNumber: '',
      requestType: '',
      closeAttachments: false,
      verifingVisible: false,
      verifingLoading: false,
      noSelectStatus: false,
      noRSCode: false,
      hasNoAttachment: true,
      noInitialLeaseDate: false,
      currentLeaseDate: null,
      noInitialPurchaseDate: false,
      currentPurchaseDate: null,
      imageAttachmentArray: [],
      currentCustomerType: null,
      meterReadingDate: ''
    }
  },
  async mounted () {
    await this.getData()
    this.getSecundaryData()
    this.getHistory()
    this.getMeterReading()
    this.getTarif()
  },
  methods: {
    async getData () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(
        RequestService.getRequestDetail(this.$route.params.id)
      )
      this.toggleLoading()
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      this.form = data
      this.form.leaseAmount = Vue.filter('formatNumber')(this.form.leaseAmount)
      // this.form.insuranceFees = Vue.filter("formatNumber")(
      //   this.form.insuranceFees
      // );
      this.form.supplyAddressId = this.form.supplyAddressId
        ? Vue.filter('formatSupplyAddress')(
          this.form.supplyAddressId.replace(/\-/g, ''),
          this.form.governorate.nameEn
            ? this.form.governorate.nameEn
            : this.form.governorate.nameAr
        )
        : '---'
      this.form.isFullyFurnished = this.form.isFullyFurnished ? 1 : 0
      this.form.customerType = data.customerType.id
      this.form.propertyCategory = data.propertyCategory.id
      this.form.propertyType = data.propertyType.id
      this.referenceNumber = data.referenceNumber
      this.requestType = data.serviceType
      this.currentStatus = data.status.statusMaster
      this.cStatus = data.status
      this.form.customer.fullNameEn =
        `${data.customer.firstNameEn ? data.customer.firstNameEn : ''} ` +
        `${data.customer.middleName1En ? data.customer.middleName1En : ''} ` +
        `${data.customer.middleName2En ? data.customer.middleName2En : ''} ` +
        `${data.customer.middleName3En ? data.customer.middleName3En : ''} ` +
        `${data.customer.middleName4En ? data.customer.middleName4En : ''} ` +
        `${data.customer.familyNameEn ? data.customer.familyNameEn : ''} `
      this.form.customer.fullNameAr =
        `${data.customer.firstNameAr ? data.customer.firstNameAr : ''} ` +
        `${data.customer.middleName1Ar ? data.customer.middleName1Ar : ''} ` +
        `${data.customer.middleName2Ar ? data.customer.middleName2Ar : ''} ` +
        `${data.customer.middleName3Ar ? data.customer.middleName3Ar : ''} ` +
        `${data.customer.middleName4Ar ? data.customer.middleName4Ar : ''} ` +
        `${data.customer.familyNameAr ? data.customer.familyNameAr : ''} `
      if (!this.form.leaseDate) {
        this.noInitialLeaseDate = true
      } else {
        this.currentLeaseDate = this.form.leaseDate
      }
      if (!this.form.purchaseDate) {
        this.noInitialPurchaseDate = true
      } else {
        this.currentPurchaseDate = this.form.purchaseDate
      }
      await this.getStatuses()
      await this.getAtachment()
    },
    async getSecundaryData () {
      await this.getcustomersTypes()
      await this.getpropertyCategories()
      this.fetchOwnerDetails()
      this.getpropertyTypes()
      this.onChangeCustomerType(this.form.customerType)
    },
    async getcustomersTypes () {
      let params = {
        limit: -1
      }
      this.toggleLoading()
      const [error, response, data] = await handlePromise(
        RequestService.getCustomersTypes(params)
      )
      this.toggleLoading()
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      this.customersTypes = data.data
    },
    async getpropertyCategories () {
      let params = {
        limit: -1
      }
      this.toggleLoading()
      const [error, response, data] = await handlePromise(
        RequestService.getPropertyCategories(params)
      )
      this.toggleLoading()
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      this.propertyCategories = data.data
    },
    onChangeCustomerType (value) {
      this.currentCustomerType = _.find(this.customersTypes, ['id', value])
      if (this.currentCustomerType.descriptionEn === 'Owner') {
        this.form.leaseDate = null
      }
    },
    async getpropertyTypes () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(
        RequestService.getPropertyTypeByCategory(this.form.propertyCategory)
      )
      this.toggleLoading()
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      this.propertiesTypes = data.data
    },
    async getPropertyTypesByCategory () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(
        RequestService.getPropertyTypeByCategory(this.form.propertyCategory)
      )
      this.toggleLoading()
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      this.propertiesTypes = data.data
      if (data.total > 0) {
        this.form.propertyType = data.data[0].id
        this.getTarif(true)
      } else {
        this.form.propertyType = null
      }
    },
    async getAtachment () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(
        RequestService.getRequestAtachment(this.$route.params.id)
      )
      this.toggleLoading()
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      this.attachmentByRequest = data.data
    },
    toggleImageModal (val, row) {
      let type = row.attachment.fileType
      if (
        !type ||
        (!type.includes('image') &&
          !type.includes('jpg') &&
          !type.includes('png') &&
          !type.includes('jpeg'))
      ) {
        return window.open(val)
      }
      if (val) {
        this.imageAttachmentArray = [
          {
            thumbnail: val,
            source: val
          }
        ]
        this.$refs.viewer.show(this.imageAttachmentArray)
      }
    },
    async getHistory () {
      this.toggleLoading()
      let params = {
        limit: -1
      }
      const [error, response, data] = await handlePromise(
        RequestService.getHistory(this.$route.params.id, params)
      )
      this.toggleLoading()
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      this.statusHistoryData = _.reverse(
        _.uniqBy(data.data, function (e) {
          return moment(e.updatedAt).utc().format('DD/MM/YYYY h:mm A')
        })
      )
    },
    handleMeterReadingDateValidation (row) {
      if (
        new Date(row.lastReadingDate).getTime() >=
        new Date(row.presentReadingDate).getTime()
      ) {
        this.$store.commit(
          'settings/toggleAlert',
          'The present meter reading must be greater than the last reading date'
        )
        return false
      } else {
        return true
      }
    },
    handleCurrentMeterReadingDate (date) {
      if (date) {
        this.meterReadingDate = date
      }
    },
    disabledReadingDate (date) {
      return moment().isBefore(date, 'day')
    },
    async estimateConsumption () {
      this.toggleLoading()
      const [error2, response2, data2] = await handlePromise(
        RequestService.getEstimateConsumption(this.$route.params.id)
      )
      this.toggleLoading()
      this.getMeterReading()
    },
    async getMeterReading () {
      let params = {
        limit: -1
      }
      this.toggleLoading()
      const [error, response, data] = await handlePromise(
        RequestService.getMeterReadigns(this.$route.params.id, params)
      )
      this.toggleLoading()
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      this.meterReadingData = data.data
      this.meterReadingData.map((item, index) => {
        this.meterReadingData[index].presentReading = Vue.filter(
          'formatNumber'
        )(item.presentReading)
      })
    },
    async getStatuses () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(
        StatusMapping.getStatusMap(this.form.status.id)
      )
      this.toggleLoading()
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      this.statuses = data.data
      this.statuses.map((item) => {
        item.createdAt = false
      })
      if (this.requestType.code !== '03' && this.requestType.code !== '04') {
        this.statuses = _.filter(this.statuses, function (s) {
          return s.nextStatus.statusMaster !== 'Change Service Type'
        })
      }
    },
    onchangeTarifElectricity () {
      if (this.form.rsdescElectricity2) {
        let value = this.form.rsdescElectricity2
        this.form.rsdescWater2 = value
      }
    },
    onchangeTarifWater () {
      if (this.form.rsdescWater2) {
        let value = this.form.rsdescWater2
        this.form.rsdescElectricity2 = value
      }
    },
    async getTarif (changePropertyType) {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(
        RequestService.getTarif(this.form.propertyType)
      )
      this.toggleLoading()
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      this.RSTarif = data
      if (changePropertyType) {
        this.form = {
          ...this.form,
          rsdescElectricity2: null,
          rsdescWater2: null,
          rsdescRate2: null
        }
      } else {
        this.form = {
          ...this.form,
          rsdescElectricity2: this.form.rsdescElectricity
            ? this.form.rsdescElectricity.id
            : null,
          rsdescWater2: this.form.rsdescWater ? this.form.rsdescWater.id : null,
          rsdescRate2: this.form.rsdescRate ? this.form.rsdescRate.id : null
        }
      }
    },
    async mergeAttachments () {
      await this.checkAttachments()

      if (this.hasNoAttachment) {
        return this.$notify({
          title: 'Warning',
          message: this.$t('noAttachments'),
          type: 'warning'
        })
      }

      this.toggleLoading()
      await this.loopAttachments()
      var blob = new Blob([this.attachmentPdf], { type: 'application/pdf' })
      this.toggleLoading()
      window.open(URL.createObjectURL(blob))
    },

    async checkAttachments () {
      return Promise.all(
        this.attachmentByRequest.map(async (attachment) => {
          if (attachment.attachment.fileUri) {
            this.hasNoAttachment = false
          }
        })
      )
    },

    async loopAttachments () {
      const pdfDoc = await PDFDocument.create()
      const header = pdfDoc.addPage()
      const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica)
      let text = 'All Attachments Merged'
      let textSize = 15

      header.drawText(text, {
        x: 80,
        y: 600,
        size: textSize,
        font: helveticaFont,
        color: rgb(0.156, 0.154, 0.154)
      })
      header.drawRectangle({
        x: 40,
        y: 450,
        width: 10,
        height: 1000,
        borderWidth: 1.5,
        color: rgb(0, 0.53, 0.71)
      })
      header.drawRectangle({
        x: 0,
        y: 700,
        width: 500,
        height: 10,
        borderWidth: 1.5,
        color: rgb(0, 0.53, 0.71)
      })
      this.attachmentPdf = await pdfDoc.save()
      return Promise.all(
        this.attachmentByRequest.map(async (attachment) => {
          let url = attachment.attachment.fileUri
          if (
            attachment.attachment.fileType === 'application/pdf' ||
            attachment.attachment.fileType === 'pdf'
          ) {
            let pdfBytes = await fetch(url).then((res) => res.arrayBuffer())
            let pdfDocaux = await PDFDocument.load(pdfBytes)

            const header = pdfDoc.addPage()
            const helveticaFont = await pdfDoc.embedFont(
              StandardFonts.Helvetica
            )
            let text = `${attachment.documentDescription} (${attachment.attachment.fileType})`
            let textSize = 15

            if (text.length > 50) {
              let aux = text.split(' ')
              let str1 = []
              let str2 = []

              aux.map((item, index) => {
                if (index < aux.length / 2) {
                  str1.push(item)
                } else {
                  str2.push(item)
                }
              })

              str1 = str1.join(' ')
              str2 = str2.join(' ')

              header.drawText(str1, {
                x: 80,
                y: 600,
                size: textSize,
                font: helveticaFont,
                color: rgb(0.156, 0.154, 0.154)
              })
              header.drawText(str2, {
                x: 80,
                y: 581,
                size: textSize,
                font: helveticaFont,
                color: rgb(0.156, 0.154, 0.154)
              })
            } else {
              header.drawText(text, {
                x: 80,
                y: 600,
                size: textSize,
                font: helveticaFont,
                color: rgb(0.156, 0.154, 0.154)
              })
            }
            header.drawRectangle({
              x: 40,
              y: 450,
              width: 10,
              height: 1000,
              borderWidth: 1.5,
              color: rgb(0, 0.53, 0.71)
            })

            header.drawRectangle({
              x: 0,
              y: 700,
              width: 500,
              height: 10,
              borderWidth: 1.5,
              color: rgb(0, 0.53, 0.71)
            })

            const copiedPagesA = await pdfDoc.copyPages(
              pdfDocaux,
              pdfDocaux.getPageIndices()
            )
            copiedPagesA.forEach((page) => pdfDoc.addPage(page))

            this.attachmentPdf = await pdfDoc.save()
          }
          if (
            attachment.attachment.fileType === 'image/jpeg' ||
            attachment.attachment.fileType === 'image/jpg' ||
            attachment.attachment.fileType === 'jpeg' ||
            attachment.attachment.fileType === 'jpg'
          ) {
            let imageBytes = await fetch(url).then((res) => res.arrayBuffer())

            const header = pdfDoc.addPage()
            const helveticaFont = await pdfDoc.embedFont(
              StandardFonts.Helvetica
            )
            let text = `${attachment.documentDescription} (${attachment.attachment.fileType})`
            let textSize = 15

            if (text.length > 50) {
              let aux = text.split(' ')
              let str1 = []
              let str2 = []

              aux.map((item, index) => {
                if (index < aux.length / 2) {
                  str1.push(item)
                } else {
                  str2.push(item)
                }
              })

              str1 = str1.join(' ')
              str2 = str2.join(' ')

              header.drawText(str1, {
                x: 80,
                y: 600,
                size: textSize,
                font: helveticaFont,
                color: rgb(0.156, 0.154, 0.154)
              })
              header.drawText(str2, {
                x: 80,
                y: 581,
                size: textSize,
                font: helveticaFont,
                color: rgb(0.156, 0.154, 0.154)
              })
            } else {
              header.drawText(text, {
                x: 80,
                y: 600,
                size: textSize,
                font: helveticaFont,
                color: rgb(0.156, 0.154, 0.154)
              })
            }

            header.drawText(text, {
              x: 80,
              y: 600,
              size: textSize,
              font: helveticaFont,
              color: rgb(0.156, 0.154, 0.154)
            })
            header.drawRectangle({
              x: 40,
              y: 450,
              width: 10,
              height: 1000,
              borderWidth: 1.5,
              color: rgb(0, 0.53, 0.71)
            })
            header.drawRectangle({
              x: 0,
              y: 700,
              width: 500,
              height: 10,
              borderWidth: 1.5,
              color: rgb(0, 0.53, 0.71)
            })

            const jpegImage = await pdfDoc.embedJpg(imageBytes)
            const page = pdfDoc.addPage()
            const jpegDims = jpegImage.scaleToFit(
              page.getWidth(),
              page.getHeight()
            )
            page.drawImage(jpegImage, {
              y: page.getHeight() - jpegDims.height - 40,
              width: jpegDims.width,
              height: jpegDims.height
            })

            this.attachmentPdf = await pdfDoc.save()
          }
          if (
            attachment.attachment.fileType === 'image/png' ||
            attachment.attachment.fileType === 'png'
          ) {
            let imageBytes = await fetch(url).then((res) => res.arrayBuffer())

            const header = pdfDoc.addPage()
            const helveticaFont = await pdfDoc.embedFont(
              StandardFonts.Helvetica
            )
            let text = `${attachment.documentDescription} (${attachment.attachment.fileType})`
            let textSize = 15

            if (text.length > 50) {
              let aux = text.split(' ')
              let str1 = []
              let str2 = []

              aux.map((item, index) => {
                if (index < aux.length / 2) {
                  str1.push(item)
                } else {
                  str2.push(item)
                }
              })

              str1 = str1.join(' ')
              str2 = str2.join(' ')

              header.drawText(str1, {
                x: 80,
                y: 600,
                size: textSize,
                font: helveticaFont,
                color: rgb(0.156, 0.154, 0.154)
              })
              header.drawText(str2, {
                x: 80,
                y: 581,
                size: textSize,
                font: helveticaFont,
                color: rgb(0.156, 0.154, 0.154)
              })
            } else {
              header.drawText(text, {
                x: 80,
                y: 600,
                size: textSize,
                font: helveticaFont,
                color: rgb(0.156, 0.154, 0.154)
              })
            }

            header.drawRectangle({
              x: 40,
              y: 450,
              width: 10,
              height: 1000,
              borderWidth: 1.5,
              color: rgb(0, 0.53, 0.71)
            })
            header.drawRectangle({
              x: 0,
              y: 700,
              width: 500,
              height: 10,
              borderWidth: 1.5,
              color: rgb(0, 0.53, 0.71)
            })

            const pngImage = await pdfDoc.embedPng(imageBytes)
            const page = pdfDoc.addPage()
            const pngDims = pngImage.scaleToFit(
              page.getWidth(),
              page.getHeight()
            )
            page.drawImage(pngImage, {
              y: page.getHeight() - pngDims.height - 40,
              width: pngDims.width,
              height: pngDims.height
            })

            this.attachmentPdf = await pdfDoc.save()
          }
        })
      )
    },

    changeSelectedStatus (newStatus) {
      this.statuses.map((item) => {
        item.createdAt = false
      })

      this.statuses.map((item) => {
        if (item.id === newStatus.id) {
          item.createdAt = true
        }
      })

      if (newStatus.nextStatus.statusMaster === 'Approved') {
        this.meterReadingData.map((item) => {
          if (!item.presentReading) {
            this.statuses.map((item2) => {
              item2.createdAt = false
            })
            return this.$store.commit(
              'settings/toggleAlert',
              this.$t('meterReadingNotNullWhenApproved')
            )
          }
        })
      }
      this.form.status = newStatus.nextStatus
    },
    handleNoSelectStatus (val) {
      this.form.status = this.cStatus
      this.statuses.map((item) => {
        item.createdAt = false
      })
    },
    toggleDialog () {
      this.verifingVisible = !this.verifingVisible
    },
    async verifyRequest () {
      this.form.isVerified = 'true'
      this.submitForm()
      this.toggleDialog()
    },
    async submitForm () {
      if (!this.form.leaseDate && !this.form.purchaseDate) {
        return this.$store.commit(
          'settings/toggleAlert',
          this.$t('youMustSpecifyDate')
        )
      }
      if (
        this.form.status.statusMaster === 'Incomplete' &&
        (!this.form.comments ||
          !_.find(this.attachmentByRequest, function (a) {
            return a.attachment.incomplete
          }))
      ) {
        return this.$store.commit(
          'settings/toggleAlert',
          this.$t('fillCommentsAttachmentsBeforeSave')
        )
      }
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          let data = {
            customerType: this.form.customerType,
            propertyType: this.form.propertyType,
            leaseDate: this.form.leaseDate
              ? moment(this.form.leaseDate).format('YYYY-MM-DD')
              : '',
            customerComment: this.form.customerComment
              ? this.form.customerComment
              : '',
            propertyCategory: this.form.propertyCategory,
            purchaseDate: this.form.purchaseDate
              ? moment(this.form.purchaseDate).format('YYYY-MM-DD')
              : '',
            leaseAmount: this.form.leaseAmount
              ? this.checkString(this.form.leaseAmount)
                ? this.form.leaseAmount.replace(/,/g, '')
                : this.form.leaseAmount
              : 0,
            isFullyFurnished:
              this.form.isFullyFurnished === 1 ? 'true' : 'false',
            additionalNotesToCss: this.form.additionalNotesToCss,

            // 'customerName': {
            //   'firstNameAr': this.form.customer.firstNameAr,
            //   'middleName1Ar': this.form.customer.middleName1Ar,
            //   'middleName2Ar': this.form.customer.middleName2Ar,
            //   'middleName3Ar': this.form.customer.middleName3Ar,
            //   'middleName4Ar': this.form.customer.middleName4Ar,
            //   'familyNameAr': this.form.customer.familyNameAr
            // },
            paymentDeductionOption: this.form.paymentDeductionOption,
            // insuranceFees: this.form.insuranceFees
            //   ? this.checkString(this.form.insuranceFees)
            //     ? this.form.insuranceFees.replace(/,/g, "")
            //     : this.form.insuranceFees
            //   : 0,
            comments: this.form.comments ? this.form.comments : '',
            status: this.form.status.id,
            internalComments: this.form.internalComments
              ? this.form.internalComments
              : '',
            createServiceOrder: `${this.form.createServiceOrder}`,
            isVerified: this.form.isVerified ? 'true' : 'false',
            rsdescWater: this.form.rsdescWater2 ? this.form.rsdescWater2 : -1,
            rsdescElectricity: this.form.rsdescElectricity2
              ? this.form.rsdescElectricity2
              : -1,
            rsdescRate: this.form.rsdescRate2 ? this.form.rsdescRate2 : -1
          }
          this.toggleLoading()
          const [error, response] = await handlePromise(
            RequestService.updateRequest(this.$route.params.id, data)
          )
          this.toggleLoading()
          if (!response.ok) {
            return this.$store.commit('settings/toggleAlert', this.$t(error))
          }
          await this.getData()
          this.getTarif()
          this.getHistory()
          return this.$store.commit(
            'settings/toggleSuccessAlert',
            this.$t('requestUpdated')
          )
        }
      })
    },
    async saveMeterReading () {
      await this.meterReadingData.map(async (item) => {
        let presentReading = item.presentReading
          ? item.presentReading.split('.')
          : item.presentReading
        if (presentReading && presentReading[0].length > item.meterDigit) {
          return this.$store.commit(
            'settings/toggleAlert',
            `The current meter reading of ${item.meterNumber} cant have more than ${item.meterDigit}`
          )
        }
        let data = {
          presentReading: this.checkString(item.presentReading)
            ? item.presentReading.replace(/,/g, '')
            : item.presentReading,
          presentReadingDate: moment(item.presentReadingDate).format(
            'YYYY-MM-DD'
          )
        }
        this.toggleLoading()
        const [error, response] = await handlePromise(
          RequestService.updateMeterReading(item.id, data)
        )
        this.toggleLoading()
        if (!response.ok) {
          this.$store.commit('settings/toggleAlert', this.$t(error))
        }
        this.getMeterReading()
      })
    },
    async handleChangeAttachment (row) {
      const data = {
        incomplete: `${row.attachment.incomplete}`
      }
      const [error, response] = await handlePromise(
        RequestService.updateAtachment(row.attachment.id, data)
      )
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
    },
    async fetchOwnerDetails () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(
        RequestService.getOwnerDetails(this.$route.params.id)
      )
      this.toggleLoading()
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      this.form.propertyOwner = {
        name: data.name ? data.name : null,
        idNumber: data.idNumber ? data.idNumber : null,
        idType: {
          descriptionEn: data.idType ? data.idType.descriptionEn : null
        }
      }
    },
    async fetchOwnerDetailsOUC () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(
        RequestService.getOwnerDetailsOUC(this.form.referenceNumber)
      )
      this.toggleLoading()
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      this.form.propertyOwner = {
        name: data.name,
        idNumber: data.idNumber,
        idType: {
          descriptionEn: data.idType.descriptionEn
        }
      }
    },

    async fetchMeterReadingsDetails () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(
        RequestService.getMeterRadingsDetails(this.form.referenceNumber)
      )
      this.toggleLoading()
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      this.meterReadingData = data.meterDetails
    },

    async fetchRSCodesDetails () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(
        RequestService.getRSCodesDetails(this.form.referenceNumber)
      )
      this.toggleLoading()
      if (!response.ok) {
        return this.$store.commit('settings/toggleAlert', this.$t(error))
      }
      data.requestRscode.map((item) => {
        if (item.serviceEnglish === 'Electricity') {
          this.form.rsExistingSpnElectricity = item.serviceId
          this.form.rsStatusElectricity = item.statusEnglish
          this.form.rsCodeActualElectricity = item.rscDescEnglish
        }
        if (item.serviceEnglish === 'Water') {
          this.form.rsExistingSpnWater = item.serviceId
          this.form.rsStatusWater = item.statusEnglish
          this.form.rsCodeActualWater = item.rscDescEnglish
        }
        if (item.serviceEnglish === 'Rate') {
          this.form.rsExistingSpnRate = item.serviceId
          this.form.rsStatusRate = item.statusEnglish
          this.form.rsCodeActualRate = item.rscDescEnglish
        }
      })
      this.form = {
        ...this.form,
        rsdescElectricity2: this.form.rsdescElectricity.id,
        rsdescWater2: this.form.rsdescWater.id,
        rsdescRate2: this.form.rsdescRate.id
      }
    },
    formatNumber (val, index) {
      this.meterReadingData[index].presentReading = Vue.filter(
        'formatNumberForm'
      )(val.replace(/[^\d.-]/g, ''))
    },
    checkString (val) {
      if (typeof val === 'string') {
        return true
      }
      return false
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    disabledLeaseDateTo (date) {
      return moment().isBefore(date, 'day')
    },
    disabledPurchaseDateTo (date) {
      return moment().isBefore(date, 'day')
    }
  },
  watch: {
    'form.leaseAmount': function (val) {
      this.form.leaseAmount = Vue.filter('formatNumberForm')(
        val.replace(/[^\d.-]/g, '')
      )
    }
    // "form.insuranceFees": function (val) {
    //   this.form.insuranceFees = Vue.filter("formatNumberForm")(
    //     val.replace(/[^\d.-]/g, "")
    //   );
    // },
  }
}
</script>
<style scoped>
.resaltField {
  box-shadow: 5px 5px 5px rgb(204, 204, 204);
}
.active {
  box-shadow: 5px 5px 5px rgb(185, 184, 184);
  color: rgb(0, 0, 0);
}

.radioButton {
  pointer-events: none;
}
.rsNoData {
  color: #00417a;
  position: absolute;
  margin-left: 15%;
  margin-top: 10px;
}

.header {
  position: sticky;
  top: 0;
  z-index: 2000;
  box-shadow: 0px -100px 100px 100px rgb(250, 250, 250);
}
</style>
