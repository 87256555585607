import esLocale from '@/addons/locale/lang/es'

export default {
  ...esLocale,
  label: 'Español',
  labelMobile: 'ES',
  serverError: 'Ha ocurrido un error inesperado en el servidor. Inténtalo de nuevo.',
  email: 'Email',
  password: 'Contraseña',
  login: 'Entrar',
  edit: 'Editar',
  back: 'Regresar',
  cancel: 'Cancelar',
  users: 'Usuarios',
  name: 'Nombre',
  roles: 'Roles',
  enabled: 'Activo',
  admin: 'Admin',
  operator: 'Operador',
  superAdmin: 'Superadmin',
  editUser: 'Editar usuario',
  newUser: 'Crear usuario',
  externalAuthentication: 'Autenticación externa',
  confirm: 'Confirmar',
  deleteAlert: '¿Estás seguro que deseas borrar este elemento?',
  code: 'Código',
  errors: {
    required: 'Este campo es requerido',
    email: 'Por favor introduzca un email correcto',
    url: 'Por favor introduzca un URL correcta',
    phone: 'Por favor introduzca un número de teléfono correcto'
  },
  dashboard: 'Dashboard',
  filterCriteria: 'Criterios de búsqueda',
  referenceNumber: 'Número de referencia',
  accountNumber: 'Número de cuenta',
  customerIDNumber: 'Número de ID del cliente',
  customerPhoneNumber: 'Número de teléfono del cliente',
  serviceType: 'Tipo de servicio',
  electricityTechnicalServiceRequest: 'Solicitud de servicio técnico de electricidad',
  disconectionElectricityWate: 'Solicitud de Desconexión de Electricidad y Agua',
  moveWithDebtTransferCON: 'Solicitud de mudanza con transferencia de deuda (CON)',
  moveWithDebtTransferCOT: 'Solicitud de mudanza con transferencia de deuda (COT)',
  newElectricitySupply: 'Solicitud de nuevo suministro eléctrico',
  newWaterSupply: 'Solicitud de nuevo suministro de agua',
  reconnectionElectricityWater: 'Solicitud de Reconexión de Electricidad y Agua',
  waterTechnicalServiceRequest: 'Solicitud de servicio técnico de agua',
  status: 'Estado',
  approved: 'Aprobado',
  approvedCSSProcessError: 'Aprobado - Error de proceso OUC',
  approvedCSSSubmitFailed: 'Aprobado: envío de OUC fallido',
  approvedCSSSubmitSuccess: 'Aprobado: envío de OUC satisfactorio',
  approvedFileNetSubmitFailed: 'Aprobado: envío de FileNet fallido',
  approvedFileNetSubmitSuccess: 'Aprobado: envío de FileNet satisfactorio',
  approvedNewAccountGenerated: 'Approved - Nueva cuenta generada',
  cancelled: 'Cancelado',
  changeServiceType: 'Cambio del tipo de servicio',
  incomplete: 'Incompleto',
  newRequest: 'Nueva solicitud',
  rejected: 'Rechazada',
  governorate: 'Gobernación',
  blockNumber: 'Número de bloque',
  newAccountNumber: 'Nuevo número de cuenta',
  searchResult: 'Resultado de la Búsqueda',
  dateFrom: 'Fecha De',
  dateTo: 'Fecha Hasta',
  Capital: 'Capital',
  Northern: 'Northern',
  Muharraq: 'Muharraq',
  central: 'Central',
  Southern: 'Southern',
  search: 'Buscar',
  reset: 'Resetiar',
  requestDate: 'Fecha de Solicitud',
  idNumber: 'ID del Número',
  requestStatus: 'Estado de la Solicitud',
  lastModifiedBy: 'Última Modificación Por',
  requestDetails: 'Detalles de la Solicitud',
  ERROR_MESSAGE_REQUEST_INVALID_USER_PASSWORD_COMBINATION: 'Usuario o contraseña incorrecta',
  apiError: 'Ops se produjo un error',
  username: 'Nombre de usuario',
  changePassword: 'Cambiar contraseña',
  cpr: 'CPR',
  lastName: 'Apellidos',
  ROLE_API: 'Api',
  ROLE_OPERATOR: 'Operador',
  ROLE_CALL_CENTER: 'Centro de llamadas',
  passwordDontMatch: 'Las contraseña no coinciden',
  currentPassword: 'Contraseña actual',
  details: 'Detalles',
  accountDetails: 'Detalles de la cuenta',
  supplyAddress: 'Dirección de suministro',
  propertyDetails: 'Detalles de la propiedad',
  serviceRSCodeDetails: 'Detalles del código RS de servicio',
  mailingAddressDetails: 'Detalles de la dirección postal',
  customerDetails: 'Detalles del cliente',
  paymentDetails: 'Detalles de pago',
  attachmentsDetails: 'Detalles de los archivos adjuntos',
  statusDetails: 'Detalles del estado',
  meterReadingDetails: 'Detalles de la lectura de medidores',
  statusHistory: 'Historial del estado',
  customerType: 'Tipo de cliente',
  propertyType: 'Tipo de propiedad',
  purchaseDate: 'Fecha de compra',
  leaseDate: 'Fecha de arrendamiento',
  leaseAmount: 'Monto del arrendamiento (BD)',
  customerComments: 'Comentatios del cliente',
  fullyFurnished: 'Totalmente amueblado',
  yes: 'si',
  no: 'no',
  ownerInformation: 'Información del propietario',
  fetchOwnerDetails: 'Cargar los datos del propietario de nuevo',
  ownerName: 'Nombre del propietario',
  ownerIdentityType: 'Tipo de identificación del propietario',
  ownerIdentityNumber: 'Número de identidad del propietario',
  additionalNotes: 'Notas adicionales',
  addressType: 'Tipo de dirección',
  mailingAddress: 'Dirección de correo',
  idType: 'Tipo de ID',
  nameEnglish: 'Nombre en inglés',
  nameArabic: 'Nombre en árabe',
  nationality: 'Nacionalidad',
  mobileNumber: 'Número de celular',
  otherMobileNumber: 'Otro número de celular',
  telephoneNumber: 'Número de teléfono',
  fax: 'fax',
  requestLanguage: 'Idioma de la solicitud',
  comments: 'Comentarios',
  otherComments: 'Otros comentarios',
  statusInCSS: 'Estado en OUC',
  firstNameArabic: 'Primer nombre en árabe',
  middleName1Arabic: 'Segundo nombre 1 árabe',
  middleName2Arabic: 'Segundo nombre 2 árabe',
  middleName3Arabic: 'Segundo nombre 3 árabe',
  middleName4Arabic: 'Segundo nombre 4 árabe',
  familyNameArabic: 'Nombre árabe de familia',
  firstNameEnglish: 'Primer nombre en inglés',
  middleName1English: 'Segundo nombre 1 inglés',
  middleName2English: 'Segundo nombre 2 inglés',
  middleName3English: 'Segundo nombre 3 inglés',
  middleName4English: 'Segundo nombre 4 inglés',
  familyNameEnglish: 'Nombre inglés de familia',
  paymentDeductionOption: 'Opción de deducción de pago',
  creditCard: 'Targeta de crédito',
  bankAccount: 'Cuenta de banco',
  insuranceFees: 'Depositado',
  service: 'Servicio',
  consumptionType: 'Tipo de consumo',
  lastMeterReading: 'Última lectura de medidor',
  lastReadingDate: 'Última fecha de lectura',
  lastReadingType: 'Último tipo de lectura',
  currentMeterReading: 'Lectura actual del medidor',
  currentReadingDate: 'Fecha de la lectura actual',
  existingSPN: 'SPN existente',
  existingRSCode: 'Código RS existente',
  newRSCODE: 'Nuevo código RS',
  user: 'Usuario',
  internalComments: 'Comentarios internos',
  date: 'Fecha',
  documentName: 'Nombre del documento',
  documentDescription: 'Descripción del documento',
  viewDocument: 'Ver documento',
  view: 'Ver',
  mergeViewAttachments: 'Combinar y ver archivos adjuntos',
  'Request for  Reconnection of Electricity and Water': 'Solicitud de reconexión de electricidad y agua',
  'Request for Move-in - With debt transfer (CON)': 'Solicitud de mudanza - con transferencia de deuda (CON)',
  'Electricity Technical service request': 'Solicitud de servicio técnico de electricidad',
  'Water Technical service request': 'Solicitud de servicio técnico de agua',
  'Request for New Water Supply': 'Solicitud de nuevo suministro de agua',
  'Request for New Electricity Supply': 'Solicitud de nuevo suministro eléctrico',
  'Request for Move-in - Without debt transfer (COT)': 'Solicitud de mudanza - sin transferencia de deuda (COT)',
  'Request for Disconnection of Electricity and Water': 'Solicitud de desconexión de electricidad y agua',
  'Approved Submited Fail': 'Aprobado enviado fallido',
  Cancelled: 'Cancelada',
  'Approved - CSS Submit Failed': 'Aprobado - OUC enviado fallido',
  'Approved - CSS Submit Success': 'Aprobado - OUC enviado satisfactorio',
  'New Request': 'Nueva solicitud',
  Incomplete: 'Incompleta',
  'Request Updated By Customer': 'Solicitud actualizada por el cliente',
  Approved: 'Aprobada',
  Rejected: 'Rechazada',
  'Change Service Type': 'Cambiar el tipo de servicio',
  billLanguage: 'Idioma de al factura',
  isVerified: 'Está verificado',
  createServiceOrder: 'Crear orden de servicio',
  verified: 'Verificada',
  requestUpdated: 'Solicitud actualisada',
  electricityService: 'Servicio de Electricidad',
  waterService: 'Servicio de Agua',
  rate: 'Calificación',
  newRSDescription: 'Nueva descripción RS',
  sendedNotifications: 'Sended notifications',
  statusMaintenance: 'Mantenimiento de estados',
  statusInCSSDesc: 'Descripcion del Estado en OUC',
  tarif: 'Tarifa',
  currentStatus: 'Estado actual',
  newStatuses: 'Nuevos estados',
  notify: 'Notificar',
  notifications: 'Notificaciones',
  notificationMaintenance: 'Mantenimiento de notificaciones',
  creationDate: 'Fecha de creación',
  posibleChangeOfStatus: 'Posible cambio de estado a:',
  verifyAlert: 'Está usted seguro quiere verificar esta request? Esta tarea no se puede volver atras y la request quedará como verificada.',
  verify: 'Verificar',
  newStatus: 'Nuevo estado',
  noSelectStatus: 'No seleccionar estado',
  showAnimations: 'Mostrar animaciones',
  afterLoginRedirectTo: 'Después de autenticarse, ir a:',
  requestList: 'Lista de solicitudes',
  youNeedToReloginToSeeThisChange: 'Debe autenticarce nuevamente para ver este cambio',
  createNotification: 'Crear notificación',
  editNotification: 'Editar notificación',
  sendSMS: 'Enviar SMS',
  sendEmail: 'Enviar email',
  enSMS: 'SMS en inglés',
  arSMS: 'SMS en árabe',
  tags: 'Etiquetas',
  requestNumber: 'Número de solicitud',
  notificationSaved: 'Notificación salvada',
  smsTemplateEnRequired: 'El SMS en inglés es obligatorio',
  smsTemplateArRequired: 'El SMS en árabe es obligatorio',
  emailTemplateEnRequired: 'El Email en inglés es obligatorio',
  emailTemplateArRequired: 'El Email en árabe es obligatorio',
  forgotPassword: 'Olvidó la contraseña?',
  backToLogin: 'Volver a autenticarce',
  emailSended: 'Email enviado',
  send: 'Enviar',
  enEmail: 'Email en Inglés',
  arEmail: 'Email en Árabe',
  requestByStatus: 'Solicitudes por estado',
  verifiedRequests: 'Solicitudes verificadas',
  avgBetweenCreatedAndApproved: 'Promedio de duración en días entre solicitudes creadas y aprobadas',
  requestStatusByGovernorates: 'Estado de las solicitudes por estados',
  requestByGovernorates: 'Solicitudes por estados',
  requestByServiceTypes: 'Solicitudes por tipo de servicio',
  requestsByCreatedDate: 'Solicitudes por fecha de creadas',
  requestsApprovedByCreatedDate: 'Solicitudes aprobadas por fecha de creadas',
  notificationsSentTo: 'Notificaciones enviadas a:',
  attempt: 'Intento',
  error: 'Error',
  notificationType: 'Tipo de notificación',
  sentOn: 'Enviada en',
  notificationsSent: 'Notificaciones enviadas',
  ERROR_MESSAGE_METER_DETAIL_NOT_FOUND: 'No se encontraron lectura de medidores',
  ERROR_MESSAGE_USER_DISABLED: 'Este usuario no se encuentra activado',
  saveMeterReading: 'Salvar las lecturas actuales de los medidores',
  clearNotifications: 'Limpiar todas las notificaciones',
  noNotifications: 'No se encontraron notificaciones',
  youMustSpecifyDate: 'Tiene que especificar una fecha de compra o una fecha de arrendamiento',
  getLongAuthToken: 'Obtener token de larga duración',
  getToken: 'Generar token',
  statusOuc: 'Estado en OUC',
  meterReadingNotNullWhenApproved: 'Antes de pasar el estado a aprobado debe llenar las lecturas actuales en la sección de lectura de medidores',
  fetchMeterReading: 'Obtener lectura de medidores de nuevo',
  fetchRSCodes: 'Obtener código RS de servicio de nuevo',
  propertyCategory: 'Categoría de la propiedad',
  fetchStatusOUC: 'Obtener estado en OUC',
  docList: 'Lista de documentos',
  descriptionEn: 'Descripción-En',
  descriptionAr: 'Descripción-Ar',
  createDocList: 'Crear documento',
  editDocList: 'Editar documento',
  attachmentType: 'Tipo de adjunto',
  createAttachmentType: 'Crear tipo de adjunto',
  attachmentTypeMaintenance: 'Mantenimiento de tipos de adjuntos',
  editAttachmentType: 'Editar tipo de adjunto',
  payments: 'Pagos',
  totalAmount: 'Monto total',
  records: 'Entradas',
  channelId: 'Id del canal',
  transactionId: 'Id de la transacción',
  paymentDate: 'Fecha de pago',
  paymentDateFrom: 'Fecha de pago desde',
  paymentDateTo: 'Fecha de pago hasta',
  paidAmount: 'Monto pagado',
  authorizationCode: 'Código de autorización',
  billType: 'Tipo de factura',
  machine: 'Máquina',
  processed: 'Procesado',
  exportExcel: 'Export to excel',
  all: 'Todos',
  forAll: 'Para todos',
  docListFiltering: 'Filtrado de las listas de documentos',
  filters: 'Filtros',
  addFilter: 'Añadir filtro',
  attachmentsList: 'Lista de archivos adjuntos filtrados',
  ERROR_MESSAGE_USER_NOT_FOUND: 'Usuario no encontrado',
  noAttachments: 'No se encontraron archivos adjuntos',
  LogDetail: 'Detalle del log',
  result: 'Resultado',
  action: 'Acción',
  body: 'Cuerpo',
  statusResponse: 'Status response',
  response: 'Response',
  header: 'Header',
  origin: 'Origin',
  url: 'Url',
  createdAt: 'Creado en',
  logs: 'Logs',
  deleteAllLogs: 'Eliminar todos los logs',
  deleteAllLogsAlert: 'Está seguro de que desea eliminar todos los logs?',
  noRSCodeSelected: 'No hay código RS seleccionado',
  externalData: 'Datos externos',
  fetchExternalAgain: 'Obtener los datos externos nuevamente',
  fillCommentsAttachmentsBeforeSave: 'Antes de guardar la solicitud con estado incompleto, debe especificar un archivo adjunto incompleto y añadir un comentario',
  ERROR_MESSAGE_NOTIFICATION_NOT_FOUND: 'No se encontraron notificaciones',
  moreData: 'Información extra',
  supplyAddressEn: 'Dirección de suministro',
  supplyInformation: 'Información de la dirección de suministro',
  maillingAddress: 'Información de la dirección de envío',
  'cpr/username': 'cpr/nombre de usuario',
  ERROR_MESSAGE_STATUS_NOT_CHANGED: 'El estado de la solicitud no se cambió',
  admitedFiles: 'Solo se pueden combinar los tipos de archivo pdf, jpg, jpeg y png.',
  ERROR_MESSAGE_REQUEST_NOT_FOUND: 'Solicitud no encontrada',
  updatedBy: 'Updated by',
  oucAccount: 'Cuenta de OUC',
  defaultRequestStatus: 'Filtro de estado de solicitud predeterminado',
  estimateConsumption: 'Estimar consumo',
  passwordChanged: 'Password changed',
  ERROR_MESSAGE_PASSWORD_RECOVERY_REQUEST_HAS_ALREADY_BEEN_REQUESTED: 'Message recovery request has already been requested'
}
