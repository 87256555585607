<template>
  <section v-loading="loading">
     <ods-row :gutter="20" type="flex">
       <ods-module :header="$t('filters')" style="width:100%">
         <template slot="header">
            <list-header title="filters">
              <template slot="right" >
                <ods-button size="medium" @click="toggleEditDialog('creating')">
                  {{$t('addFilter')}}
                </ods-button>
              </template>
            </list-header>
          </template>
          <ods-table
            :data="data"
            size="mini"
            style="width: 100%">
            <ods-table-column :label="$t('blockNumber')" width="120">
              <template slot-scope="scope">
                {{scope.row.blockNumber ? scope.row.blockNumber : '---'}}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('idType')">
              <template slot-scope="scope">
                {{scope.row.idType ? scope.row.idType.descriptionEn : '---'}}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('customerType')">
              <template slot-scope="scope">
                {{scope.row.customerType ? scope.row.customerType.descriptionEn : '---'}}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('serviceType')">
              <template slot-scope="scope">
                {{scope.row.serviceType ? scope.row.serviceType.descriptionEn : '---'}}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('forAll')">
              <template slot-scope="scope">
                <ods-checkbox
                  v-model="scope.row.forAll"
                  disabled
                    >
                </ods-checkbox>
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('isMandatory')">
              <template slot-scope="scope">
                <ods-checkbox
                  v-model="scope.row.isMandatory"
                  disabled
                    >
                </ods-checkbox>
              </template>
            </ods-table-column>
            <ods-table-column align="right" width="80">
              <template slot-scope="scope">
                <ods-button
                  size="mini"
                  type="primary"
                  icon="ods-icon-edit" @click="toggleEditDialog(scope.row.id)"/>
              </template>
            </ods-table-column>
            <ods-table-column align="right" width="80">
              <template slot-scope="scope">
                <table-delete style="margin-top:-20px" @click="toggleDialog(scope.row.id)"></table-delete>
              </template>
            </ods-table-column>
          </ods-table>
          <ods-pagination
            :total="total"
            :page-size="pageSize"
            @size-change="handleSizeChange"
            @current-change="handlePageChange"
            layout="prev, pager, next, jumper, sizes, ->, total"
          >
          </ods-pagination>
       </ods-module>
       <dialog-confirmation
          :visible="dialogVisible"
          :loading="deleteLoading"
          @confirm="deleteItem"
          @cancel="toggleDialog()"
        />
        <docListEditModal v-if="editDialogVisible"
          :valueToEdit="valueToEdit"
          v-show="editDialogVisible"
          @close="toggleEditDialog()"
        />
     </ods-row>
  </section>
</template>

<script>
import handlePromise from '@/utils/promise'
import map from 'lodash/map'
import ListHeader from '@/custom-components/ListHeader'
import DoclistService from '@/services/Doclist'
import TableDelete from '@/custom-components/Buttons/TableDelete'
import DialogConfirmation from '@/custom-components/DialogConfirmation'
import docListEditModal from './EditDocListModal.vue'

export default {
  name: 'Doclist',
  components: {
    ListHeader,
    TableDelete,
    DialogConfirmation,
    docListEditModal
  },
  data () {
    return {
      loading: false,
      data: [],
      pageSize: 10,
      pageIndex: 0,
      pageStartZero: true,
      total: 0,
      sort: null,
      toDelete: null,
      deleteLoading: false,
      dialogVisible: false,
      editDialogVisible: false,
      valueToEdit: null
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      let auxParams = {}
      let params = {
        offset: this.pageIndex === 0 && this.pageStartZero ? 0 : (this.pageIndex * this.pageSize),
        limit: this.pageSize,
        ...auxParams
      }

      if (this.sort && this.sort.prop) params[`sortby[${this.sort.prop}]`] = this.sort.order === 'ascending' ? 'ASC' : 'DESC'
      this.toggleLoading()
      const [error, response, data] = await handlePromise(DoclistService.getDoclistByAttachmentType(this.$route.params.id, params))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.data = data.data
      this.total = data.total
    },
    toggleDialog (id) {
      this.toDelete = id
      this.dialogVisible = !this.dialogVisible
    },
    toggleEditDialog (id) {
      if (id !== 'creating') {
        this.valueToEdit = id
      }
      this.editDialogVisible = !this.editDialogVisible
      if (!id) {
        this.getData()
      }
    },
    async deleteItem () {
      this.deleteLoading = true

      const [error, response] = await handlePromise(DoclistService.deleteDoclist(this.toDelete))
      this.deleteLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      this.toggleDialog()
      this.getData(this.tableParams)
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getData()
    },
    handlePageChange (val) {
      this.pageIndex = val - 1
      this.getData()
    },
    handleFilters (filters) {
      this.filters = {}
      map(filters, (filter, key) => {
        if (filter) this.filters[`filters[${key}]`] = filter
      })

      this.getData()
    },
    handleSort (val) {
      this.sort = { prop: val.prop, order: val.order }
      this.getData()
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
