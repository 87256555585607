<template>
    <transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        >
          <header class="modal-header" id="modalTitle">
              <slot name="header">
                 {{ valueToEdit ? $t('editDocList') :  $t('createDocList') }}
              </slot>
              <button
              type="button"
              class="btn-close"
              @click="close"
              aria-label="Close modal"
              >
              <ods-icon name = "close" size = "18" />
              </button>
          </header>
          <ods-module v-loading="loading">
            <section class="modal-body">
                <slot name="body">
                    <ods-form
                        :model="form"
                        ref="form"
                        v-loading="loading"
                    >
                      <ods-row :gutter="20">
                            <ods-col :md="12" v-if="!form.idType" v-show="!form.customerType">
                                <ods-form-item :label="$t('blockNumber')" prop="blockNumber" v-show="!form.all"  v-if="!form.serviceType">
                                    <ods-input v-model="form.blockNumber" type="number"></ods-input>
                                </ods-form-item>
                            </ods-col>
                            <ods-col :md="12" v-if="!form.blockNumber" v-show="!form.all">
                              <ods-form-item :label="$t('customerType')" prop="customerType" :rules="form.serviceType ? [errors.required] : null" v-if="!form.idType">
                                <ods-select v-model="form.customerType"  placeholder="" clearable remote>
                                  <ods-option
                                      v-for="item in customerTypes"
                                      :key="item.id"
                                      :label="item.descriptionEn"
                                      :value="item.id"
                                  >
                                  </ods-option>
                                </ods-select>
                              </ods-form-item>
                            </ods-col>
                            <ods-col :md="12" v-if="!form.blockNumber" v-show="!form.customerType">
                                <ods-form-item :label="$t('idType')" prop="idType" v-show="!form.all"  v-if="!form.serviceType">
                                    <ods-select v-model="form.idType" placeholder="" clearable remote>
                                      <ods-option
                                          v-for="item in idTypes"
                                          :key="item.id"
                                          :label="item.descriptionEn"
                                          :value="item.id"
                                      >
                                      </ods-option>
                                    </ods-select>
                                </ods-form-item>
                            </ods-col>

                            <ods-col :md="12" v-if="!form.blockNumber" v-show="!form.all">
                              <ods-form-item :label="$t('serviceType')" prop="serviceType" :rules="form.customerType ? [errors.required] : null" v-if="!form.idType">
                                <ods-select v-model="form.serviceType" clearable placeholder="" remote>
                                  <ods-option
                                      v-for="item in serviceTypes"
                                      :key="item.id"
                                      :label="item.descriptionEn"
                                      :value="item.id"
                                  >
                                  </ods-option>
                                </ods-select>
                              </ods-form-item>
                            </ods-col>
                      </ods-row>
                      <ods-row :gutter="20">
                            <ods-col :md="12">
                                <ods-checkbox style="margin-top:23px"  v-model="form.isMandatory">{{$t('isMandatory')}}</ods-checkbox>
                            </ods-col>
                            <ods-col :md="12" v-if="!form.blockNumber" v-show="!form.customerType">
                                <ods-checkbox v-if="!form.idType" v-show="!form.serviceType" style="margin-top:23px" v-model="form.all">{{$t('all')}}</ods-checkbox>
                            </ods-col>
                      </ods-row>
                    </ods-form>
                </slot>
            </section>
          </ods-module>
          <slot name="footer" class="modal-footer">
              <form-buttons
                  @submit="submitForm"
                  @cancel="$emit('close')"
              ></form-buttons>
          </slot>
        </div>
    </div>
  </transition>
</template>

<script>
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'
import FormButtons from '@/custom-components/Buttons/FormButtons'
import DoclistService from '@/services/Doclist'
import RequestService from '@/services/Request'
import AttachmentService from '@/services/Attachment'

export default {
  name: 'docListEditModal',
  components: {
    FormButtons
  },
  props: {
    valueToEdit: String
  },
  mounted: function () {
    this.getServiceTypes()
    this.getIdTypes()
    this.getCustomerTypes()
    if (this.valueToEdit) {
      this.getData()
    }
  },
  data () {
    return {
      loading: false,
      form: {},
      errors,
      submitErrors: {},
      formLoading: false,
      serviceTypes: [],
      idTypes: [],
      customerTypes: []
    }
  },
  methods: {
    async getData () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(DoclistService.getDoc(this.valueToEdit))
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.form = {
        attachmentType: data.attachmentType.id,
        customerType: data.customerType ? data.customerType.id : null,
        serviceType: data.serviceType ? data.serviceType.id : null,
        isMandatory: data.isMandatory,
        blockNumber: data.blockNumber ? data.blockNumber : null,
        idType: data.idType ? data.idType : null,
        all: data.forAll
      }
      this.toggleLoading()
    },
    async submitForm () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.loading = true

          let toSend = {}

          if (this.form.all) {
            toSend = {
              attachmentType: this.$route.params.id,
              isMandatory: this.form.isMandatory ? 'true' : 'false',
              idType: '',
              blockNumber: '',
              forAll: 'true',
              serviceType: '',
              customerType: ''
            }
          }

          if (this.form.idType) {
            toSend = {
              attachmentType: this.$route.params.id,
              isMandatory: this.form.isMandatory ? 'true' : 'false',
              idType: this.form.idType,
              blockNumber: '',
              forAll: 'false',
              serviceType: '',
              customerType: ''
            }
          }

          if (this.form.blockNumber) {
            toSend = {
              attachmentType: this.$route.params.id,
              isMandatory: this.form.isMandatory ? 'true' : 'false',
              idType: '',
              blockNumber: this.form.blockNumber,
              forAll: 'false',
              serviceType: '',
              customerType: ''
            }
          }

          if (this.form.serviceType || this.form.customerType) {
            toSend = {
              attachmentType: this.$route.params.id,
              isMandatory: this.form.isMandatory ? 'true' : 'false',
              idType: '',
              blockNumber: '',
              forAll: 'false',
              serviceType: this.form.serviceType,
              customerType: this.form.customerType
            }
          }

          const [error, response, responseData] = await handlePromise(
            this.valueToEdit
              ? DoclistService.updateDoclist(this.valueToEdit, toSend)
              : DoclistService.saveDoclist(toSend)
          )
          this.loading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.close()
        }
      })
    },
    async getServiceTypes () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(RequestService.getServiceTypes())
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.serviceTypes = data.data
    },
    async getIdTypes () {
      let params = {
        limit: -1
      }
      this.toggleLoading()
      const [error, response, data] = await handlePromise(AttachmentService.getIdTypes(params))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.idTypes = data.data
    },
    async getCustomerTypes () {
      let params = {
        limit: -1
      }
      this.toggleLoading()
      const [error, response, data] = await handlePromise(RequestService.getCustomersTypes(params))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.customerTypes = data.data
    },
    close () {
      this.$emit('close')
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
<style scoped>
  ::-webkit-scrollbar {
     width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(224, 223, 223);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(197, 197, 197);
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1400;
  }

  .modal {
    background: #FFFFFF;
    overflow-x: auto;
    display: flex;
    width: 50%;
    border-radius: 5px;
    flex-direction: column;
    padding: 10px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #000000;
    font-weight: 490;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    height: 25vh;
    overflow-y: auto;
  }

  .modal-footer{
      position: sticky;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active {
    transition: opacity .5s ease;
  }
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
  .unlayer-editor {
    flex: 1;
    display: flex;
  }
</style>
