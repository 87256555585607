<template>
  <section v-loading="loading">
     <ods-row :gutter="20" type="flex">
       <ods-module :header="$t('attachmentTypeMaintenance')" style="width:100%">
         <template slot="header">
            <list-header
                title="attachmentTypeMaintenance"
                btn-label="createAttachmentType"
                url-key="AttachmentTypeNew"
            />
          </template>
          <ods-table
            :data="data"
            size="mini"
            style="width: 100%">
            <ods-table-column :label="$t('code')" width="120">
              <template slot-scope="scope">
                <router-link v-if="scope.row.id" :to="{ name: 'AttachmentTypeEdit', params: { id: scope.row.id }}">
                  <ods-button type="text" class="p-0">{{scope.row.code}}</ods-button>
                </router-link>
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('descriptionEn')">
              <template slot-scope="scope">
                {{scope.row.descriptionEn}}
              </template>
            </ods-table-column>
            <ods-table-column :label="$t('descriptionAr')">
              <template slot-scope="scope">
                {{scope.row.descriptionAr}}
              </template>
            </ods-table-column>
            <ods-table-column align="right" width="80">
              <template slot-scope="scope">
                <router-link v-if="scope.row.id" :to="{ name: 'AttachmentTypeEdit', params: { id: scope.row.id }}">
                  <ods-button
                  size="mini"
                  type="primary"
                  icon="ods-icon-edit"/>
                </router-link>
              </template>
            </ods-table-column>
            <ods-table-column align="right" width="80">
              <template slot-scope="scope">
                <table-delete @click="toggleDialog(scope.row.id)"></table-delete>
              </template>
            </ods-table-column>
          </ods-table>
          <ods-pagination
            :total="total"
            :page-size="pageSize"
            @size-change="handleSizeChange"
            @current-change="handlePageChange"
            layout="prev, pager, next, jumper, sizes, ->, total"
          >
          </ods-pagination>
       </ods-module>
       <dialog-confirmation
          :visible="dialogVisible"
          :loading="deleteLoading"
          @confirm="deleteItem"
          @cancel="toggleDialog()"
        />
     </ods-row>
  </section>
</template>

<script>
import handlePromise from '@/utils/promise'
import _ from 'lodash'
import map from 'lodash/map'
import ListHeader from '@/custom-components/ListHeader'
import AttachmentService from '@/services/Attachment'
import TableDelete from '@/custom-components/Buttons/TableDelete'
import DialogConfirmation from '@/custom-components/DialogConfirmation'

export default {
  name: 'AttachmentType',
  components: {
    ListHeader,
    TableDelete,
    DialogConfirmation
  },
  data () {
    return {
      loading: false,
      data: [],
      pageSize: 10,
      pageIndex: 0,
      pageStartZero: true,
      total: 0,
      sort: null,
      toDelete: null,
      deleteLoading: false,
      dialogVisible: false
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      let auxParams = {}
      let params = {
        offset: this.pageIndex === 0 && this.pageStartZero ? 0 : (this.pageIndex * this.pageSize),
        limit: this.pageSize,
        ...auxParams
      }

      if (this.sort && this.sort.prop) params[`sortby[${this.sort.prop}]`] = this.sort.order === 'ascending' ? 'ASC' : 'DESC'
      this.toggleLoading()
      const [error, response, data] = await handlePromise(AttachmentService.getAttachemntTypes(params))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.data = data.data
      this.total = data.total
    },
    toggleDialog (id) {
      this.toDelete = id
      this.dialogVisible = !this.dialogVisible
    },
    async deleteItem () {
      this.deleteLoading = true

      const [error, response] = await handlePromise(AttachmentService.deleteAttachemntType(this.toDelete))
      this.deleteLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      this.toggleDialog()
      this.getData(this.tableParams)
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getData()
    },
    handlePageChange (val) {
      this.pageIndex = val - 1
      this.getData()
    },
    handleFilters (filters) {
      this.filters = {}
      map(filters, (filter, key) => {
        if (filter) this.filters[`filters[${key}]`] = filter
      })

      this.getData()
    },
    handleSort (val) {
      this.sort = { prop: val.prop, order: val.order }
      this.getData()
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
