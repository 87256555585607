<template>
  <div>
    <ods-main-header
      headerLogoText="Onesait e-Services"
      logoUrl="/"
      breadcrumbSeparator=">"
      actionsMenuIcon="overflow-menu"
      :showBreadcrumbs="true"
      :topBarBorder="false"
      :showUserMenu="true"
      :userMenuText="
        user.id
          ? user.userProfile && user.userProfile.name
            ? user.userProfile.name
            : user.email
          : 'User'
      "
      ref="header"
      :environment="enviroment"
    >
      <template slot="custom">
        <ods-badge
          :value="unreadNotificationsBadge"
          :type="'warning'"
          :max="10"
          v-if="showNotification"
        >
          <ods-actions-menu :width="'300'" icon="notification">
            <p v-if="!unreadNotificationsBadge" style="text-align: center">
              {{ $t("noNotifications") }}
            </p>
            <ods-button
              size="small"
              v-if="unreadNotificationsBadge"
              style="display: block; margin-left: auto; margin-right: auto"
              @click="deleteNotifications()"
              type="secondary"
              icon="ods-icon-delete"
              class="py-0"
              >{{ $t("clearNotifications") }}</ods-button
            >
            <hr />
            <ul
              v-for="notification in unreadNotifications"
              :key="notification.id"
            >
              <li class="notificationList">
                <ods-button
                  @click="handleCheckNotification(notification)"
                  size="medium"
                  type="text"
                  >{{ notification.request.referenceNumber }}-{{
                    notification.content
                  }}</ods-button
                >
              </li>
            </ul>
          </ods-actions-menu>
        </ods-badge>
      </template>
      <template slot="notifications">
        <ods-select v-model="$i18n.locale">
          <ods-option
            v-for="lang in langs"
            :key="lang"
            :value="lang"
            :label="getLabel(lang)"
          ></ods-option>
        </ods-select>
      </template>

      <template slot="user">
        <ul class="ods-actions-menu__ul ods-actions-menu--user__ul">
          <li>
            <router-link :to="{ name: 'Profile' }">
              <span data-name="OdsIcon" class="ods-icon-user"></span>Profile
            </router-link>
          </li>
          <li class="cursor-pointer">
            <a @click="logout()"
              ><span data-name="OdsIcon" class="ods-icon-logout"></span>Log
              out</a
            >
          </li>
        </ul>
      </template>
    </ods-main-header>
    <div class="app-wrap">
      <ods-main-navigation
        v-if="isAdmin"
        :collapsed="false"
        :navigation="navigation"
        ref="navigation"
      ></ods-main-navigation>
      <ods-main-navigation
        v-if="isCallCenter && !isOperator && !isAdmin"
        :collapsed="false"
        :navigation="navigationCallCenter"
        ref="navigationCallCenter"
      ></ods-main-navigation>
      <ods-main-navigation
        v-if="isOperator && !isCallCenter && !isAdmin"
        :collapsed="false"
        :navigation="navigationOperator"
        ref="navigationOperator"
      ></ods-main-navigation>
      <ods-main-navigation
        v-if="isOperator && isCallCenter && !isAdmin"
        :collapsed="false"
        :navigation="navigationOperatorCallCenter"
        ref="navigationOperatorCallCenter"
      ></ods-main-navigation>
      <ods-main>
        <ods-scrollbar
          wrapClass="ods-scrollbar--main-content"
          v-if="user.allowAnimation"
        >
          <transition name="fade" mode="out-in">
            <router-view />
          </transition>
        </ods-scrollbar>
        <ods-scrollbar
          wrapClass="ods-scrollbar--main-content"
          v-if="!user.allowAnimation"
        >
          <router-view />
        </ods-scrollbar>
        <ods-backtop
          :target="'.ods-scrollbar--main-content'"
          :visibilityHeight="200"
          :right="40"
          :bottom="40"
        >
          <ods-button
            size="small"
            icon="ods-icon-chevron-up"
            circle
            class="py-0"
          />
        </ods-backtop>
      </ods-main>
    </div>
  </div>
</template>

<script>
import navigationConfig from './nav-config.json'
import navigationCallCenterConfig from './nav-config-call-center.json'
import navigationOperatorConfig from './nav-config-operator.json'
import navigationOperatorCallCEnterConfig from './nav-config-call-center-operator.json'
import handlePromise from '@/utils/promise'
import NotificationService from '@/services/Notification'
import UserService from '@/services/User'
import _ from 'lodash'
import { mapState } from 'vuex'
import storage from '../utils/storage'

export default {
  name: 'Private',
  computed: {
    langs () {
      return Object.keys(this.$i18n.messages)
    },
    ...mapState({
      userData: state => state.user.data
    })
  },
  async mounted () {
    await this.getUser()
    if (this.isAdmin || this.isOperator) {
      window.setInterval(() => {
        this.getUnreadNotifications()
      }, 100000)
    }
  },
  data () {
    return {
      navigation: navigationConfig,
      navigationCallCenter: navigationCallCenterConfig,
      navigationOperator: navigationOperatorConfig,
      navigationOperatorCallCenter: navigationOperatorCallCEnterConfig,
      wrapClass: '',
      user: {},
      isAdmin: false,
      isOperator: false,
      isCallCenter: false,
      showNotification: false,
      dialogVisible: false,
      unreadNotifications: [],
      unreadNotificationsBadge: null,
      loading: false,
      enviroment: `${process.env.VUE_APP_ENVIRONMENT}-v${process.env.VUE_APP_VERSION}`
    }
  },

  methods: {
    getLabel (lang) {
      return this.mobile ? this.$i18n.messages[lang].labelMobile : this.$i18n.messages[lang].label
    },
    logout () {
      this.$store.commit('user/logout')
      this.$router.push({ path: '/auth/login' })
    },
    async getUser () {
      const [, response, data] = await handlePromise(UserService.getUser(this.userData.user.id))
      if (response.ok) {
        this.user = data
        if (_.find(this.user.roles, function (r) { return r === 'ROLE_ADMIN' })) {
          this.isAdmin = true
        } else if (_.find(this.user.roles, function (r) { return r === 'ROLE_CALL_CENTER' }) &&
          !_.find(this.user.roles, function (r) { return r === 'ROLE_OPERATOR' })
        ) {
          this.isCallCenter = true
        } else if (_.find(this.user.roles, function (r) { return r === 'ROLE_OPERATOR' }) &&
          !_.find(this.user.roles, function (r) { return r === 'ROLE_CALL_CENTER' })
        ) {
          this.isOperator = true
        } else if (_.find(this.user.roles, function (r) { return r === 'ROLE_OPERATOR' }) &&
          _.find(this.user.roles, function (r) { return r === 'ROLE_CALL_CENTER' })
        ) {
          this.isOperator = true
          this.isCallCenter = true
        } else {
          this.isAdmin = true
        }
        if (this.isAdmin || this.isOperator) {
          this.showNotification = true
          this.getUnreadNotifications()
        }
      }
    },
    async getUnreadNotifications () {
      const loggedIn = !!_.get(storage.get(), 'user.data.access_token')
      if (!loggedIn) {
        this.toggleLoading()
        const [error, response, data] = await handlePromise(NotificationService.getUnreadNotifications())
        this.toggleLoading()
        if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
        this.unreadNotificationsBadge = data.total
        if (data.total === 0) this.unreadNotificationsBadge = null
        this.unreadNotifications = data.data
      }
    },
    async handleCheckNotification (n) {
      const data = {
        unread: 'false'
      }
      this.toggleLoading()
      const [error, response] = await handlePromise(NotificationService.updateUnreadNotification(n.id, data))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      await this.getUnreadNotifications()
      this.$router.push({ path: '/request-dashboard' })
    },
    async deleteAllNotifications () {
      this.unreadNotificationsBadge = null
      let notifications = this.unreadNotifications
      return Promise.all(notifications.map(async item => {
        let data = {
          unread: 'false'
        }
        this.toggleLoading()
        const [error, response] = await handlePromise(NotificationService.updateUnreadNotification(item.id, data))
        this.toggleLoading()
        if (!response.ok) this.$store.commit('settings/toggleAlert', this.$t(error))
      }))
    },
    async deleteNotifications () {
      await this.deleteAllNotifications()
      this.getUnreadNotifications()
    },
    toggleLoading () {
      this.loading = !this.loading
    },
    toggleDialog () {
      this.dialogVisible = true
    },
    closeModal () {
      this.dialogVisible = false
    }
  },
  watch: {
    '$i18n.locale': {
      handler (val) {
        const assignName = item => {
          this.$set(item, 'name', item.langs[this.$i18n.locale])
          if (item.children && Object.keys(item.children).length) {
            for (const key in item.children) {
              assignName(item.children[key])
            }
          }
        }
        for (const key in this.navigation) {
          assignName(this.navigation[key])
        }
        for (const key in this.navigationCallCenter) {
          assignName(this.navigationCallCenter[key])
        }
        for (const key in this.navigationOperator) {
          assignName(this.navigationOperator[key])
        }
        for (const key in this.navigationOperatorCallCenter) {
          assignName(this.navigationOperatorCallCenter[key])
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.app-wrap {
  display: flex;
}

/deep/ .ods-main-header__logo .logo_text h2 {
  font-size: 16px;
  font-weight: 400;
}

.notificationList {
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  margin-bottom: 10px;
}

.notificationList:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
</style>
