<template>
  <section v-loading="loading">
    <ods-module :header="$t('filterCriteria')">
      <ods-form :model="search" ref="form">
        <ods-row :gutter="24">
          <ods-col :md="12" :sm="12">
            <ods-form-item :label="$t('accountNumber')" prop="accountNumber">
              <ods-input v-model="search.accountNumber"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" :sm="12">
            <ods-form-item :label="$t('idNumber')" prop="idNumber">
              <ods-input v-model="search.idNumber"></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>
        <ods-row :gutter="24">
          <ods-col :md="12" :sm="12">
            <ods-form-item :label="$t('channelId')" prop="chanelId">
              <ods-input v-model="search.chanelId"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" :sm="12">
            <ods-form-item :label="$t('transactionId')" prop="idNumber">
              <ods-input v-model="search.transactionId"></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>
        <ods-row :gutter="24">
          <ods-col :md="12" :sm="12">
            <ods-form-item
              :label="$t('paymentDateFrom')"
              prop="paymentDateFrom"
              :rules="[errors.required]"
            >
              <ods-date-picker
                type="date"
                v-model="search.paymentDateFrom"
                style="width: 100%"
              />
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" :sm="12">
            <ods-form-item :label="$t('paymentDateTo')" prop="paymentDateTo"
              :rules="[errors.required]"
            >
              <ods-date-picker
                type="date"
                v-model="search.paymentDateTo"
                :picker-options="{ disabledDate: disabledDateTo }"
                style="width: 100%"
              />
            </ods-form-item>
          </ods-col>
        </ods-row>
        <ods-row type="flex" justify="center">
          <ods-button type="primary" @click="getData">
            {{ $t("search") }}
          </ods-button>
          <ods-button type="primary" @click="clearFilters">
            {{ $t("reset") }}
          </ods-button>
        </ods-row>
      </ods-form>
    </ods-module>
    <ods-module class="mt-1" :header="$t('payments')">
      <template slot="header">
        <list-header title="payments">
          <template slot="right">
            <ods-button type="secondary" @click="generateExcel()" size="small">
              {{ $t("exportExcel") }}
            </ods-button>
          </template>
        </list-header>
      </template>
      <ods-row class="mb-1" :gutter="24">
        <ods-col :md="6" :sm="12">
          <span class="font-weight-bold">{{ $t("records") }}:</span>
          {{ fullData.records }}
        </ods-col>
        <ods-col :md="6" :sm="12">
          <span class="font-weight-bold">{{ $t("totalAmount") }}:</span>
          {{ fullData.totalAmount | formatNumber }}
        </ods-col>
      </ods-row>
      <ods-table
        :data="data"
        v-loading="loading"
        @sort-change="handleSort"
        size="mini"
        style="width: 100%"
      >
        <ods-table-column prop="idType" :label="$t('idType')" width="80">
          <template slot-scope="scope">
            {{ scope.row.idType }}
          </template>
        </ods-table-column>
        <ods-table-column
          prop="accountNumber"
          :label="$t('accountNumber')"
          width="180"
        />
        <ods-table-column
          prop="paidAmount"
          :label="$t('paidAmount')"
          width="140"
        >
          <template slot-scope="scope">
            {{ scope.row.paidAmount | formatNumber }}
          </template>
        </ods-table-column>
        
        <ods-table-column
          prop="idChannel"
          :label="$t('channelId')"
          width="140"
        />
        <ods-table-column
          prop="paymentDate"
          :label="$t('paymentDate')"
          width="180"
        >
          <template slot-scope="scope">
            {{ scope.row.paymentDate | date2 }}
          </template>
        </ods-table-column>
        <ods-table-column
          prop="transactionId"
          :label="$t('transactionId')"
          width="200"
        />
        <ods-table-column
          prop="authorizationCode"
          :label="$t('authorizationCode')"
          width="140"
        />
        <ods-table-column prop="billType" :label="$t('billType')" width="140" />
        <ods-table-column prop="machine" :label="$t('machine')" width="140" />
        <ods-table-column
          prop="processed"
          :label="$t('processed')"
        >
          <template slot-scope="scope">
            {{ scope.row.indProcessed ? 'yes' : 'no' }}
          </template>
        </ods-table-column>
       
      </ods-table>
      <ods-pagination
        :total="total"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        layout="prev, pager, next, jumper, sizes, ->, total"
      >
      </ods-pagination>
    </ods-module>
  </section>
</template>

<script>
import handlePromise from "@/utils/promise";
import moment from "moment";
import PaymentService from "@/services/Payment";
import ExampleService from "@/services/Example";
import ListHeader from "@/custom-components/ListHeader";
import map from "lodash/map";
import errors from "@/config/InputErrors";

export default {
  name: "Payments",
  props: {
    params: Object,
  },
  components: {
    ListHeader,
  },
  data() {
    return {
      loading: false,
      search: {
        paymentDateFrom : moment(),
        paymentDateTo : moment()
      },
      data: [],
      pageSize: 10,
      pageIndex: 0,
      pageStartZero: true,
      total: 0,
      sort: null,
      fullData: [],
      errors
    };
  },
  mounted() {
    var self = this;
    addEventListener("keypress", function(e) {
      if (e.key === "Enter") {
        self.getData()
      }
    });
    this.getData();
  },
  methods: {
    disabledDateTo(date) {
      return moment(this.search.paymentDateFrom).isAfter(date, "day");
    },
    clearFilters() {
      this.search = {};
    },
    async getData() {

      let auxParams = {};
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.search.accountNumber) {
            auxParams = {
              "filters[accountNumber]": this.search.accountNumber,
              ...auxParams,
            };
          }
          if (this.search.idNumber) {
            auxParams = {
              "filters[idNumber]": this.search.idNumber,
              ...auxParams,
            };
          }
          if (this.search.accountNumber) {
            auxParams = {
              "filters[accountNumber]": this.search.accountNumber,
              ...auxParams,
            };
          }
          if (this.search.chanelId) {
            auxParams = {
              "filters[chanelId]": this.search.chanelId,
              ...auxParams,
            };
          }
          if (this.search.transactionId) {
            auxParams = {
              "filters[transactionId]": this.search.transactionId,
              ...auxParams,
            };
          }
          if (this.search.paymentDateFrom) {
            auxParams = {
              "filters[paymentDateFrom]": moment(new Date(this.search.paymentDateFrom).toUTCString()).format("YYYY-MM-DD"),
              ...auxParams,
            };
          }
          if (this.search.paymentDateTo) {
            auxParams = {
              "filters[paymentDateTo]": moment(new Date(this.search.paymentDateTo).toUTCString()).format("YYYY-MM-DD"),
              ...auxParams,
            };
          }

          let params = {
            offset:
              this.pageIndex === 0 && this.pageStartZero
                ? 0
                : this.pageIndex * this.pageSize,
            limit: this.pageSize,
            ...auxParams,
          };

          if (this.sort && this.sort.prop)
            params[`sortby[${this.sort.prop}]`] =
              this.sort.order === "ascending" ? "ASC" : "DESC";
          this.toggleLoading();
          const [error, response, data] = await handlePromise(
            PaymentService.getPayments(params)
          );
          this.toggleLoading();
          if (!response.ok)
            return this.$store.commit("settings/toggleAlert", this.$t(error));
          this.data = data.data.payments;
          this.fullData = data.data;
          this.total = data.total;
        }
      });
    },
    handleSort(val) {
      this.sort = { prop: val.prop, order: val.order };
      this.getData();
    },
    async generateExcel() {
      this.toggleLoading();
      let forSend = {
        records: this.fullData.records,
        totalAmount: this.fullData.totalAmount,
        payments: this.data,
      };
      const [error, response, data] = await handlePromise(
        PaymentService.getExcel(forSend)
      );
      this.toggleLoading();
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handlePageChange(val) {
      this.pageIndex = val - 1;
      this.getData();
    },
    handleFilters(filters) {
      this.filters = {};
      map(filters, (filter, key) => {
        if (filter) this.filters[`filters[${key}]`] = filter;
      });
      this.getData();
    },
    toggleLoading() {
      this.loading = !this.loading;
    },
  },
};
</script>
