<template>
  <section v-loading="loading">
     <ods-module :header="$t('filterCriteria')">
      <ods-form :model="search" ref="form">
        <ods-row :gutter="24">
          <ods-col :md="12" :sm="12">
            <ods-form-item :label="$t('blockNumber')" prop="blockNumber">
                <ods-input v-model="search.blockNumber" type="number"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" :sm="12">
            <ods-form-item :label="$t('idType')" prop="idType">
                <ods-select v-model="search.idType" placeholder="" clearable remote>
                  <ods-option
                      v-for="item in idTypes"
                      :key="item.id"
                      :label="item.descriptionEn"
                      :value="item.id"
                  >
                  </ods-option>
                </ods-select>
            </ods-form-item>
          </ods-col>
        </ods-row>
        <ods-row :gutter="24">
          <ods-col :md="12" :sm="12">
            <ods-form-item :label="$t('customerType')" prop="customerType" :rules="search.serviceType ? [errors.required] : null">
              <ods-select v-model="search.customerType"  placeholder="" clearable remote>
                <ods-option
                    v-for="item in customerTypes"
                    :key="item.id"
                    :label="item.descriptionEn"
                    :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" :sm="12">
            <ods-form-item :label="$t('serviceType')" prop="serviceType" :rules="search.customerType ? [errors.required] : null">
              <ods-select v-model="search.serviceType" clearable placeholder="" remote>
                <ods-option
                    v-for="item in serviceTypes"
                    :key="item.id"
                    :label="item.descriptionEn"
                    :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
        </ods-row>
        <ods-row
            type="flex"
            justify="center">
          <ods-button type="primary" @click="getData">
            {{$t('search')}}
          </ods-button>
          <ods-button type="primary" @click="clearFilters">
            {{$t('reset')}}
          </ods-button>
        </ods-row>
      </ods-form>
    </ods-module>
    <ods-module class="mt-1" :header="$t('attachmentsList')">
       <ods-table
        :data="data"
        size="mini"
        style="width: 100%">
         <ods-table-column :label="$t('attachmentType')">
          <template slot-scope="scope">
            {{scope.row.attachmentType ? scope.row.attachmentType.descriptionEn : '---'}}
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('isMandatory')" align="right">
          <template slot-scope="scope">
            <ods-checkbox style="margin-right:25px"
              v-model="scope.row.isMandatory"
              disabled
                >
            </ods-checkbox>
          </template>
        </ods-table-column>
      </ods-table>
      <ods-pagination
        :total="total"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        layout="prev, pager, next, jumper, sizes, ->, total"
      >
      </ods-pagination>
    </ods-module>
  </section>
</template>

<script>
import handlePromise from '@/utils/promise'
import _ from 'lodash'
import map from 'lodash/map'
import errors from '@/config/InputErrors'
import DoclistService from '@/services/Doclist'
import RequestService from '@/services/Request'
import AttachmentService from '@/services/Attachment'

export default {
  name: 'Doclist',
  data () {
    return {
      search: {},
      errors,
      loading: false,
      data: [],
      pageSize: 10,
      pageIndex: 0,
      pageStartZero: true,
      total: 0,
      sort: null,
      serviceTypes: [],
      idTypes: [],
      customerTypes: [],
      attachmentTypes: []
    }
  },
  mounted () {
    this.getData()
    this.getServiceTypes()
    this.getIdTypes()
    this.getCustomerTypes()
    this.getAttachmentTypes()
  },
  methods: {
    clearFilters () {
      this.search = {}
    },
    async getData () {
      let auxParams = {}
      if (this.search.blockNumber) {
        auxParams = {
          'filters[blockNumber]': this.search.blockNumber,
          ...auxParams
        }
      }
      if (this.search.customerType) {
        auxParams = {
          'filters[customerType]': this.search.customerType,
          ...auxParams
        }
      }
      if (this.search.idType) {
        auxParams = {
          'filters[idType]': this.search.idType,
          ...auxParams
        }
      }
      if (this.search.serviceType) {
        auxParams = {
          'filters[serviceType]': this.search.serviceType,
          ...auxParams
        }
      }
      let params = {
        offset: this.pageIndex === 0 && this.pageStartZero ? 0 : (this.pageIndex * this.pageSize),
        limit: this.pageSize,
        ...auxParams
      }
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          if (this.sort && this.sort.prop) params[`sortby[${this.sort.prop}]`] = this.sort.order === 'ascending' ? 'ASC' : 'DESC'
          this.toggleLoading()
          const [error, response, data] = await handlePromise(DoclistService.getDoclist(params))
          this.toggleLoading()
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.data = data.data
          // this.data = _.uniqBy(data.data, function (d) {
          //   return d.attachmentType.id
          // })
          this.total = data.total
        }
      })
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getData()
    },
    handlePageChange (val) {
      this.pageIndex = val - 1
      this.getData()
    },
    handleFilters (filters) {
      this.filters = {}
      map(filters, (filter, key) => {
        if (filter) this.filters[`filters[${key}]`] = filter
      })
      this.getData()
    },
    handleSort (val) {
      this.sort = { prop: val.prop, order: val.order }
      this.getData()
    },
    async getServiceTypes () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(RequestService.getServiceTypes())
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.serviceTypes = data.data
    },
    async getIdTypes () {
      let params = {
        limit: -1
      }
      this.toggleLoading()
      const [error, response, data] = await handlePromise(AttachmentService.getIdTypes(params))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.idTypes = data.data
    },
    async getCustomerTypes () {
      let params = {
        limit: -1
      }
      this.toggleLoading()
      const [error, response, data] = await handlePromise(RequestService.getCustomersTypes(params))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.customerTypes = data.data
    },
    async getAttachmentTypes () {
      let params = {
        limit: -1
      }
      this.toggleLoading()
      const [error, response, data] = await handlePromise(AttachmentService.getAttachemntTypes(params))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.attachmentTypes = data.data
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
