<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div
        class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header class="modal-header" id="modalTitle">
          <slot name="header">
            {{
              valueToEdit === null
                ? $t("createNotification")
                : $t("editNotification")
            }}
            {{ valueToEdit !== null ? "-" + valueToEdit.name : "" }}
          </slot>
          <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
          >
            <ods-icon name="close" size="18" />
          </button>
        </header>
        <ods-module v-loading="formLoading">
          <section class="modal-body">
            <slot name="body">
              <ods-form :model="form" ref="form" v-loading="formLoading">
                <ods-row :gutter="20">
                  <ods-col :md="12">
                    <ods-form-item
                      :label="$t('name')"
                      prop="name"
                      :rules="[errors.required]"
                    >
                      <ods-input v-model="form.name" />
                    </ods-form-item>
                  </ods-col>
                  <ods-col :md="12">
                    <ods-checkbox
                      style="margin-top:20px"
                      class="mb-1"
                      v-model="form.isSmsRequired"
                    >
                      {{ $t("sendSMS") }}
                    </ods-checkbox>
                    <ods-checkbox
                      style="margin-top:20px"
                      class="mb-1"
                      v-model="form.isEmailRequired"
                    >
                      {{ $t("sendEmail") }}
                    </ods-checkbox>
                  </ods-col>
                </ods-row>
                <ods-row :gutter="20">
                  <ods-tabs v-model="activeName" type="border-card">
                    <ods-tab-pane label="SMS" name="first">
                      <ods-accordion v-model="activeNamesSMS">
                        <ods-accordion-item :title="$t('enSMS')" name="1">
                          <ods-tag style="margin-right: 10px" type="warning"
                            >{{ $t("tags") }}:</ods-tag
                          >
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addName('en')"
                            :disabled="!form.smsTemplateEn"
                          >
                            {{ $t("name") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addRequestNumber('en')"
                            :disabled="!form.smsTemplateEn"
                          >
                            {{ $t("requestNumber") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addIDType('en')"
                            :disabled="!form.smsTemplateEn"
                          >
                            {{ $t("idType") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addIDNumber('en')"
                            :disabled="!form.smsTemplateEn"
                          >
                            {{ $t("idNumber") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addRequestStatus('en')"
                            :disabled="!form.smsTemplateEn"
                          >
                            {{ $t("requestStatus") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addServiceType('en')"
                            :disabled="!form.smsTemplateEn"
                          >
                            {{ $t("serviceType") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addSupplyAddress('en')"
                            :disabled="!form.smsTemplateEn"
                          >
                            {{ $t("supplyAddress") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addComments('en')"
                            :disabled="!form.smsTemplateEn"
                          >
                            {{ $t("comments") }}
                          </ods-button>
                          <ods-form-item prop="enSMS">
                            <ods-input
                              v-model="form.smsTemplateEn"
                              :autosize="{ minRows: 4 }"
                              type="textarea"
                            ></ods-input>
                          </ods-form-item>
                        </ods-accordion-item>
                        <ods-accordion-item :title="$t('arSMS')" name="2">
                          <ods-tag style="margin-right: 10px" type="warning"
                            >{{ $t("tags") }}:</ods-tag
                          >
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addName('ar')"
                            :disabled="!form.smsTemplateAr"
                          >
                            {{ $t("name") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addRequestNumber('ar')"
                            :disabled="!form.smsTemplateAr"
                          >
                            {{ $t("requestNumber") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addIDType('ar')"
                            :disabled="!form.smsTemplateAr"
                          >
                            {{ $t("idType") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addIDNumber('ar')"
                            :disabled="!form.smsTemplateAr"
                          >
                            {{ $t("idNumber") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addRequestStatus('ar')"
                            :disabled="!form.smsTemplateAr"
                          >
                            {{ $t("requestStatus") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addServiceType('ar')"
                            :disabled="!form.smsTemplateAr"
                          >
                            {{ $t("serviceType") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addSupplyAddress('ar')"
                            :disabled="!form.smsTemplateAr"
                          >
                            {{ $t("supplyAddress") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addComments('ar')"
                            :disabled="!form.smsTemplateAr"
                          >
                            {{ $t("comments") }}
                          </ods-button>
                          <ods-form-item prop="enSMS">
                            <ods-input
                              v-model="form.smsTemplateAr"
                              :autosize="{ minRows: 4 }"
                              type="textarea"
                            ></ods-input>
                          </ods-form-item>
                        </ods-accordion-item>
                      </ods-accordion>
                    </ods-tab-pane>
                    <ods-tab-pane label="Email" name="second">
                      <ods-accordion v-model="activeNamesEmail">
                        <ods-accordion-item :title="$t('enEmail')" name="1">
                          <ods-tag style="margin-right: 10px" type="warning"
                            >{{ $t("tags") }}:</ods-tag
                          >
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addNameEmail('en')"
                            :disabled="!form.emailTemplateEn"
                          >
                            {{ $t("name") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addRequestNumberEmail('en')"
                            :disabled="!form.emailTemplateEn"
                          >
                            {{ $t("requestNumber") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addIDTypeEmail('en')"
                            :disabled="!form.emailTemplateEn"
                          >
                            {{ $t("idType") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addIDNumberEmail('en')"
                            :disabled="!form.emailTemplateEn"
                          >
                            {{ $t("idNumber") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addRequestStatusEmail('en')"
                            :disabled="!form.emailTemplateEn"
                          >
                            {{ $t("requestStatus") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addServiceTypeEmail('en')"
                            :disabled="!form.emailTemplateEn"
                          >
                            {{ $t("serviceType") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addSupplyAddressEmail('en')"
                            :disabled="!form.emailTemplateEn"
                          >
                            {{ $t("supplyAddress") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addCommentsEmail('en')"
                            :disabled="!form.emailTemplateEn"
                          >
                            {{ $t("comments") }}
                          </ods-button>
                          <el-tiptap
                            class="quill"
                            :extensions="extensions"
                            v-model="form.emailTemplateEn"
                            placeholder="Write something ..."
                          />
                        </ods-accordion-item>
                        <ods-accordion-item :title="$t('arEmail')" name="2">
                          <ods-tag style="margin-right: 10px" type="warning"
                            >{{ $t("tags") }}:</ods-tag
                          >
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addNameEmail('ar')"
                            :disabled="!form.emailTemplateAr"
                          >
                            {{ $t("name") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addRequestNumberEmail('ar')"
                            :disabled="!form.emailTemplateAr"
                          >
                            {{ $t("requestNumber") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addIDTypeEmail('ar')"
                            :disabled="!form.emailTemplateAr"
                          >
                            {{ $t("idType") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addIDNumberEmail('ar')"
                            :disabled="!form.emailTemplateAr"
                          >
                            {{ $t("idNumber") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addRequestStatusEmail('ar')"
                            :disabled="!form.emailTemplateAr"
                          >
                            {{ $t("requestStatus") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addServiceTypeEmail('ar')"
                            :disabled="!form.emailTemplateAr"
                          >
                            {{ $t("serviceType") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addSupplyAddressEmail('ar')"
                            :disabled="!form.emailTemplateAr"
                          >
                            {{ $t("supplyAddress") }}
                          </ods-button>
                          <ods-button
                            size="mini"
                            type="success"
                            @click="addCommentsEmail('ar')"
                            :disabled="!form.emailTemplateAr"
                          >
                            {{ $t("comments") }}
                          </ods-button>
                          <el-tiptap
                            class="quill"
                            :extensions="extensions"
                            v-model="form.emailTemplateAr"
                            placeholder="Write something ..."
                          />
                        </ods-accordion-item>
                      </ods-accordion>
                    </ods-tab-pane>
                  </ods-tabs>
                </ods-row>
              </ods-form>
            </slot>
          </section>
        </ods-module>
        <slot name="footer" class="modal-footer">
          <form-buttons
            @submit="submitForm"
            @cancel="$emit('close')"
          ></form-buttons>
        </slot>
      </div>
    </div>
  </transition>
</template>

<script>
import errors from "@/config/InputErrors";
import FormButtons from "@/custom-components/Buttons/FormButtons";
import handlePromise from "@/utils/promise";

import { mapState } from "vuex";
import NotificationService from "@/services/Notification";

import {
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  Image,
  Blockquote,
  ListItem,
  BulletList, // use with ListItem
  OrderedList, // use with ListItem
  TodoItem,
  TodoList, // use with TodoItem
  TextAlign,
  Indent,
  HorizontalRule,
  HardBreak,
  History,
  // LineHeight,
  // Iframe,
  // CodeBlock,
  // TrailingNode,
  Table, // use with TableHeader, TableCell, TableRow
  TableHeader,
  TableCell,
  TableRow,
  FormatClear,
  TextColor,
  // TextHighlight,
  Preview,
  // Print,
  CodeView,
  // SelectAll,
} from "element-tiptap";

import codemirror from "codemirror";
import "codemirror/lib/codemirror.css"; // import base style
import "codemirror/mode/xml/xml.js"; // language
import "codemirror/addon/selection/active-line.js"; // require active-line.js
import "codemirror/addon/edit/closetag.js"; // autoCloseTags

import {
  // layout
  Container,
  Header,
  Main,
  Footer,
  Button,
} from "element-ui";
import Vue from "vue";
import { ElementTiptapPlugin } from "element-tiptap";
// import ElementUI's styles
import "element-ui/lib/theme-chalk/index.css";
// import this package's styles
import "element-tiptap/lib/index.css";
// use this package's plugin
Vue.use(ElementTiptapPlugin, { lang: "en" });
Vue.use(Container);
Vue.use(Header);
Vue.use(Main);
Vue.use(Footer);
Vue.use(Button);

export default {
  name: "EditNotificationModal",
  props: {
    valueToEdit: {
      type: Object,
      default: null,
    },
  },
  components: {
    FormButtons,
  },
  computed: {
    ...mapState({
      userData: (state) => state.user.data,
    }),
  },
  mounted: function() {
    if (this.valueToEdit != null) {
      this.getValue();
    }
  },
  data() {
    return {
      formLoading: false,
      form: {},
      activeName: "first",
      activeNamesSMS: ["1", "2"],
      activeNamesEmail: ["1", "2"],
      errors,
      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 5 }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true }),
        new Italic({ bubble: true }),
        new Strike({ bubble: true }),
        new Link({ bubble: true }),
        new Image(),
        new Blockquote(),
        new TextAlign(),
        new ListItem(),
        new BulletList({ bubble: true }),
        new OrderedList({ bubble: true }),
        new TodoItem(),
        new TodoList(),
        new Indent(),
        new HardBreak(),
        new Table(),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new HorizontalRule({ bubble: true }),
        new CodeView({
          codemirror,
          codemirrorOptions: {
            styleActiveLine: true,
            autoCloseTags: true,
          },
        }),
        new History(),
        new FormatClear(),
        new TextColor(),
        new Preview(),
      ],
    };
  },
  methods: {
    async getValue() {
      this.toggleLoading();
      const [error, response, data] = await handlePromise(
        NotificationService.getNotificationDetail(this.valueToEdit.id)
      );
      this.toggleLoading();
      if (!response.ok)
        return this.$store.commit("settings/toggleAlert", this.$t(error));
      this.form = {
        name: data.name,
        isEmailRequired: data.isEmailRequired,
        isSmsRequired: data.isSmsRequired,
        smsTemplateEn: data.smsTemplateEn,
        smsTemplateAr: data.smsTemplateAr,
        emailTemplateEn: data.emailTemplateEn,
        emailTemplateAr: data.emailTemplateAr,
      };
    },
    addName(language) {
      if (language === "en") {
        this.form.smsTemplateEn = this.form.smsTemplateEn + "{{name}}";
      } else {
        this.form.smsTemplateAr = this.form.smsTemplateAr + "{{name}}";
      }
    },
    addRequestNumber(language) {
      if (language === "en") {
        this.form.smsTemplateEn =
          this.form.smsTemplateEn + "{{request_number}}";
      } else {
        this.form.smsTemplateAr =
          this.form.smsTemplateAr + "{{request_number}}";
      }
    },
    addIDType(language) {
      if (language === "en") {
        this.form.smsTemplateEn = this.form.smsTemplateEn + "{{id_type}}";
      } else {
        this.form.smsTemplateAr = this.form.smsTemplateAr + "{{id_type}}";
      }
    },
    addIDNumber(language) {
      if (language === "en") {
        this.form.smsTemplateEn = this.form.smsTemplateEn + "{{id_number}}";
      } else {
        this.form.smsTemplateAr = this.form.smsTemplateAr + "{{id_number}}";
      }
    },
    addRequestStatus(language) {
      if (language === "en") {
        this.form.smsTemplateEn =
          this.form.smsTemplateEn + "{{request_status}}";
      } else {
        this.form.smsTemplateAr =
          this.form.smsTemplateAr + "{{request_status}}";
      }
    },
    addServiceType(language) {
      if (language === "en") {
        this.form.smsTemplateEn = this.form.smsTemplateEn + "{{service_type}}";
      } else {
        this.form.smsTemplateAr = this.form.smsTemplateAr + "{{service_type}}";
      }
    },
    addSupplyAddress(language) {
      if (language === "en") {
        this.form.smsTemplateEn =
          this.form.smsTemplateEn + "{{supply_address}}";
      } else {
        this.form.smsTemplateAr =
          this.form.smsTemplateAr + "{{supply_address}}";
      }
    },
    addComments(language) {
      if (language === "en") {
        this.form.smsTemplateEn = this.form.smsTemplateEn + "{{comments}}";
      } else {
        this.form.smsTemplateAr = this.form.smsTemplateAr + "{{comments}}";
      }
    },
    addNameEmail(language) {
      if (language === "en") {
        this.form.emailTemplateEn = this.form.emailTemplateEn + "{{name}}";
      } else {
        this.form.emailTemplateAr = this.form.emailTemplateAr + "{{name}}";
      }
    },
    addRequestNumberEmail(language) {
      if (language === "en") {
        this.form.emailTemplateEn =
          this.form.emailTemplateEn + "{{request_number}}";
      } else {
        this.form.emailTemplateAr =
          this.form.emailTemplateAr + "{{request_number}}";
      }
    },
    addIDTypeEmail(language) {
      if (language === "en") {
        this.form.emailTemplateEn = this.form.emailTemplateEn + "{{id_type}}";
      } else {
        this.form.emailTemplateAr = this.form.emailTemplateAr + "{{id_type}}";
      }
    },
    addIDNumberEmail(language) {
      if (language === "en") {
        this.form.emailTemplateEn = this.form.emailTemplateEn + "{{id_number}}";
      } else {
        this.form.emailTemplateAr = this.form.emailTemplateAr + "{{id_number}}";
      }
    },
    addRequestStatusEmail(language) {
      if (language === "en") {
        this.form.emailTemplateEn =
          this.form.emailTemplateEn + "{{request_status}}";
      } else {
        this.form.emailTemplateAr =
          this.form.emailTemplateAr + "{{request_status}}";
      }
    },
    addServiceTypeEmail(language) {
      if (language === "en") {
        this.form.emailTemplateEn =
          this.form.emailTemplateEn + "{{service_type}}";
      } else {
        this.form.emailTemplateAr =
          this.form.emailTemplateAr + "{{service_type}}";
      }
    },
    addSupplyAddressEmail(language) {
      if (language === "en") {
        this.form.emailTemplateEn =
          this.form.emailTemplateEn + "{{supply_address}}";
      } else {
        this.form.emailTemplateAr =
          this.form.emailTemplateAr + "{{supply_address}}";
      }
    },
    addCommentsEmail(language) {
      if (language === "en") {
        this.form.emailTemplateEn = this.form.emailTemplateEn + "{{comments}}";
      } else {
        this.form.emailTemplateAr = this.form.emailTemplateAr + "{{comments}}";
      }
    },
    async submitForm() {
      if (!this.form.smsTemplateEn) {
        return this.$store.commit(
          "settings/toggleAlert",
          this.$t("smsTemplateEnRequired")
        );
      }
      if (!this.form.smsTemplateAr) {
        return this.$store.commit(
          "settings/toggleAlert",
          this.$t("smsTemplateArRequired")
        );
      }
      if (!this.form.emailTemplateEn) {
        return this.$store.commit(
          "settings/toggleAlert",
          this.$t("emailTemplateEnRequired")
        );
      }
      if (!this.form.emailTemplateAr) {
        return this.$store.commit(
          "settings/toggleAlert",
          this.$t("emailTemplateArRequired")
        );
      }
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let emailAr = this.form.emailTemplateAr
            .replace(/<table/g,"<table style=' border: 1px solid black; border-collapse: collapse;'")
            .replace(/<td/g, "<td style=' border: 1px solid black;'")
            .replace(/<th/g, "<th style=' border: 1px solid black;'");
          let emailEn = this.form.emailTemplateEn
            .replace(/<table/g,"<table style=' border: 1px solid black; border-collapse: collapse;'")
            .replace(/<td/g, "<td style=' border: 1px solid black;'")
            .replace(/<th/g, "<th style=' border: 1px solid black;'");
          const data = {
            name: this.form.name,
            isEmailRequired: this.form.isEmailRequired ? "true" : "false",
            isSmsRequired: this.form.isSmsRequired ? "true" : "false",
            smsTemplateEn: this.form.smsTemplateEn,
            smsTemplateAr: this.form.smsTemplateAr,
            emailTemplateEn: emailEn,
            emailTemplateAr: emailAr,
          };

          this.formLoading = true;
          const [error, response] = await handlePromise(
            this.valueToEdit
              ? NotificationService.updateNotification(
                  this.valueToEdit.id,
                  data
                )
              : NotificationService.saveNotification(data)
          );
          this.formLoading = false;
          if (!response.ok)
            return this.$store.commit("settings/toggleAlert", this.$t(error));
          this.$store.commit(
            "settings/toggleSuccessAlert",
            this.$t("notificationSaved")
          );
          this.$emit("confirm");
          this.$emit("close");
        }
      });
    },
    close() {
      this.$emit("close");
    },
    toggleLoading() {
      this.formLoading = !this.formLoading;
    },
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background: rgb(224, 223, 223);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(197, 197, 197);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1400;
}

.modal {
  background: #ffffff;
  overflow-x: auto;
  display: flex;
  width: 90%;
  border-radius: 5px;
  flex-direction: column;
  padding: 10px;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  color: #000000;
  font-weight: 490;
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: column;
  justify-content: flex-end;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  height: 60vh;
  overflow-y: auto;
}

.modal-footer {
  position: sticky;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active {
  transition: opacity 0.5s ease;
}
.modal-fade-leave-active {
  transition: opacity 0.5s ease;
}

.quill {
  overflow-y: scroll;
  resize: vertical;
  overflow: hidden;
  border: 1px solid #d6d6d6;
}
</style>
