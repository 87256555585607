<template>
  <ods-row type="flex" justify="center">
    <ods-button
        type="primary"
        class="mr-1"
        @click="$emit('submit')"
        :loading="loading"
        :disabled=disabledSubmit
    >
      {{$t('save')}}
    </ods-button>
    <ods-button
        class="mr-1"
        type="secondary"
        :disabled="loading"
        v-if="verified"
        @click="$emit('verified')"
    >
      {{$t('verified')}}
    </ods-button>
    <router-link :to="{ name: cancelTo}" v-if="cancelTo">
      <ods-button type="secondary">{{$t(cancelText)}}</ods-button>
    </router-link>
    <ods-button
        type="secondary"
        :disabled="loading"
        v-if="!cancelTo"
        @click="$emit('cancel')"
    >
      {{$t(cancelText)}}
    </ods-button>
  </ods-row>
</template>

<script>

export default {
  name: 'FormButtons',
  props: {
    loading: Boolean,
    cancelTo: String,
    verified: Boolean,
    form: {},
    cancelText: {
      type: String,
      default: 'cancel'
    },
    disabledSubmit: {
      type: Boolean,
      default: false
    }
  }
}
</script>
