<template>
  <section>
    <ods-module>
      <template slot="header">
       <list-header title="users">
          <template slot="right">
            <router-link :to="{ name: 'UserNew'}">
              <ods-button size="medium" type="primary">
                <ods-icon name = "plus"  style="margin-left:-10px; display:inherit; float:left; padding-right:10px"  size="18"/>{{ $t('newUser') }}
              </ods-button>
            </router-link>
            <!-- <router-link :to="{ name: 'UserChangePassword'}">
                <ods-button style="float:right;margin-left:20px;margin-top:5px"
                    size="mini"
                    type="secondary"
                >
                  {{$t('changePassword')}}
                </ods-button>
            </router-link> -->
          </template>
        </list-header>
      </template>
      <async-table
          :data="data"
          :total="total"
          :loading="loading"
          @fetchData="getData"
      >
        <ods-table-column prop="email" :label="$t('email')">
          <template slot-scope="scope">
            <router-link v-if="scope.row.id" :to="{ name: 'UserEdit', params: { id: scope.row.id }}">
              <ods-button type="text" class="p-0">{{scope.row.email}}</ods-button>
            </router-link>
          </template>
        </ods-table-column>

        <ods-table-column
            prop="name"
            :label="$t('name')"
            :formatter="(row) => row.name"
        />
        <ods-table-column
            prop="lastName"
            :label="$t('lastName')"
            :formatter="(row) => row.lastName"
        />
        <ods-table-column
            prop="roles"
            :label="$t('roles')"
        >
            <template slot-scope="scope">
              <div v-for="item in scope.row.roles" :key="item" >
                <span v-if="item !== 'ROLE_API'">
                  {{$t(item)}}
                </span>
              </div>
            </template>
        </ods-table-column>

        <ods-table-column prop="enabled" :label="$t('enabled')">
          <template slot-scope="scope">
            <ods-icon name="checkmark-2" v-if="scope.row.enabled"></ods-icon>
            <ods-icon name="close" v-if="!scope.row.enabled"></ods-icon>
          </template>
        </ods-table-column>

         <ods-table-column
            prop="cpr"
            :label="$t('username')"
        />
      </async-table>

      <dialog-confirmation
          :visible="dialogVisible"
          :loading="deleteLoading"
          @cancel="toggleDialog"
          @confirm="deleteItem"
      />
    </ods-module>
  </section>
</template>

<script>
import UserService from '@/services/User'
import handlePromise from '@/utils/promise'
import AsyncTable from '@/custom-components/AsyncTable'
import ListHeader from '@/custom-components/ListHeader'
import DialogConfirmation from '@/custom-components/DialogConfirmation'
import asyncTableCommon from '@/mixins/async-table'
import { mapState } from 'vuex'

export default {
  name: 'UsersDashboard',
  components: {
    AsyncTable,
    DialogConfirmation,
    ListHeader
  },
  computed: {
    ...mapState({
      userRole: state => state.userRoles.data
    })
  },
  mounted () {
    // this.handlePermission()
  },
  mixins: [asyncTableCommon],
  data () {
    return {
      // hasPermission: true,
      deleteLoading: false,
      dialogVisible: false,
      toDelete: null,
      promise: (params) => UserService.getUsers(params)
    }
  },
  methods: {
    toggleDialog (id) {
      this.toDelete = id
      this.dialogVisible = !this.dialogVisible
    },
    async deleteItem () {
      this.deleteLoading = true

      const [error, response] = await handlePromise(UserService.deleteUser(this.toDelete))
      this.deleteLoading = false
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      this.toggleDialog()
      this.getData(this.tableParams)
    }
    // handlePermission () {
    //   let rolesArray = Object.values(this.userRole)
    //   rolesArray.map(role => {
    //     if (role === 'ROLE_OPERATOR') {
    //       this.hasPermission = false
    //       rolesArray.map(role2 => {
    //         if (role2 === 'ROLE_ADMIN') {
    //           this.hasPermission = true
    //         }
    //       })
    //       rolesArray.map(role2 => {
    //         if (role2 === 'ROLE_SUPER_ADMIN') {
    //           this.hasPermission = true
    //         }
    //       })
    //       rolesArray.map(role2 => {
    //         if (role2 === 'ROLE_MANAGER') {
    //           this.hasPermission = true
    //         }
    //       })
    //     }
    //   })
    // }
  }
}
</script>
