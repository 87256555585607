<template>
  <section v-loading="loading">
     <ods-row :gutter="20" type="flex">
       <ods-module :header="$t('statusMaintenance')" style="width:100%">
        <ods-col :md="9" :sm="9">
          <ods-module class="mb-1" :header="$t('currentStatus')">
            <ods-table
              ref="singleTable"
              :data="statuses"
              highlight-current-row
              size="mini"
              @current-change="getStatusMap"
              style="width: 100%">
              <ods-table-column
                property="statusMaster"
                :label="$t('name')"
                width="200">
              </ods-table-column>
              <ods-table-column width="100"  :label="$t('notify')">
                <template slot-scope="scope">
                  <ods-checkbox
                    v-model="scope.row.mustNotify"
                    @change="handleStatusNotify(scope.row)"
                    >
                  </ods-checkbox>
                </template>
              </ods-table-column>
            </ods-table>
            <!-- <ods-radio-group v-model="selectedStatus" @change="getStatusMap()">
              <div class="mb-1"  v-for="status in statuses" :key="status.id">
                <ods-radio :key="status.id" :label="status.id">
                  {{status.statusMaster}}
                  <ods-checkbox
                    v-model="mustNotify"
                    disabled
                    >
                  </ods-checkbox>
                </ods-radio>
              </div>
            </ods-radio-group> -->
          </ods-module>
        </ods-col>
        <ods-col :md="8" :sm="8">
          <ods-module>
            <ods-table
                :data="statuses"
                ref="multipleTable"
                v-loading="loading"
                size="mini"
                style="width: 100%;margin-top:20px"
            >
              <!-- <ods-table-column v-if="selectedStatus"
                type="selection"
                width="55">
              </ods-table-column> -->
              <ods-table-column width="55">
                <template slot-scope="scope" >
                  <ods-checkbox
                    v-model="scope.row.isActive"
                    @change="handleChangeStatus(scope.row)"
                    :disabled="!selectedStatus"
                    >
                  </ods-checkbox>
                </template>
              </ods-table-column>
              <ods-table-column prop="statusMaster" width="200" :label="$t('posibleChangeOfStatus')" />
              <!-- <ods-table-column prop="date" width="180" :label="$t('date')">
                <template slot-scope="scope">
                    {{ scope.row.createdAt| date }}
                </template>
              </ods-table-column> -->
            </ods-table>
          </ods-module>
        </ods-col>
        <ods-col :md="7" :sm="7">
          <ods-module :header="$t('notifications')">
            <ods-radio-group v-model="selectedNotification" @change="handleNotification">
              <div class="mb-1"  v-for="notification in notifications" :key="notification.id">
                <ods-radio :key="notification.id" :label="notification.id">
                  {{notification.name}}
                </ods-radio>
              </div>
            </ods-radio-group>
          </ods-module>
        </ods-col>
       </ods-module>
     </ods-row>
  </section>
</template>

<script>
import handlePromise from '@/utils/promise'
import _ from 'lodash'
import StatusMapping from '@/services/StatusMapping'
import Request from '@/services/Request'
import NotificationService from '@/services/Notification'

export default {
  name: 'StatusMapping',
  data () {
    return {
      loading: false,
      mustNotify: false,
      statuses: [],
      selectedStatus: null,
      selectedNotification: null,
      objectSelectedStatus: null,
      statusMap: [],
      notifications: []
    }
  },
  mounted () {
    this.getStatuses()
    this.getStatusMap()
    this.getNotifications()
  },
  methods: {
    async getStatuses () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(Request.getStatus())
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))

      this.statuses = data.data
      this.statuses.map(item => {
        item.isActive = false
      })
    },
    async getStatusMap (val) {
      if (val) {
        this.selectedStatus = val.id
        this.objectSelectedStatus = val
      }
      this.statusMap = []
      this.toggleLoading()
      const [error, response, data] = await handlePromise(StatusMapping.getStatusMap(this.selectedStatus))
      this.toggleLoading()
      this.statuses.map(item => {
        item.isActive = false
      })
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.statusMap = data.data
      if (data.total > 0 && this.selectedStatus) {
        this.statuses.map((item) => {
          if (_.find(data.data, function (mapedStatus) { return mapedStatus.nextStatus.id === item.id })) {
            item.isActive = true
          }
        })
      }
      if (this.selectedStatus && val) {
        this.notifications.map(item => {
          item.isEmailRequired = false
        })
        if (val.notificationType) {
          this.selectedNotification = null
          this.selectedNotification = this.objectSelectedStatus.notificationType.id
        }
      }
    },
    async getNotifications () {
      let params = {
        limit: -1
      }
      this.toggleLoading()
      const [error, response, data] = await handlePromise(NotificationService.getNotifications(params))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.notifications = data.data
      this.notifications.map(item => {
        item.isEmailRequired = false
      })
    },
    async handleStatusNotify (val) {
      const data = {
        mustNotify: val.mustNotify ? 'true' : 'false'
      }
      this.toggleLoading()
      const [error, response] = await handlePromise(Request.updateStatus(val.id, data))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
    },
    async handleNotification (row) {
      this.objectSelectedStatus.notificationType.id = row
      const data = {
        notificationType: row
      }
      this.toggleLoading()
      const [error, response] = await handlePromise(Request.updateStatus(this.selectedStatus, data))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
    },
    async handleChangeStatus (row) {
      const data = {
        'status': this.selectedStatus,
        'nextStatus': row.id
      }
      if (row.isActive) {
        this.toggleLoading()
        const [error, response] = await handlePromise(StatusMapping.saveStatusMap(data))
        this.toggleLoading()
        if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
        this.getStatusMap(this.objectSelectedStatus)
      } else {
        let statusForDelete = _.find(this.statusMap, function (o) { return o.status.id === data.status && o.nextStatus.id === data.nextStatus })
        this.toggleLoading()
        const [error, response] = await handlePromise(StatusMapping.deleteStatusMap(statusForDelete.id))
        this.toggleLoading()
        if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
        this.getStatusMap(this.objectSelectedStatus)
      }
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
