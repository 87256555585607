import api from '@/config/Api'

const baseEndpoint = 'administration/ouc/payments'

export default {
  getPayments (params) {
    return api.get(baseEndpoint, params)
  },
  getExcel (data) {
    return api.post('administration/payment/exel-generate', data)
  }
}
