<template>
  <section v-loading="loading">
    <ods-module :header="$t('filterCriteria')">
      <ods-form :model="search" ref="form">
        <ods-row :gutter="20">
          <ods-col :md="12" :sm="12">
            <ods-form-item :label="$t('origin')" prop="origin">
              <ods-select v-model="search.origin" placeholder="" remote clearable filterable>
                <ods-option
                    v-for="item in origins"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" :sm="12">
            <ods-form-item :label="$t('statusResponse')" prop="statusResponse">
              <ods-select v-model="search.statusResponse" placeholder="" remote clearable filterable>
                <ods-option
                    v-for="item in statuses"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
        </ods-row>
        <ods-row :gutter="20">
          <ods-col :md="12" :sm="12">
            <ods-form-item
              :label="$t('body')"
              prop="body"
            >
              <ods-input v-model="search.body"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" :sm="12">
            <ods-form-item
              :label="$t('response')"
              prop="response"
            >
              <ods-input v-model="search.response"></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>
        <ods-row
            type="flex"
            justify="center">
          <ods-button type="primary" @click="getData">
            {{$t('search')}}
          </ods-button>
          <ods-button type="primary" @click="clearFilters">
            {{$t('reset')}}
          </ods-button>
        </ods-row>
      </ods-form>
    </ods-module>
    <ods-module class="mt-1" :header="'logs'">
      <template slot="header">
        <list-header title="logs">
          <template slot="right">
            <ods-button type="danger" @click="toggleDeleteDialog()" size="medium">
              {{$t('deleteAllLogs')}}
            </ods-button>
          </template>
        </list-header>
      </template>
       <ods-table
          :data="data"
          v-loading="loading"
          @sort-change="handleSort"
          :defaultSort = "{prop: 'createdAt', order: 'descending'}"
          size="mini"
          style="width: 100%"
      >
        <ods-table-column prop="action" :label="$t('action')">
            <template slot-scope="scope">
                {{ scope.row.action }}
            </template>
        </ods-table-column>
        <ods-table-column prop="origin" :label="$t('origin')">
          <template slot-scope="scope">
              {{ scope.row.origin }}
          </template>
        </ods-table-column>
        <ods-table-column prop="url" :label="$t('url')" width="240"/>
        <ods-table-column prop="statusResponse" width="140" :label="$t('statusResponse')"/>
        <ods-table-column prop="header" :label="$t('header')" width="240">
          <template slot-scope="scope">
              {{ scope.row.header | max50}}
          </template>
        </ods-table-column>
        <ods-table-column prop="body" :label="$t('body')" width="240">
          <template slot-scope="scope">
              {{ scope.row.body | max50}}
          </template>
        </ods-table-column>
        <ods-table-column prop="response" :label="$t('response')" width="240">
          <template slot-scope="scope">
              {{ scope.row.response | max50}}
          </template>
        </ods-table-column>
        <ods-table-column prop="result" :label="$t('result')">
          <template slot-scope="scope">
              {{ scope.row.result  ? scope.row.result : '-'}}
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('view')">
          <template slot-scope="scope">
            <ods-button
              size="mini"
              type="primary"
              icon="ods-icon-eye" @click="toggleDialog(scope.row)"/>
          </template>
        </ods-table-column>
        <ods-table-column prop="createdAt" :label="$t('createdAt')" sortable width="160">
          <template slot-scope="scope">
            {{scope.row.createdAt | date}}
          </template>
        </ods-table-column>
      </ods-table>
      <div class="block mr-1">
        <ods-pagination
            :total="total"
            :page-size="pageSize"
            @size-change="handleSizeChange"
            @current-change="handlePageChange"
            layout="prev, pager, next, jumper, sizes, ->, total"
        >
        </ods-pagination>
      </div>
    </ods-module>
    <dialog-confirmation
          :visible="dialogDeleteVisible"
          :loading="deleteLoading"
          v-bind:deleteAllLogs="true"
          @confirm="deleteAllLogs"
          @cancel="toggleDeleteDialog()"
        />
    <DetailModal v-if="dialogVisible"
      :valueToShow="valueToShow"
      v-show="dialogVisible"
      @close="toggleDialog"
    />
  </section>
</template>

<script>
import map from 'lodash/map'
import handlePromise from '@/utils/promise'
import moment from 'moment'
import LogService from '@/services/Log'
import DetailModal from './DetailModal.vue'
import ListHeader from '@/custom-components/ListHeader'
import DialogConfirmation from '@/custom-components/DialogConfirmation'

export default {
  name: 'Logs',
  props: {
    params: Object
  },
  components: {
    DetailModal,
    ListHeader,
    DialogConfirmation
  },
  data () {
    return {
      loading: false,
      search: {},
      data: [],
      pageSize: 10,
      pageIndex: 0,
      pageStartZero: true,
      total: 0,
      sort: null,
      dialogVisible: false,
      valueToShow: null,
      dialogDeleteVisible: false,
      deleteLoading: false,
      origins: [{ id: 'PORTAL', name: 'PORTAL' }, { id: 'OUC', name: 'OUC' }],
      statuses: [{ id: '400', name: '400' }, { id: '404', name: '404' }, { id: '200', name: '200' }, { id: '500', name: '500' }, { id: '1000', name: '1000' }]
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    clearFilters () {
      this.search = {}
    },
    toggleDeleteDialog () {
      this.dialogDeleteVisible = !this.dialogDeleteVisible
    },
    async deleteAllLogs () {
      this.toggleLoading()
      const [error, response] = await handlePromise(LogService.deleteLogs())
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.toggleDeleteDialog()
      this.getData()
    },
    async getData () {
      let auxParams = {}
      if (this.search.origin) {
        auxParams = {
          'filters[origin]': this.search.origin,
          ...auxParams
        }
      }

      if (this.search.statusResponse) {
        auxParams = {
          'filters[statusResponse]': this.search.statusResponse,
          ...auxParams
        }
      }

      if (this.search.body) {
        auxParams = {
          'filters[body]': this.search.body,
          ...auxParams
        }
      }

      if (this.search.response) {
        auxParams = {
          'filters[response]': this.search.response,
          ...auxParams
        }
      }

      let params = {
        offset: this.pageIndex === 0 && this.pageStartZero ? 0 : (this.pageIndex * this.pageSize),
        limit: this.pageSize,
        ...auxParams
      }

      if (this.sort && this.sort.prop) params[`sortby[${this.sort.prop}]`] = this.sort.order === 'ascending' ? 'ASC' : 'DESC'
      this.toggleLoading()
      const [error, response, data] = await handlePromise(LogService.getLogs(params))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.data = data.data
      this.total = data.total
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getData()
    },
    handlePageChange (val) {
      this.pageIndex = val - 1
      this.getData()
    },
    handleFilters (filters) {
      this.filters = {}
      map(filters, (filter, key) => {
        if (filter) this.filters[`filters[${key}]`] = filter
      })

      this.getData()
    },
    handleSort (val) {
      this.sort = { prop: val.prop, order: val.order }
      this.getData()
    },
    async toggleDialog (val) {
      if (val) {
        this.valueToShow = val
      } else { this.valueToShow = null }
      this.dialogVisible = !this.dialogVisible
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
