import api from '@/config/Api'

const baseEndpoint = 'administration/dashBoard'

export default {
  getGraphs () {
    return api.get(baseEndpoint)
  },
  getMap () {
    return api.get('administration/dashBoardMap')
  }
}
