import api from '@/config/Api'

const baseEndpoint = 'administration/api-logs'

export default {
  getLogs (params) {
    return api.get(baseEndpoint, params)
  },
  deleteLogs () {
    return api.delete(baseEndpoint)
  },
  getLog (id) {
    return api.get(`administration/api-log/${id}`)
  }
}
