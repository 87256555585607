<template>
  <section v-loading="loading">
    <ods-module>
      <ods-row>
        <p class="font-weight-bold" style="margin-top:25px;font-size:130%">{{$t('externalData')}}</p>
        <ods-button style="float:right;margin-top:-30px" type="primary" @click="getExternalData()" size="medium">
              {{$t('fetchExternalAgain')}}
        </ods-button>
      </ods-row>
    </ods-module>
    <ods-row :gutter="24">
      <ods-col :md="12" :sm="24">
         <ods-module class="mt-1" :header="$t('propertyCategory')">
            <ods-table
                :data="propertyCategoryData"
                v-loading="loading"
                size="mini"
                style="width: 100%"
            >
              <ods-table-column prop="code" :label="$t('code')" />
              <ods-table-column prop="descriptionEn" :label="$t('descriptionEn')" />
              <ods-table-column prop="descriptionAr" :label="$t('descriptionAr')" />
              <!-- <ods-table-column prop="createdAt" :label="$t('createdAt')">
                <template slot-scope="scope">
                  {{scope.row.createdAt | date}}
                </template>
              </ods-table-column>
              <ods-table-column prop="updatedAt" :label="$t('updatedAt')">
                <template slot-scope="scope">
                  {{scope.row.updatedAt | date}}
                </template>
              </ods-table-column> -->
            </ods-table>
            <div class="block mr-1">
              <ods-pagination
                  :total="propertyCategoryTotal"
                  :page-size="propertyCategoryPageSize"
                  @current-change="handlePageChangePropertyCategory"
                  layout="prev, pager, next, jumper, ->, total"
              >
              </ods-pagination>
            </div>
        </ods-module>
      </ods-col>
      <ods-col :md="12" :sm="24">
         <ods-module class="mt-1" :header="$t('propertyType')">
            <ods-table
                :data="propertyTypeData"
                v-loading="loading"
                size="mini"
                style="width: 100%"
            >
              <ods-table-column prop="code" :label="$t('code')" />
              <ods-table-column prop="descriptionEn" :label="$t('descriptionEn')" />
              <ods-table-column prop="descriptionAr" :label="$t('descriptionAr')" />
              <!-- <ods-table-column prop="createdAt" :label="$t('createdAt')">
                <template slot-scope="scope">
                  {{scope.row.createdAt | date}}
                </template>
              </ods-table-column>
              <ods-table-column prop="updatedAt" :label="$t('updatedAt')">
                <template slot-scope="scope">
                  {{scope.row.updatedAt | date}}
                </template>
              </ods-table-column> -->
            </ods-table>
            <div class="block mr-1">
              <ods-pagination
                  :total="propertyTypeTotal"
                  :page-size="propertyTypePageSize"
                  @current-change="handlePageChangePropertyType"
              >
              </ods-pagination>
            </div>
        </ods-module>
      </ods-col>
    </ods-row>
  </section>
</template>

<script>
import handlePromise from '@/utils/promise'
import RequestService from '@/services/Request'
import ExternalDataService from '@/services/ExternalData'

export default {
  name: 'ExternalData',
  props: {
    params: Object
  },
  data () {
    return {
      loading: false,
      propertyCategoryData: [],
      propertyCategoryPageSize: 5,
      propertyCategoryPageIndex: 0,
      propertyCategoryTotal: 0,
      propertyTypeData: [],
      propertyTypePageSize: 5,
      propertyTypePageIndex: 0,
      propertyTypeTotal: 0,
      pageStartZero: true
    }
  },
  mounted () {
    this.getPropertyCategoryData()
    this.getPropertyTypeData()
  },
  methods: {
    async getExternalData () {
      this.toggleLoading()
      const [error, response] = await handlePromise(ExternalDataService.getExternalData())
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      await this.getPropertyTypeData()
      await this.getPropertyCategoryData()
    },
    async getPropertyTypeData () {
      let params = {
        offset: this.propertyTypePageIndex === 0 && this.pageStartZero ? 0 : (this.propertyTypePageIndex * this.propertyTypePageSize),
        limit: this.propertyTypePageSize
      }
      this.toggleLoading()
      const [error, response, data] = await handlePromise(RequestService.getPropertyTypes(params))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.propertyTypeData = data.data
      this.propertyTypeTotal = data.total
    },
    async getPropertyCategoryData () {
      let params = {
        offset: this.propertyCategoryPageIndex === 0 && this.pageStartZero ? 0 : (this.propertyCategoryPageIndex * this.propertyCategoryPageSize),
        limit: this.propertyCategoryPageSize
      }
      this.toggleLoading()
      const [error, response, data] = await handlePromise(RequestService.getPropertyCategories(params))
      this.toggleLoading()
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.propertyCategoryData = data.data
      this.propertyCategoryTotal = data.total
    },
    handlePageChangePropertyType (val) {
      this.propertyTypePageIndex = val - 1
      this.getPropertyTypeData()
    },
    handlePageChangePropertyCategory (val) {
      this.propertyCategoryPageIndex = val - 1
      this.getPropertyCategoryData()
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
