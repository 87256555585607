import api from '@/config/Api'

const baseEndpoint = 'administration/requests'
const baseEndpoint2 = 'administration/request-attachments'
const baseEndpoint3 = 'administration/requests-history'
const baseEndpoint4 = 'administration/meterDetails'
const baseEndpoint5 = 'administration/requestRscodes'

export default {
  getRequests (params) {
    return api.get(baseEndpoint, params)
  },

  getStatusinOUC (reference) {
    return api.get(`administration/ouc/requests/${reference}/status-ouc`)
  },

  getRequestDetail (id) {
    return api.get(`${baseEndpoint}/${id}`)
  },

  updateRequest (id, data) {
    return api.put(`${baseEndpoint}/${id}`, data)
  },

  getRSCode (id) {
    return api.get(`${baseEndpoint5}?filters[request]=${id}`)
  },

  // getRequestAtachment (codeType, codeService) {
  //   return api.get(`administration/docList-attachments?filters[serviceType]=${codeService}&filters[customerType]=${codeType}`)
  // },

  getRequestAtachment (id) {
    return api.get(`${baseEndpoint2}?filters[request]=${id}`)
  },

  updateAtachment (id, data) {
    return api.put(`${baseEndpoint2}/${id}`, data)
  },

  getHistory (id, params) {
    return api.get(`${baseEndpoint3}?filters[request]=${id}`, params)
  },

  getOwnerDetails (id) {
    return api.get(`administration/propertyOwner/${id}`)
  },

  getOwnerDetailsOUC (referenceNumber) {
    return api.get(`administration/ouc/requests/${referenceNumber}/propertyOwner`)
  },

  getMeterRadingsDetails (referenceNumber) {
    return api.get(`administration/ouc/requests/${referenceNumber}/meterDetail`)
  },

  getRSCodesDetails (referenceNumber) {
    return api.get(`administration/ouc/requests/${referenceNumber}/requestRscode`)
  },

  getMeterReadigns (id, params) {
    return api.get(`${baseEndpoint4}?filters[request]=${id}`, params)
  },

  getEstimateConsumption (request) {
    return api.get(`administration/meterDetail/estimate/${request}`)
  },

  updateMeterReading (id, data) {
    return api.put(`administration/meterDetail/${id}`, data)
  },

  getCustomersTypes (params) {
    return api.get('administration/customerTypes', params)
  },

  getPropertyTypes (params) {
    return api.get('administration/propertyTypes', params)
  },

  getPropertyCategories (params) {
    return api.get('administration/propertyCategories', params)
  },

  getPropertyTypeByCategory (id) {
    return api.get(`administration/property-category-types?filters[propertyCategory]=${id}&limit=-1`)
  },

  getAttachment (url) {
    return api.get(url)
  },

  getTarif (propertyType) {
    return api.get(`administration/rs-desc?filters[propertyType]=${propertyType}`)
  },

  getGovernorates () {
    return api.get('administration/governorates')
  },

  getServiceTypes () {
    return api.get('administration/service-types')
  },

  getStatus () {
    return api.get('administration/status')
  },

  updateStatus (id, data) {
    return api.put(`administration/status/${id}`, data)
  },

  getPayments (params) {
    return api.get(baseEndpoint, params)
  }
}
