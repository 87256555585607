import Vue from 'vue'
import Router from 'vue-router'
import _ from 'lodash'
import Profile from '../views/Profile'
import Login from '../views/Login'
import ChangePassword from '../views/User/ChangePassword.vue'
import Private from '../views/Private'

import UserDashboard from '../views/User/Dashboard'
import UserEdit from '../views/User/Edit'
import UserChangePassword from '../views/User/ChangePassword'

import Dashboard from '../views/Dashboard/Dashboard'
import RequestDashboard from '../views/Requests/Dashboard'
import RequestDetail from '../views/Requests/Detail'

import StatusMapping from '../views/StatusMapping/Dashboard'

import notificationMaintenance from '../views/NotificationsMaintenance/Dashboard'

import Doclist from '../views/Doclist/Dashboard'

import AttachmentType from '../views/AttachmentType/Dashboard'

import AttachmentTypeEdit from '../views/AttachmentType/Edit'

import Payments from '../views/Payments/Dashboard'

import ReadCards from '../views/ReadCard/Dashboard'

import Logs from '../views/Logs/Dashboard'

import ExternalData from '../views/ExternalData/Dashboard'

import storage from '../utils/storage'

Vue.use(Router)

const route = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Private,
      children: [
        {
          path: '/',
          redirect: '/dashboard'
        },
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: '/request-dashboard',
          name: 'RequestDashboard',
          component: RequestDashboard
        },
        {
          path: '/request-dashboard/directLink/:id',
          name: 'RequestDashboard',
          component: RequestDashboard
        },
        {
          path: '/request-dashboard/:id',
          name: 'RequestDetail',
          component: RequestDetail
        },
        {
          path: '/users',
          component: UserDashboard,
          name: 'UserDashboard'
        },
        {
          path: '/users/new',
          name: 'UserNew',
          component: UserEdit
        },
        {
          path: '/users/:id/change-password',
          name: 'UserChangePassword',
          component: UserChangePassword
        },
        {
          path: '/users/:id',
          name: 'UserEdit',
          component: UserEdit
        },
        {
          path: '/profile',
          name: 'Profile',
          component: Profile
        },
        {
          path: '/statusMaintenance',
          name: 'StatusMapping',
          component: StatusMapping
        },
        {
          path: '/notificationMaintenance',
          name: 'notificationMaintenance',
          component: notificationMaintenance
        },
        {
          path: '/doclist',
          name: 'Doclist',
          component: Doclist
        },
        {
          path: '/attachment-type',
          name: 'AttachmentType',
          component: AttachmentType
        },
        {
          path: '/attachment-type/new',
          name: 'AttachmentTypeNew',
          component: AttachmentTypeEdit
        },
        {
          path: '/attachment-type/:id',
          name: 'AttachmentTypeEdit',
          component: AttachmentTypeEdit
        },
        {
          path: '/payments',
          name: 'Payments',
          component: Payments
        },
        {
          path: '/read-cards',
          name: 'ReadCards',
          component: ReadCards
        },
        {
          path: '/external-data',
          name: 'ExternalData',
          component: ExternalData
        },
        {
          path: '/logs',
          name: 'Logs',
          component: Logs
        }
      ]
    },
    {
      path: '/auth/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/reset-password/:token',
      name: 'ChangePassword',
      component: ChangePassword
    }
  ]

})

route.beforeEach((to, from, next) => {
  const loggedIn = !!_.get(storage.get(), 'user.data.access_token')
  const roles = _.get(storage.get(), 'user.data.user.roles')
  const isCallCenter = !!(_.find(roles, function (r) { return r === 'ROLE_CALL_CENTER' }))
  const isOperator = !!(_.find(roles, function (r) { return r === 'ROLE_OPERATOR' }))
  const isAdmin = !!(_.find(roles, function (r) { return r === 'ROLE_ADMIN' }))

  if (to.name === 'ChangePassword') {
    return next()
  } else if (to.name !== 'Login' && !loggedIn) {
    return next({ name: 'Login' })
  } else if (to.name !== 'Profile' && to.name !== 'Payments' && isCallCenter && !isOperator && !isAdmin) {
    return next({ name: 'Payments' })
  } else if (to.name !== 'Profile' && to.name !== 'RequestDashboard' && to.name !== 'RequestDetail' && to.name !== 'Dashboard' && !isCallCenter && isOperator && !isAdmin) {
    return next({ name: 'Dashboard' })
  } else if (to.name !== 'Profile' && to.name !== 'RequestDashboard' && to.name !== 'RequestDetail' && to.name !== 'Dashboard' && to.name !== 'Payments' && isCallCenter && isOperator && !isAdmin) {
    return next({ name: 'Dashboard' })
  } else {
    return next()
  }
})

export default route
