<template>
    <transition name="modal-fade">
    <div class="modal-backdrop">
        <div class="modal"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
        >
        <header class="modal-header" id="modalTitle">
            <slot name="header">
              {{$t('getLongAuthToken')}}-{{userEmail}}
            </slot>
            <button
            type="button"
            class="btn-close"
            @click="close"
            aria-label="Close modal"
            >
             <ods-icon name = "close" size = "18" />
            </button>
        </header>
            <ods-module v-loading="loadingModal">
              <section class="modal-body">
                  <slot name="body">
                     <ods-form
                          :model="form"
                          ref="form"
                          v-loading="loadingModal"
                      >
                        <ods-row :gutter="20" v-if="!form.token">
                          <ods-col :md="24">
                            <ods-form-item :label="$t('password')" prop="password" :rules="[errors.required]">
                              <ods-input v-model="form.password" type="password"></ods-input>
                            </ods-form-item>
                          </ods-col>
                        </ods-row>
                        <ods-row :gutter="20" v-if="form.token">
                          <ods-col :md="24">
                            <ods-form-item :label="$t('token')" prop="token" >
                              <ods-input v-bind:autosize="true" v-model="form.token" type="textarea"></ods-input>
                            </ods-form-item>
                          </ods-col>
                        </ods-row>
                      </ods-form>
                  </slot>
              </section>
            </ods-module>
            
        <ods-button v-if="!form.token" @click="submitForm()">{{$t('getToken')}}</ods-button>
        </div>
    </div>
  </transition>
</template>

<script>
import errors from '@/config/InputErrors'
import FormButtons from '@/custom-components/Buttons/FormButtons'
import handlePromise from '@/utils/promise'
import moment from 'moment'
import Vue from 'vue'
import { mapState } from 'vuex'
import AuthService from '@/services/Auth'

export default {
  name: 'LongDurationTokenModal',
  props: {
    userEmail: String
  },
  computed: {
    ...mapState({
      userData: state => state.user.data
    })
  },
  mounted: function () {

  },
  data () {
    return {
      form: {},
      errors,
      loadingModal: false
    }
  },
  methods: {
    // copyURL() {
    //   var Url = this.$refs.mylink;
    //   Url.innerHTML = window.location.href;
    //   console.log(Url.innerHTML)
    //   Url.select();
    //   document.execCommand("copy");
    // },
    async submitForm () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.toggleLoading()
          const [error, response, data] = await handlePromise(AuthService.loginLongDuration({
            username: this.userEmail, // 'admin@sauna.com',
            password: this.form.password// '123456'
          }))
          this.toggleLoading()
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          this.form.token = data.access_token
        }
      })
    },
    close () {
      this.$emit('close')
    },
    toggleLoading () {
      this.loadingModal = !this.loadingModal
    }
  }
}
</script>
<style >
  ::-webkit-scrollbar {
     width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(224, 223, 223);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(197, 197, 197);
  }

  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1400;
  }

  .modal {
    background: #FFFFFF;
    overflow-x: auto;
    display: flex;
    width: 40%;
    border-radius: 5px;
    flex-direction: column;
    padding: 10px;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #000000;
    font-weight: 490;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
    height: 20vh;
    overflow-y: auto;
  }

  .modal-footer{
      position: sticky;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active {
    transition: opacity .5s ease;
  }
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
  .unlayer-editor {
    flex: 1;
    display: flex;
  }
</style>
