<template>
  <section>
    <ods-module v-loading="loading" class="mb-1">
       <template slot="header">
          <ods-row :gutter="20" type="flex">
            <ods-col :md="12">
              {{ $route.params.id  ? $t('editAttachmentType') :  $t('createAttachmentType') }}
            </ods-col>
          </ods-row>
        </template>

      <ods-form
          :model="form"
          ref="form"
          v-loading="loading"
       >
        <ods-row :gutter="20">
              <ods-col :md="12">
                <ods-form-item :label="$t('code')" prop="code" :rules="[errors.required]">
                   <ods-input v-model="form.code"></ods-input>
                </ods-form-item>
              </ods-col>
        </ods-row>
        <ods-row :gutter="20">
              <ods-col :md="12">
                  <ods-form-item :label="$t('descriptionEn')" prop="descriptionEn" :rules="[errors.required]">
                      <ods-input v-model="form.descriptionEn" v-bind:autosize="true" type="textarea"></ods-input>
                  </ods-form-item>
              </ods-col>

              <ods-col :md="12">
                <ods-form-item :label="$t('descriptionAr')" prop="descriptionAr" :rules="[errors.required]">
                  <ods-input v-model="form.descriptionAr" v-bind:autosize="true" type="textarea"></ods-input>
                </ods-form-item>
              </ods-col>
        </ods-row>
        <form-buttons
            @submit="submitForm"
            cancel-to="AttachmentType"
            cancelText = "back"
        ></form-buttons>
      </ods-form>
    </ods-module>
    <doclist v-if="$route.params.id"/>
  </section>
</template>

<script>
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'
import FormButtons from '@/custom-components/Buttons/FormButtons'
import AttachmentService from '@/services/Attachment'
import Doclist from './Doclist.vue'

export default {
  name: 'attachmentTypeEdit',
  components: {
    FormButtons,
    Doclist
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.getData()
    }
  },
  data () {
    return {
      loading: false,
      form: {},
      errors,
      submitErrors: {},
      formLoading: false
    }
  },
  methods: {
    async getData () {
      this.toggleLoading()
      const [error, response, data] = await handlePromise(AttachmentService.getAttachemntType(this.$route.params.id))
      if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
      this.form = {
        code: data.code,
        descriptionEn: data.descriptionEn,
        descriptionAr: data.descriptionAr
      }
      this.toggleLoading()
    },
    async submitForm () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.loading = true

          const toSend = {
            code: this.form.code,
            descriptionEn: this.form.descriptionEn,
            descriptionAr: this.form.descriptionAr
          }

          const [error, response, responseData] = await handlePromise(
            this.$route.params.id
              ? AttachmentService.updateAttachemntType(this.$route.params.id, toSend)
              : AttachmentService.saveAttachemntType(toSend)
          )
          this.loading = false
          if (!response.ok) return this.$store.commit('settings/toggleAlert', this.$t(error))
          return this.$router.push({ name: 'AttachmentType' })
        }
      })
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  }
}
</script>
