<template>
  <section>
    <ods-module :header="$route.params.id ? $t('editUser') : $t('newUser')">
      <ods-form
          :model="form"
          ref="form"
          v-loading="loading"
      >
        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item :label="$t('name')" prop="name"
                           :rules="[errors.required]">
              <ods-input v-model="form.name"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12">
            <ods-form-item :label="$t('lastName')" prop="lastName"
                           :rules="[errors.required]">
              <ods-input v-model="form.lastName"></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>

        <ods-row :gutter="20">
          <ods-col :md="12">
            <ods-form-item :label="$t('email')" prop="email"
                           :rules="[errors.required,errors.email]">
              <ods-input v-model="form.email" type="email"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12" v-if="!$route.params.id">
            <ods-form-item :label="$t('password')" prop="password" :rules="[errors.required]">
              <ods-input v-model="form.password" type="password"></ods-input>
            </ods-form-item>
          </ods-col>

          <ods-col :md="12">
            <ods-form-item :label="$t('roles')" prop="roles" :rules="[errors.required]">
              <ods-select multiple v-model="form.roles" cleanable>
                <ods-option
                    v-for="item in roles"
                    :key="item.id"
                    :label="$t(item.name)"
                    :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12">
             <ods-form-item :label="$t('username')" prop="cpr"
                           :rules="[errors.required]">
              <ods-input v-model="form.cpr"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="4" class="mt-1" v-if="$route.params.id">
            <ods-form-item label="" prop="enabled">
              <ods-checkbox
                  v-model="form.enabled"
                  :label="$t('enabled')">
              </ods-checkbox>
            </ods-form-item>
          </ods-col>
          <ods-col :md="8" class="mt-1" v-if="$route.params.id">
            <ods-form-item label="" prop="enabled">
              <ods-button @click="toggleDialog()" size="medium" type="primary">{{$t('getLongAuthToken')}}</ods-button>
            </ods-form-item>
          </ods-col>
        </ods-row>

        <div class="my-1">
          <ods-alert type="error" :closable="false" v-if="submitErrors">
            {{submitErrors}}
          </ods-alert>
        </div>

        <form-buttons
            @submit="submitForm"
            cancel-to="UserDashboard"
        ></form-buttons>
      </ods-form>
    </ods-module>
    <GetLongTokenModal
      v-if="showDialog"
      :userEmail="form.email"
      @close="toggleDialog()"
    />
  </section>
</template>

<script>
import UserService from '@/services/User'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'
import FormButtons from '@/custom-components/Buttons/FormButtons'
import GetLongTokenModal from './GetLongTokenModal.vue'

export default {
  name: 'Form',
  components: {
    FormButtons,
    GetLongTokenModal
  },
  mounted: function () {
    if (this.$route.params.id) {
      this.getData()
    }
  },
  data () {
    return {
      loading: false,
      form: {},
      roles: [
        { id: 'ROLE_ADMIN', name: 'ROLE_ADMIN' },
        { id: 'ROLE_CALL_CENTER', name: 'ROLE_CALL_CENTER' },
        { id: 'ROLE_OPERATOR', name: 'ROLE_OPERATOR' }
      ],
      errors,
      submitErrors: null,
      showDialog: false
    }
  },
  methods: {
    async getData () {
      this.loading = true

      const [error, response, data] = await handlePromise(UserService.getUser(this.$route.params.id))
      this.loading = false
      const roles = data.roles.filter(this.removeRolaApi)
      if (!response.ok) return this.$store.commit('settings/toggleAlert', error)

      this.form = {
        name: data.name ? data.name : '',
        lastName: data.lastName ? data.lastName : '',
        email: data.email,
        roles: roles,
        cpr: data.cpr,
        username: data.cpr,
        enabled: data.enabled
      }
    },
    removeRolaApi (value) {
      return value !== 'ROLE_API'
    },
    async submitForm () {
      this.submitErrors = null
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.loading = true
          const formattedData = {
            ...this.form
          }
          if (this.$route.params.id) {
            formattedData.enabled = this.form.enabled ? 'true' : 'false'
          }

          const [error, response] = await handlePromise(
            this.$route.params.id
              ? UserService.updateUser(this.$route.params.id, formattedData)
              : UserService.saveUser(formattedData)
          )
          this.loading = false
          if (!response.ok) {
            // this.submitErrors = error
            return this.$store.commit('settings/toggleAlert', error)
          }
          return this.$router.push({ name: 'UserDashboard' })
        }
      })
    },
    toggleDialog () {
      this.showDialog = !this.showDialog
    }
  }
}
</script>

<style lang="scss">
</style>
