<template>
  <section v-loading="loading">
    <ods-module :header="$t('filterCriteria')">
      <ods-form :model="search" ref="form">
        <ods-row :gutter="20">
          <ods-col :md="6" :sm="12">
            <ods-form-item
              :label="$t('referenceNumber')"
              prop="referenceNumber"
            >
              <ods-input v-model="search.referenceNumber"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="6" :sm="12">
            <ods-form-item :label="$t('accountNumber')" prop="accountNumber">
              <ods-input v-model="search.accountNumber"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="6" :sm="12">
            <ods-form-item
              :label="$t('customerIDNumber')"
              prop="customerIDNumber"
            >
              <ods-input v-model="search.customerIDNumber"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="6" :sm="12">
            <ods-form-item
              :label="$t('customerPhoneNumber')"
              prop="customerPhoneNumber"
            >
              <ods-input v-model="search.customerPhoneNumber"></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>
        <ods-row :gutter="20">
          <ods-col :md="6" :sm="12">
            <ods-form-item :label="$t('serviceType')" prop="serviceType">
              <ods-select
                v-model="search.serviceType"
                placeholder=""
                remote
                clearable
                filterable
              >
                <ods-option
                  v-for="item in serviceTypes"
                  :key="item.id"
                  :label="$t(item.descriptionEn)"
                  :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="6" :sm="12">
            <ods-form-item :label="$t('status')" prop="status">
              <ods-select
                v-model="search.status"
                placeholder=""
                remote
                clearable
                filterable
                multiple
              >
                <ods-option
                  v-for="item in statuses"
                  :key="item.id"
                  :label="$t(item.statusMaster)"
                  :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
          <ods-col :md="6" :sm="12">
            <ods-form-item :label="$t('statusOuc')" prop="statusOuc">
              <ods-input v-model="search.statusOuc"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="6" :sm="12">
            <ods-form-item :label="$t('governorate')" prop="governorate">
              <ods-select
                v-model="search.governorate"
                placeholder=""
                remote
                clearable
              >
                <ods-option
                  v-for="item in governorates"
                  :key="item.id"
                  :label="$t(item.nameEn)"
                  :value="item.id"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
        </ods-row>
        <ods-row :gutter="20">
          <ods-col :md="6" :sm="12">
            <ods-form-item :label="$t('blockNumber')" prop="blockNumber">
              <ods-input v-model="search.blockNumber"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="6" :sm="12">
            <ods-form-item :label="$t('dateFrom')" prop="dateFrom">
              <ods-date-picker
                type="date"
                v-model="search.dateFrom"
                style="width: 100%"
              />
            </ods-form-item>
          </ods-col>
          <ods-col :md="6" :sm="12">
            <ods-form-item :label="$t('dateTo')" prop="dateTo">
              <ods-date-picker
                type="date"
                v-model="search.dateTo"
                :picker-options="{ disabledDate: disabledDateTo }"
                style="width: 100%"
              />
            </ods-form-item>
          </ods-col>
          <ods-col :md="6" :sm="12">
            <ods-form-item
              :label="$t('newAccountNumber')"
              prop="newAccountNumber"
            >
              <ods-input v-model="search.newAccountNumber"></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>
        <ods-row :gutter="20">
          <ods-col :md="6" :sm="12">
            <ods-form-item :label="$t('supplyAddress')" prop="supplyAddressId">
              <ods-input v-model="search.supplyAddressId"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="6" :sm="12">
            <ods-form-item :label="'NAS'" prop="nas">
              <ods-input v-model="search.nas"></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>
        <ods-row type="flex" justify="center">
          <ods-button type="primary" @click="getData">
            {{ $t("search") }}
          </ods-button>
          <ods-button type="primary" @click="clearFilters">
            {{ $t("reset") }}
          </ods-button>
        </ods-row>
      </ods-form>
    </ods-module>
    <ods-module class="mt-1" :header="$t('searchResult')">
      <ods-table
        :data="data"
        v-loading="loading"
        @sort-change="handleSort"
        size="mini"
        style="width: 100%"
      >
        <ods-table-column
          prop="referenceNumber"
          width="140"
          :label="$t('referenceNumber')"
          sortable
        >
          <template slot-scope="scope">
            <router-link
              v-if="scope.row.id"
              :to="{ name: 'RequestDetail', params: { id: scope.row.id } }"
            >
              <ods-button type="text" class="p-0">{{
                scope.row.referenceNumber
              }}</ods-button>
            </router-link>
          </template>
        </ods-table-column>
        <ods-table-column
          prop="requestDate"
          width="180"
          :label="$t('requestDate')"
          sortable
        >
          <template slot-scope="scope">
            {{ scope.row.requestDate }}
          </template>
        </ods-table-column>
        <ods-table-column
          prop="status.statusMaster"
          width="180"
          :label="$t('requestStatus')"
          sortable
        />
        <ods-table-column
          prop="serviceType.descriptionEn"
          width="320"
          :label="$t('serviceType')"
          sortable
        />
        <ods-table-column
          prop="applicantIdNumber"
          width="130"
          :label="$t('idNumber')"
          sortable
        />
        <ods-table-column
          prop="applicantIdType.descriptionEn"
          width="130"
          :label="$t('idType')"
          sortable
        />
        <ods-table-column
          prop="accountNumberOld"
          width="150"
          :label="$t('accountNumber')"
          sortable
        />
        <ods-table-column
          prop="statusOuc"
          width="180"
          :label="$t('statusOuc')"
          sortable
        />
        <ods-table-column :label="$t('updatedBy')" width="250">
          <template slot-scope="scope">
            {{ scope.row.updatedBy ? scope.row.updatedBy : "---" }}
          </template>
        </ods-table-column>
        <ods-table-column :label="$t('notificationsSent')" width="300">
          <template slot-scope="scope">
            <ods-button
              size="mini"
              type="primary"
              icon="ods-icon-vote"
              @click="toggleDialog(scope.row)"
            />
          </template>
        </ods-table-column>
      </ods-table>
      <div class="block mr-1">
        <ods-pagination
          :total="total"
          :page-size="pageSize"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
          layout="prev, pager, next, jumper, sizes, ->, total"
        >
        </ods-pagination>
      </div>
    </ods-module>
    <notification-modal
      v-if="dialogVisible"
      :valueToShow="valueToShow"
      v-show="dialogVisible"
      @close="toggleDialog"
    />
  </section>
</template>

<script>
import map from 'lodash/map'
import _ from 'lodash'
import handlePromise from '@/utils/promise'
import moment from 'moment'
import RequestService from '@/services/Request'
import UserService from '@/services/User'
import NotificationModal from './NotificationModal.vue'
import ListHeader from '@/custom-components/ListHeader'
import { mapState } from 'vuex'
import Vue from 'vue'

export default {
  name: 'RequestDashboard',
  props: {
    params: Object
  },
  computed: {
    ...mapState({
      userData: (state) => state.user.data
    })
  },
  components: {
    ListHeader,
    NotificationModal
  },
  data () {
    return {
      loading: false,
      search: {},
      serviceTypes: [],
      statuses: [],
      governorates: [],
      data: [],
      pageSize: 10,
      pageIndex: 0,
      pageStartZero: true,
      total: 0,
      sort: null,
      dialogVisible: false,
      valueToShow: null
    }
  },
  async mounted () {
    var self = this
    addEventListener('keypress', function (e) {
      if (e.key === 'Enter') {
        self.getData()
      }
    })
    this.loading = true
    await this.getUser()
    await this.getGovernorates()
    await this.getServiceTypes()
    await this.getStatuses()
    await this.getData()
    this.loading = false
  },
  methods: {
    disabledDateTo (date) {
      if (this.search.dateFrom) { return moment(this.search.dateFrom).isAfter(date, 'day') }
    },
    clearFilters () {
      this.search = {}
      this.disabledDateTo(null)
    },
    // change(){
    //   process.env.TZ = 'UTC'
    //   console.log(process.env)
    // },
    async getStatusInOUC () {
      return Promise.all(
        this.data.map(async (item) => {
          const [error, response] = await handlePromise(
            RequestService.getStatusinOUC(item.referenceNumber)
          )
          if (!response.ok) { return this.$store.commit('settings/toggleAlert', this.$t(error)) }
        })
      )
    },
    async getGovernorates () {
      const [error, response, data] = await handlePromise(
        RequestService.getGovernorates()
      )
      if (!response.ok) { return this.$store.commit('settings/toggleAlert', this.$t(error)) }
      this.governorates = data.data
    },

    async getServiceTypes () {
      const [error, response, data] = await handlePromise(
        RequestService.getServiceTypes()
      )
      if (!response.ok) { return this.$store.commit('settings/toggleAlert', this.$t(error)) }
      this.serviceTypes = data.data
    },

    async getStatuses () {
      const [error, response, data] = await handlePromise(
        RequestService.getStatus()
      )
      if (!response.ok) { return this.$store.commit('settings/toggleAlert', this.$t(error)) }
      this.statuses = data.data
    },
    async getUser () {
      const [error, response, data] = await handlePromise(
        UserService.getUser(this.userData.user.id)
      )
      if (!response.ok) { return this.$store.commit('settings/toggleAlert', this.$t(error)) }
      this.search = {
        ...this.search,
        status: data.status
      }
    },
    async getData () {
      if (this.$route.params.id) {
        let linkedGovernorate = _.find(this.governorates, [
          'nameEn',
          this.$route.params.id
        ])
        this.search = {
          ...this.search,
          governorate: linkedGovernorate ? linkedGovernorate.id : 'none'
        }
      }
      let auxParams = {}
      if (this.search.referenceNumber) {
        auxParams = {
          'filters[referenceNumber]': this.search.referenceNumber,
          ...auxParams
        }
      }
      if (this.search.accountNumber) {
        auxParams = {
          'filters[accountNumberOld]': this.search.accountNumber,
          ...auxParams
        }
      }
      if (this.search.customerIDNumber) {
        auxParams = {
          'filters[applicantIdNumber]': this.search.customerIDNumber,
          ...auxParams
        }
      }
      if (this.search.customerPhoneNumber) {
        auxParams = {
          'filters[applicantMobile]': this.search.customerPhoneNumber,
          ...auxParams
        }
      }
      if (this.search.serviceType) {
        auxParams = {
          'filters[serviceType]': this.search.serviceType,
          ...auxParams
        }
      }
      if (this.search.statusOuc) {
        auxParams = {
          'filters[statusOuc]': this.search.statusOuc,
          ...auxParams
        }
      }
      if (this.search.governorate) {
        auxParams = {
          'filters[governorate]': this.search.governorate,
          ...auxParams
        }
      }
      if (this.search.blockNumber) {
        auxParams = {
          'filters[blockNumber]': this.search.blockNumber,
          ...auxParams
        }
      }
      if (this.search.dateFrom) {
        auxParams = {
          'filters[from]': moment(this.search.dateFrom).format('YYYY-MM-DD'),
          ...auxParams
        }
      }
      if (this.search.dateTo) {
        auxParams = {
          'filters[till]': moment(this.search.dateTo).format('YYYY-MM-DD'),
          ...auxParams
        }
      }
      if (this.search.newAccountNumber) {
        auxParams = {
          'filters[cssAccountNumber]': this.search.newAccountNumber,
          ...auxParams
        }
      }
      if (this.search.supplyAddressId) {
        auxParams = {
          'filters[supplyAddressEn]': this.search.supplyAddressId,
          ...auxParams
        }
      }

      if (this.search.nas) {
        auxParams = {
          'filters[supplyAddressId]': this.search.nas.replace(/\-/g, ''),
          ...auxParams
        }
      }

      if (this.search.status) {
        auxParams = {
          'filters[status]': _.map(this.search.status),
          ...auxParams
        }
      }

      let params = {
        offset:
          this.pageIndex === 0 && this.pageStartZero
            ? 0
            : this.pageIndex * this.pageSize,
        limit: this.pageSize,
        ...auxParams
      }

      if (this.$route.params.id) {
        this.$route.params.id = null
        this.search = {
          ...this.search,
          governorate: null
        }
      }

      if (this.sort && this.sort.prop) {
        params[`sortby[${this.sort.prop}]`] =
          this.sort.order === 'ascending' ? 'ASC' : 'DESC'
      }
      if (!this.loading) this.toggleLoading()
      const [error, response, data] = await handlePromise(
        RequestService.getRequests(params)
      )
      if (this.loading) this.toggleLoading()
      if (!response.ok) { return this.$store.commit('settings/toggleAlert', this.$t(error)) }
      this.data = data.data
      this.total = data.total
    },
    handleSizeChange (val) {
      this.pageSize = val
      this.getData()
    },
    handlePageChange (val) {
      this.pageIndex = val - 1
      this.getData()
    },
    handleFilters (filters) {
      this.filters = {}
      map(filters, (filter, key) => {
        if (filter) this.filters[`filters[${key}]`] = filter
      })

      this.getData()
    },
    handleSort (val) {
      this.sort = { prop: val.prop, order: val.order }
      this.getData()
    },
    async toggleDialog (val) {
      if (val) {
        this.valueToShow = val
      } else {
        this.valueToShow = null
      }
      this.dialogVisible = !this.dialogVisible
    },
    toggleLoading () {
      this.loading = !this.loading
    }
  },
  watch: {
    'search.nas': function (val) {
      this.search.nas = Vue.filter('formatIdSupplyAddress')(val.replace(/\-/g, ''))
    }
  }
}
</script>
