import api from '@/config/Api'

const baseEndpoint = 'administration/notification-type'
const baseEndpoint2 = 'administration/notifications?filters[unread]=1'

export default {
  getUnreadNotifications () {
    return api.get(baseEndpoint2)
  },

  updateUnreadNotification (id, data) {
    return api.put(`administration/notifications/${id}`, data)
  },

  getNotifications (params) {
    return api.get(baseEndpoint, params)
  },

  getNotificationDetail (id) {
    return api.get(`${baseEndpoint}/${id}`)
  },

  saveNotification (data) {
    return api.post(baseEndpoint, data)
  },

  updateNotification (id, data) {
    return api.put(`${baseEndpoint}/${id}`, data)
  },

  deleteNotification (id) {
    return api.delete(`${baseEndpoint}/${id}`)
  },

  getEmailLog (id) {
    return api.get(`administration/email-logs?filters[request]=${id}`)
  },

  getSMSLog (id) {
    return api.get(`administration/sms-logs?filters[request]=${id}`)
  }
}
