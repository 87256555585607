import enLocale from '@/addons/locale/lang/en'

export default {
  ...enLocale,
  label: 'English',
  labelMobile: 'EN',
  serverError: 'Unexpected server error. Try again.',
  email: 'Email',
  password: 'Password',
  login: 'Login',
  edit: 'Edit',
  back: 'Back',
  cancel: 'cancel',
  users: 'Users',
  name: 'Name',
  roles: 'Roles',
  enabled: 'Enabled',
  ROLE_ADMIN: 'Admin',
  ROLE_OPERATOR: 'Operator',
  ROLE_CALL_CENTER: 'Call center',
  editUser: 'Edit user',
  newUser: 'New user',
  externalAuthentication: 'External authentication',
  confirm: 'Confirm',
  deleteAlert: 'Are you sure you want to delete this item?',
  description: 'Description',
  code: 'Code',
  value: 'Value',
  newValue: 'New value',
  save: 'Save',
  lovValues: 'LOV values',
  editLov: 'Edit LOV',
  lovs: 'LOVs',
  newLov: 'New LOV',
  delete: 'Delete',
  errors: {
    required: 'This field is required',
    email: 'Please input correct email address',
    url: 'Please input correct URL',
    phone: 'Please input a correct phone number'
  },
  dashboard: 'Dashboard',
  filterCriteria: 'Search criterias',
  referenceNumber: 'Reference number',
  accountNumber: 'Account number',
  customerIDNumber: 'Customer ID number',
  customerPhoneNumber: 'Customer phone number',
  serviceType: 'Service type',
  electricityTechnicalServiceRequest: 'Electricity Technical service request',
  disconectionElectricityWater: 'Request for Disconnection of Electricity and Water',
  moveWithDebtTransferCON: 'Request from Move-in With debt transfer (CON)',
  moveWithDebtTransferCOT: 'Request from Move-in With debt transfer (COT)',
  newElectricitySupply: 'Request for New Electricity Supply',
  newWaterSupply: 'Request for New Water Supply',
  reconnectionElectricityWater: 'Request for Reconnection of Electricity and Water',
  waterTechnicalServiceRequest: 'Water Technical service request',
  status: 'Status',
  approved: 'Approved',
  approvedCSSProcessError: 'Approved - OUC Process Error',
  approvedCSSSubmitFailed: 'Approved - OUC Submit Failed',
  approvedCSSSubmitSuccess: 'Approved - OUC Submit Success',
  approvedFileNetSubmitFailed: 'Approved - FileNet Submit Failed',
  approvedFileNetSubmitSuccess: 'Approved - FileNet Submit Success',
  approvedNewAccountGenerated: 'Approved - New Account Generated',
  cancelled: 'Cancelled',
  changeServiceType: 'Change Service Type',
  incomplete: 'Incomplete',
  newRequest: 'New Request',
  rejected: 'Rejected',
  governorate: 'Governorate',
  blockNumber: 'Block Number',
  newAccountNumber: 'New Account Number',
  searchResult: 'Search result',
  dateFrom: 'Date From',
  dateTo: 'Date To',
  Capital: 'Capital',
  Northern: 'Northern',
  Muharraq: 'Muharraq',
  central: 'Central',
  Southern: 'Southern',
  search: 'Search',
  reset: 'Reset',
  requestDate: 'Request Date',
  idNumber: 'ID Number',
  requestStatus: 'Request Status',
  lastModifiedBy: 'Last Modified By',
  requestDetails: 'Request Details',
  ERROR_MESSAGE_REQUEST_INVALID_USER_PASSWORD_COMBINATION: 'Invalid user or password',
  apiError: 'Ops there was an error',
  username: 'Username',
  changePassword: 'Change password',
  cpr: 'CPR',
  lastName: 'Last name',
  ROLE_API: 'Api',
  passwordDontMatch: 'Password dont match',
  currentPassword: 'Current password',
  details: 'Details',
  accountDetails: 'Account details',
  supplyAddress: 'Supply address',
  propertyDetails: 'Property details',
  serviceRSCodeDetails: 'Service RS code details',
  mailingAddressDetails: 'Mailing address details',
  customerDetails: 'Customer details',
  paymentDetails: 'Payment details',
  attachmentsDetails: 'Attachments details',
  statusDetails: 'Status details',
  meterReadingDetails: 'Meter reading details',
  statusHistory: 'Status history',
  customerType: 'Customer type',
  propertyType: 'Property type',
  purchaseDate: 'Purchase date',
  leaseDate: 'Lease date',
  leaseAmount: 'Lease amount (BD)',
  customerComments: 'Customer comments',
  fullyFurnished: 'Fully furnished',
  yes: 'yes',
  no: 'no',
  ownerInformation: 'Owner information',
  fetchOwnerDetails: 'Fetch owner details again',
  ownerName: 'Owner name',
  ownerIdentityType: 'Owner identity type',
  ownerIdentityNumber: 'Owner identity number',
  additionalNotes: 'Additional notes',
  addressType: 'Address type',
  mailingAddress: 'Mailing address',
  idType: 'ID type',
  nameEnglish: 'Name english',
  nameArabic: 'Name arabic',
  nationality: 'Nationality',
  mobileNumber: 'Mobile number',
  otherMobileNumber: 'Other mobile number',
  telephoneNumber: 'Telephone number',
  fax: 'fax',
  requestLanguage: 'Request language',
  comments: 'Comments',
  otherComments: 'Other comments',
  statusInCSS: 'Status in OUC',
  firstNameArabic: 'First name arabic',
  middleName1Arabic: 'Middle name 1 arabic',
  middleName2Arabic: 'Middle name 2 arabic',
  middleName3Arabic: 'Middle name 3 arabic',
  middleName4Arabic: 'Middle name 4 arabic',
  familyNameArabic: 'Family name arabic',
  firstNameEnglish: 'First name english',
  middleName1English: 'Middle name 1 english',
  middleName2English: 'Middle name 2 english',
  middleName3English: 'Middle name 3 english',
  middleName4English: 'Middle name 4 english',
  familyNameEnglish: 'Family name english',
  paymentDeductionOption: 'Payment deduction option',
  creditCard: 'Credit card',
  bankAccount: 'Bank account',
  insuranceFees: 'Deposit',
  meterNumber: 'Meter number',
  service: 'Service',
  consumptionType: 'Consumption type',
  lastMeterReading: 'Last meter reading',
  lastReadingDate: 'Last reading date',
  lastReadingType: 'Last reading type',
  currentMeterReading: 'Current meter reading',
  currentReadingDate: 'Current reading date',
  existingSPN: 'Existing SPN',
  existingRSCode: 'Existing RS code',
  newRSCODE: 'New RS code',
  user: 'User',
  internalComments: 'Internal comments',
  date: 'Date',
  documentName: 'Document name',
  documentDescription: 'Document description',
  viewDocument: 'View document',
  view: 'View',
  mergeViewAttachments: 'Merge and view attachments',
  'Request for  Reconnection of Electricity and Water': 'Request for reconnection of electricity and water',
  'Request for Move-in - With debt transfer (CON)': 'Request for Move-in - with debt transfer (CON)',
  'Electricity Technical service request': 'Electricity technical service request',
  'Water Technical service request': 'Water technical service request',
  'Request for New Water Supply': 'Request for new water supply',
  'Request for New Electricity Supply': 'Request for new electricity supply',
  'Request for Move-in - Without debt transfer (COT)': 'Request for move-in - without debt transfer (COT)',
  'Request for Disconnection of Electricity and Water': 'Request for disconnection of electricity and water',
  'Approved Submited Fail': 'Approved submited fail',
  Cancelled: 'Cancelled',
  'Approved - CSS Submit Failed': 'Approved - OUC submit failed',
  'Approved - CSS Submit Success': 'Approved - OUC submit success',
  'New Request': 'New request',
  Incomplete: 'Incomplete',
  'Request Updated By Customer': 'Request updated by customer',
  Approved: 'Approved',
  Rejected: 'Rejected',
  'Change Service Type': 'Change service type',
  billLanguage: 'Bill language',
  isVerified: 'Is verified',
  createServiceOrder: 'Create service order',
  verified: 'Verified',
  requestUpdated: 'Request updated',
  electricityService: 'Electricity service',
  waterService: 'Water service',
  rate: 'Rate',
  newRSDescription: 'New RS description',
  sendedNotifications: 'Sended notifications',
  statusMaintenance: 'Status maintenance',
  statusInCSSDesc: 'Status in OUC description',
  tarif: 'Tarif',
  currentStatus: 'Current status',
  newStatuses: 'New statuses',
  notify: 'Notify',
  notifications: 'Notifications',
  notificationMaintenance: 'Notification maintenance',
  creationDate: 'Creation date',
  posibleChangeOfStatus: 'Possible changing of Status to:',
  verifyAlert: 'Are you sure you want to verify this request? This task cannot be reverted and the request will be verified.',
  verify: 'Verify',
  newStatus: 'New status',
  noSelectStatus: 'No select status',
  showAnimations: 'Show animations',
  afterLoginRedirectTo: 'After login, redirect to:',
  requestList: 'Request list',
  youNeedToReloginToSeeThisChange: 'You must to authenticate again to see this change',
  createNotification: 'Create notification',
  editNotification: 'Edit notification',
  sendSMS: 'Send SMS',
  sendEmail: 'Send email',
  enSMS: 'English SMS',
  arSMS: 'Arabic SMS',
  tags: 'Tags',
  requestNumber: 'Request number',
  notificationSaved: 'Notification saved',
  smsTemplateEnRequired: 'The English SMS is required',
  smsTemplateArRequired: 'The Arabic SMS is required',
  emailTemplateEnRequired: 'The English Email is required',
  emailTemplateArRequired: 'The Arabic Email is required',
  forgotPassword: 'Forgot your password?',
  backToLogin: 'Back to login',
  emailSended: 'Email sent',
  send: 'Send',
  enEmail: 'English Email',
  arEmail: 'Arabic Email',
  requestByStatus: 'Request by status',
  verifiedRequests: 'Verified requests',
  avgBetweenCreatedAndApproved: 'Average duration in days between requests created and approved',
  requestStatusByGovernorates: 'Requests status by governorates',
  requestByGovernorates: 'Requests by governorates',
  requestByServiceTypes: 'Requests by service types',
  requestsByCreatedDate: 'Requests by created date',
  requestsApprovedByCreatedDate: 'Requests approved by created date',
  notificationsSentTo: 'Notifications sent to:',
  attempt: 'Attempt',
  error: 'Error',
  notificationType: 'Notification type',
  sentOn: 'Sent on',
  notificationsSent: 'Notifications sent',
  ERROR_MESSAGE_METER_DETAIL_NOT_FOUND: 'Meter readings not found',
  ERROR_MESSAGE_USER_DISABLED: 'This user is not enabled',
  saveMeterReading: 'Save current meter readings',
  clearNotifications: 'Clear all notifications',
  noNotifications: 'No notifications found',
  youMustSpecifyDate: 'You must specify a purchase date or a lease date',
  getLongAuthToken: 'Get long duration token',
  getToken: 'Generate token',
  statusOuc: 'Status in OUC',
  meterReadingNotNullWhenApproved: 'Before passing the status to approved you must fill the present readings in the meter reading section',
  fetchMeterReading: 'Fetch meter reading again',
  fetchRSCodes: 'Fetch service RS code again',
  propertyCategory: 'Property category',
  fetchStatusOUC: 'Fetch status in OUC',
  docList: 'Doc list',
  descriptionEn: 'Description-En',
  descriptionAr: 'Description-Ar',
  isMandatory: 'Is mandatory',
  createDocList: 'Create doc element',
  editDocList: 'Editar doc element',
  attachmentType: 'Attachment type',
  createAttachmentType: 'Create attachment type',
  attachmentTypeMaintenance: 'Attachment type maintenance',
  editAttachmentType: 'Edit attachment type',
  payments: 'Payments',
  totalAmount: 'Total amount',
  records: 'Records',
  channelId: 'Channel id',
  transactionId: 'Transaction id',
  paymentDate: 'Payment date',
  paymentDateFrom: 'Payment date from',
  paymentDateTo: 'Payment date to',
  paidAmount: 'Paid amount',
  authorizationCode: 'Authorization code',
  billType: 'Bill type',
  machine: 'Machine',
  processed: 'Processed',
  exportExcel: 'Export to excel',
  all: 'All',
  forAll: 'For all',
  docListFiltering: 'Doc list filtering',
  filters: 'Filters',
  addFilter: 'Add filter',
  attachmentsList: 'Attachments list filtering',
  ERROR_MESSAGE_USER_NOT_FOUND: 'User not found',
  noAttachments: 'No attachments was found',
  LogDetail: 'Log detail',
  result: 'Result',
  action: 'Action',
  body: 'Body',
  statusResponse: 'Status response',
  response: 'Response',
  header: 'Header',
  origin: 'Origin',
  url: 'Url',
  createdAt: 'Created at',
  logs: 'Logs',
  deleteAllLogs: 'Delete all logs',
  deleteAllLogsAlert: 'Are you sure that you want to delete all logs?',
  noRSCodeSelected: 'No RS code selected',
  externalData: 'External data',
  fetchExternalAgain: 'Fetch external data again',
  fillCommentsAttachmentsBeforeSave: 'Before saving the request with status incomplete, you must to specify an incomplete attachment and fill a comment',
  ERROR_MESSAGE_NOTIFICATION_NOT_FOUND: 'Notifications not found',
  moreData: 'Extra information',
  supplyAddressEn: 'Supply address',
  supplyInformation: 'Supply address information',
  maillingAddress: 'Mailing address information',
  'cpr/username': 'cpr/username',
  ERROR_MESSAGE_STATUS_NOT_CHANGED: 'The status of the request was not changed',
  admitedFiles: 'Only pdf, jpg, jpeg and png filetypes can be merged.',
  ERROR_MESSAGE_REQUEST_NOT_FOUND: 'Request not found',
  updatedBy: 'Updated by',
  oucAccount: 'OUC Account',
  defaultRequestStatus: 'Default request status filter',
  estimateConsumption: 'Estimate consumption',
  passwordChanged: 'Password changed',
  ERROR_MESSAGE_PASSWORD_RECOVERY_REQUEST_HAS_ALREADY_BEEN_REQUESTED: 'Message recovery request has already been requested'
}
