<template>
  <section>
    <ods-module header="Profile">
      <template slot="header">
        <list-header
          :title="$t('Profile')"
          :btn-label="$t('changePassword')"
          url-key="UserChangePassword"
        />
      </template>
      <ods-form
        class="login-form"
        label-position="top"
        :model="profileForm"
        :rules="validations"
        ref="profileForm"
        v-loading="loading"
      >
        <ods-row :gutter="24">
          <ods-col :md="12">
            <ods-form-item label="Name" prop="name">
              <ods-input v-model="profileForm.name"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12">
            <ods-form-item :label="$t('lastName')" prop="lastName">
              <ods-input v-model="profileForm.lastName"></ods-input>
            </ods-form-item>
          </ods-col>
        </ods-row>
        <ods-row :gutter="24">
          <ods-col :md="12">
            <ods-form-item label="Email" prop="email">
              <ods-input v-model="profileForm.email" type="email"></ods-input>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12">
            <ods-form-item
              :label="$t('defaultRequestStatus')"
              prop="defaultRequestStatus"
            >
              <ods-select
                v-model="profileForm.status"
                remote
                clearable
                filterable
                multiple
              >
                <ods-option
                  v-for="item in statuses"
                  :key="item.statusMaster"
                  :label="$t(item.statusMaster)"
                  :value="item.statusMaster"
                >
                </ods-option>
              </ods-select>
            </ods-form-item>
          </ods-col>
        </ods-row>
        <ods-row justify="center" :gutter="24">
          <ods-col :md="12">
            <ods-form-item :label="$t('afterLoginRedirectTo')">
              <ods-radio-group v-model="profileForm.redirectTo">
                <ods-radio :label="'dashboard'" class="mb-1">{{
                  $t("dashboard")
                }}</ods-radio>
                <ods-radio :label="'agent_panel'" class="mb-1">{{
                  $t("requestList")
                }}</ods-radio>
              </ods-radio-group>
            </ods-form-item>
          </ods-col>
          <ods-col :md="12">
            <ods-checkbox
              style="margin-top: 25px"
              class="mb-1"
              v-model="profileForm.allowAnimation"
            >
              {{ $t("showAnimations") }}
            </ods-checkbox>
          </ods-col>
        </ods-row>
        <ods-row type="flex" justify="center">
          <ods-button type="primary" @click="submitForm()">Save</ods-button>
        </ods-row>
      </ods-form>
    </ods-module>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import UserService from '@/services/User'
import handlePromise from '@/utils/promise'
import errors from '@/config/InputErrors'
import ListHeader from '@/custom-components/ListHeader'
import RequestService from '@/services/Request'
import _ from 'lodash'

export default {
  name: 'Profile',
  async mounted () {
    this.loading = true
    await this.getStatuses()
    await this.getData()
    this.loading = false
  },
  data () {
    return {
      loading: false,
      profileForm: {},
      statuses: [],
      validations: {
        email: [errors.required, errors.email],
        name: [errors.required]
      }
    }
  },
  components: {
    ListHeader
  },
  computed: {
    ...mapState({
      userData: (state) => state.user.data
    })
  },
  methods: {
    async getData () {
      const [error, response, data] = await handlePromise(
        UserService.getUser(this.userData.user.id)
      )
      if (!response.ok) { return this.$store.commit('settings/toggleAlert', this.$t(error)) }

      const roles = data.roles.filter(this.removeRolaApi)
      let status = []
      data.status.map(item => {
        if (_.find(this.statuses, { 'id': item })) {
          status.push(_.find(this.statuses, { 'id': item }).statusMaster)
        }
      })
      this.profileForm = {
        email: data.email,
        name: data.name,
        lastName: data.lastName ? data.lastName : '',
        roles: roles,
        cpr: data.cpr,
        enabled: data.enabled,
        redirectTo: data.redirectTo === '' ? 'dashboard' : data.redirectTo,
        allowAnimation: data.allowAnimation,
        status: status
      }
    },
    removeRolaApi (value) {
      return value !== 'ROLE_API'
    },
    async getStatuses () {
      const [error, response, data] = await handlePromise(
        RequestService.getStatus()
      )
      if (!response.ok) { return this.$store.commit('settings/toggleAlert', this.$t(error)) }
      this.statuses = data.data
    },
    async submitForm () {
      this.$refs['profileForm'].validate(async (valid) => {
        if (valid) {
          this.loading = true

          const data = {
            email: this.profileForm.email,
            name: this.profileForm.name,
            lastName: this.profileForm.lastName
              ? this.profileForm.lastName
              : '',
            roles: this.profileForm.roles,
            cpr: this.profileForm.cpr,
            enabled: this.profileForm.enabled ? 'true' : 'false',
            redirectTo: this.profileForm.redirectTo,
            allowAnimation: this.profileForm.allowAnimation ? 'true' : 'false',
            status: this.profileForm.status
          }
          const [error, response] = await handlePromise(
            UserService.updateUser(this.userData.user.id, data)
          )
          this.loading = false
          if (!response.ok) {
            return this.$store.commit('settings/toggleAlert', this.$t(error))
          }
          return window.location.reload()
        }
      })
    }
  }
}
</script>

<style lang="scss"></style>
